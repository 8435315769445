import React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const SwitchComponent = withStyles({
  switchBase: {
    color: "#FCFCFC",
    "&$checked": {
      color: "#039b03",
    },
    "&$checked + $track": {
      backgroundColor: "#039b03",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function Toggle(props: any) {
  return (
    <FormGroup>
      <SwitchComponent
        checked={props.statusType}
        onChange={() =>
          props.changePublishCallback(props.itemId, props.statusType)
        }
        name=""
      />
    </FormGroup>
  );
}
