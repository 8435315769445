import {
  NOTIFICATIONS_SUCCESS_FAILURE,
  Action,
  InitialState,
  CATEGORIES_SUCCESS_FAILURE,
  SEND_NOTIFICATION_SUCCESS_FAILURE,
} from "../../types/notifications";

const initialState: InitialState = {
  isFetching: false,
  notificationsSuccessFailure: [],
  sendNotificationSuccessFailure: undefined,
};
const notifications = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case NOTIFICATIONS_SUCCESS_FAILURE:
      return {
        ...state,
        notificationsSuccessFailure: actions.data,
      };
    case CATEGORIES_SUCCESS_FAILURE:
      return {
        ...state,
        categoriesSuccessFailure: actions.data,
      };
    case SEND_NOTIFICATION_SUCCESS_FAILURE:
      return {
        ...state,
        sendNotificationSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default notifications;
