import React, { memo, useEffect, useState, useMemo } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Event } from "../../commonTypes/types";
import { rolesArray, routePathNames, alert } from "../../utils/constants";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import ImageDropZone from "../../commonComponents/imageDropZone";
import Toggle from "../../commonComponents/publishToggle";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import ShowSnackBar from "../../commonComponents/snackBar";
import Loading from "../../commonComponents/loading";
import { connect } from "react-redux";
import {
  createHomeScreenImages,
  editHomeScreenImagesCall,
} from "./redux/actions/homescreenimage";
import {
  createNewHomeScreenImage,
  editHomeScreenImage,
} from "./types/homescreenimages";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    textField: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
      order: 2,
    },

    btnStyle: {
      width: "100%",
      marginTop: "20px",
      marginLeft: "5px",
      fontWeight: "bold",
    },
    buttonTextStyle: {
      color: "#fff",
      fontWeight: "bold",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#fff",
      marginTop: theme.spacing(2),
    },
    imageItem: {
      order: 2,
      width: "100%",
      justifyItems: "center",
      /**[theme.breakpoints.up("md")]: {
        order: 1,
      },*/
    },
    btnItem: {
      width: "60%",
      order: 3,
    },
    contentItem: {
      order: 2,
      width: "100%",
    },
  })
);
//

const CreateHomeScreenImages = (props: any) => {
  const { location } = props;
  const classes = useStyles();
  const [id, setId] = React.useState("");
  const [imageWhileUpdate, setImageWhileUpdate] = React.useState("");
  const [status, setStatus] = React.useState(1);
  const [imageData, setImageData] = React.useState([{}]);
  const [urlTo, setUrlTo] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [
    { title: "Home Screen Images", link: routePathNames.HOME_SCREEN_IMAGES },
  ];
  const actionType = props.actionType;
  let imageUrlOnUpdate: string;
  //

  useEffect(() => {
    checkUrlProps();
  }, []);

  const checkUrlProps = () => {
    const currentUrl = props.location;
    console.log("checkUrlProps ~ data111", props, currentUrl);
    if (
      location &&
      location.state &&
      location.state.data &&
      actionType === "edit"
    ) {
      let data = location.state.data;
      console.log("checkUrlProps ~ data", data);
      setId(data.id);
      setUrlTo(data.url);
      const publishStatus = Number(data.published);
      setStatus(publishStatus);
      imageUrlOnUpdate = data.imageurl;
      let imageData = [
        {
          preview: imageUrlOnUpdate,
        },
      ];
      setImageData(imageData);
      setImageWhileUpdate(data.imageurl);
    }
  };

  const clearData = () => {
    setImageData([]);
    setUrlTo("");
  };

  //AddImage
  const addHomeScreenImagesApiCall = (body: createNewHomeScreenImage) => {
    setLoading(true);
    props
      .createHomeScreenImages(body)
      .then((result: any) => {
        console.log("addHomeScreenImagesApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          clearData();
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  //Updateimage

  const updateHomeScreenImagesApiCall = (body: editHomeScreenImage) => {
    setLoading(true);
    props
      .editHomeScreenImagesCall(id, body)
      .then((result: any) => {
        console.log("updateHomeScreenImagesApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          setTimeout(() => {
            clearData();
            props.history.goBack();
          }, 1000);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const checkEmpty = () => {
    if (urlTo === "") {
      return false;
    } else {
      return true;
    }
  };

  //validatePayoad

  const validateAndGeneratePayoad = () => {
    console.log("validateAndGeneratePayoad", status);
    if (imageData && imageData.length) {
      let data: any = imageData[0];
      let baseData =
        data && data.base64 ? data.base64.split(",")[1] : imageWhileUpdate;
      let body: any = {
        imageFile: baseData,
        imageName: data && data.name ? data.name : "",
        published: status,
        url: urlTo,
      };
      if (actionType === "add") {
        console.log("body add", body);
        addHomeScreenImagesApiCall(body);
      } else {
        console.log("body edit", body);
        updateHomeScreenImagesApiCall(body);
      }
    } else {
      toggleSnackBar(true, alert.upload);
    }
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const syncImageData = (data: any) => {
    console.log("CreateHomeScreenImages ~ data", data, imageData);
    setImageData(data);
  };
  return (
    <React.Fragment>
      <SimpleBreadcrumbs
        breadcrumbData={breadData}
        heading={actionType === "add" ? "Create New" : "Update"}
      />
      <form className={classes.root} noValidate autoComplete="off">
        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid item xs={8} justify="center" className={classes.imageItem}>
            <ImageDropZone
              placeHolderText={
                "Drag 'n' drop single image file here, or click here to select the file."
              }
              height={"300px"}
              width={"100%"}
              imageData={imageData}
              syncImageDataCallback={syncImageData}
            />
          </Grid>

          <Grid item xs={8} justify="center" className={classes.contentItem}>
            <TextField
              className={classes.textField}
              id="outlined-size-small"
              label="Link To"
              variant="outlined"
              value={urlTo}
              onChange={(event: Event) => setUrlTo(event.target.value)}
            />
          </Grid>

          <Grid item xs={5} justify="center" className={classes.btnItem}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnStyle}
              size="large"
              onClick={validateAndGeneratePayoad}
            >
              <Typography className={classes.buttonTextStyle}>
                {"Save"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
      <Loading isFetching={loading} />
    </React.Fragment>
  );
};

const mapState = (state: any) => {
  return {
    createHomeScreenImagesSuccessFailure:
      state.homescreenimage.createHomeScreenImagesSuccessFailure,
  };
};

const mapDispatch = {
  createHomeScreenImages: (body: createNewHomeScreenImage) =>
    createHomeScreenImages(body),
  editHomeScreenImagesCall: (id: number, body: editHomeScreenImage) =>
    editHomeScreenImagesCall(id, body),
};

const connector = connect(mapState, mapDispatch);
export default connector(React.memo(CreateHomeScreenImages));
