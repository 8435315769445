import React, { useCallback, useEffect, useState } from "react";

import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import "./css/notifications.css";
import { Event, TRUMBOWYG_EVENT } from "../../commonTypes/types";
import {
  AddNotifictionParams,
  Category,
  CircleData,
  notification_payload,
} from "./types/notifications";
import { rolesArray, routePathNames, alert } from "../../utils/constants";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import _ from "lodash";
import ShowSnackBar from "../../commonComponents/snackBar";
import Typography from "@material-ui/core/Typography";
import {
  getNotificationsCategories,
  getMobileUsersCount,
  sendNotification,
} from "./redux/actions/notifications";

import * as utils from "../../utils/index";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";

// common components
import SelectMenu from "./components/selectComponent";
import TrumbowygTextEditor from "../../commonComponents/trumbowygTextEditor";
import Loading from "../../commonComponents/loading";

// material
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

var moment = require("moment");

// Map

// @ts-ignore
const google = window.google;
var circles: any[] = [];
var map;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    textField: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
      marginBottom: "15px",
    },
    textField_2: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
      marginTop: "10px",
    },
    textField_3: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
      marginBottom: "16px",
      marginTop: "10px",
    },
    btnStyle: {
      width: "50%",
      marginTop: "15px",
      fontWeight: "bold",
    },
    buttonTextStyle: {
      color: "#fff",
      fontWeight: "bold",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#fff",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    verticalPadding_8: {
      // paddingTop: 4,
      paddingBottom: 8,
    },
    notificationBar: {
      color: "#888",
      fontSize: "12px",
    },
    map_height: {
      height: "20rem",
    },
    delete_circle_button: {
      position: "absolute",
      marginTop: "-3rem",
      marginLeft: "38%",
      backgroundColor: "#fff",
    },
    display_none: {
      display: "none",
    },
    padderBoth: {
      // paddingBottom: 16,
      paddingTop: 16,
    },
    padderTop: {
      // paddingBottom: 16,
      paddingTop: 8,
    },
    btnItem: {
      width: "100%",
    },
  })
);

const CreateNotification = (props: any) => {
  const { location } = props;
  const classes = useStyles();
  const [shortText, setShortText] = React.useState("");
  const [mobileUsersCount, setMobileUsersCount] = React.useState(0);
  const [fullTextKey, setFullTextKey] = React.useState(false);
  const [fullText, setFullText] = React.useState("");
  const [defaultFullText, setDefaultFullText] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [categoryData, setCategoryData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [selectedType, setSelectedType] = React.useState("Notification");
  const [types, setTypes] = React.useState([{}]);
  const breadData = [
    { title: "Notifications", link: routePathNames.NOTIFICATIONS },
  ];
  const actionType = props.actionType;
  const [useDistance, setUseDistance] = React.useState("no");
  const [useDistanceArr, setUseDistanceArr] = React.useState([{}]);
  const [scheduleFor, setScheduleFor] = React.useState("");
  const [circleData, setCircleData] = React.useState([] as any);
  const [numOfNotification, setNumOfNotification] = React.useState(1);
  const [notificationDifference, setNotificationDifference] = React.useState(1);
  const [expire, setExpire] = React.useState("");

  useEffect(() => {
    fetchMobileUsersCount();
    fetchNotificationsCategories();
    manageNotificationTypes();
    manageDistanceArr();
    renderMapFunc();
  }, []);

  const clearBounds = () => {
    circles.map((item) => {
      item.setMap(null);
    });
    setCircleData([]);
  };

  useEffect(() => {
    if (useDistance === "no") {
      clearBounds();
    }
  }, [useDistance]);

  const renderMapFunc = () => {
    setTimeout(() => {
      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 39.2709, lng: -74.5875 },
        zoom: 8,
      });
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.CIRCLE,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.CIRCLE],
        },
        markerOptions: {
          icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
        },
        circleOptions: {
          fillColor: "transparent",
          fillOpacity: 1,
          strokeWeight: 2,
          clickable: true,
          editable: true,
          zIndex: 1,
        },
      });

      google.maps.event.addListener(
        drawingManager,
        "circlecomplete",
        function (circle: any) {
          circles.unshift(circle);
          let obj = {
            radius: circle.getRadius(),
            lat: circle.getCenter().lat(),
            long: circle.getCenter().lng(),
          };
          circleData.unshift(obj);
          console.log("Notification ~ radius", circles, obj);
          setCircleData(circleData);
        }
      );
      drawingManager.setMap(map);
    }, 1000);
  };

  const manageDistanceArr = () => {
    var distanceArr = [
      { label: "YES", value: "yes" },
      { label: "NO", value: "no" },
    ];
    setUseDistanceArr(distanceArr);
  };

  const manageNotificationTypes = () => {
    var types = [
      { label: "Notification", value: "Notification" },
      { label: "Banner", value: "Banner" },
      { label: "Banner & Notification", value: "Banner & Notification" },
    ];
    setTypes(types);
  };
  const fetchMobileUsersCount = () => {
    console.log("method call fetchMobileUsersCount");
    props
      .getMobileUsersCount()
      .then((result: any) => {
        console.log("data -", result.data.count);
        setMobileUsersCount(result.data.count);
      })
      .catch((error: any) => {
        console.log("error -", error);
      });
  };

  const fetchNotificationsCategories = () => {
    props
      .getNotificationsCategories()
      .then((result: any) => {
        let data = _.cloneDeep(result.data.categories);
        let filterCat = data.map((category: Category) => {
          return {
            value: category.id,
            label: category.name,
          };
        });
        setCategoryData(filterCat);
        setCategory(data[0]["id"]);
      })
      .catch((error: any) => {
        console.log("error ==>", error);
      });
  };

  const sendNotificationCall = (body: notification_payload) => {
    console.log("sendNotification ~ body", body);
    setLoading(true);
    props
      .sendNotification(body)
      .then((result: any) => {
        console.log("sendNotification ~ body ~ response", result);
        setLoading(false);
        if (result && result.isSuccess) {
          clearAll();
          toggleSnackBar(true, result.data.message);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const checkEmpty = () => {
    if (shortText === "" || fullText === "") {
      return false;
    } else {
      return true;
    }
  };

  const generateAndValidatePayload = () => {
    if (checkEmpty()) {
      let circleInfo: any =
        circleData && circleData.length ? circleData[0] : [];
      let body = {
        type: selectedType,
        notification_text: shortText,
        full_text: fullText,
        radius: circleInfo && circleInfo.radius ? circleInfo.radius : 0,
        lat: circleInfo && circleInfo.lat ? circleInfo.lat : "",
        lng: circleInfo && circleInfo.long ? circleInfo.long : "",
        category: category,
        scheduled: scheduleFor
          ? moment(scheduleFor).format("YYYY-MM-DD HH:mm:ss")
          : null,
        expires: expire ? moment(expire).format("YYYY-MM-DD HH:mm:ss") : null,
        numOfNotification: useDistance === "yes" ? numOfNotification : null,
        notificationDifference:
          useDistance === "yes" ? notificationDifference : null,
      };
      console.log("generateAndValidatePayload", body);
      if (selectedType === "Notification") {
        checkLocationSelectedOrNot(body);
      } else if (selectedType === "Banner") {
        if (checkDates(body)) {
          sendNotificationCall(body);
        } else {
          toggleSnackBar(true, alert.scheduleAndExpireDate);
        }
      } else if (selectedType === "Banner & Notification") {
        if (checkDates(body)) {
          checkLocationSelectedOrNot(body);
        } else {
          toggleSnackBar(true, alert.scheduleAndExpireDate);
        }
      }
    } else {
      toggleSnackBar(true, alert.shortFullText);
    }
  };

  const checkDates = (body: notification_payload) => {
    if (body.scheduled !== null && body.expires !== null) {
      return true;
    } else {
      return false;
    }
  };

  const clearAll = () => {
    setDefaultFullText(`&nbsp;`);
    setFullText("");
    setShortText("");
    setSelectedType("Notification");
    setUseDistance("no");
    setScheduleFor("");
    setNumOfNotification(1);
    setNotificationDifference(1);
    setExpire("");
    clearBounds();
    setFullTextKey(!fullTextKey);
  };

  const checkLocationSelectedOrNot = (body: notification_payload) => {
    const isLocationSeletced: any =
      circleData && circleData.length ? true : false;
    if (useDistance === "yes") {
      if (isLocationSeletced) {
        if (body.numOfNotification && body.notificationDifference) {
          sendNotificationCall(body);
        } else {
          toggleSnackBar(true, alert.numOfnotificationAndDifference);
        }
      } else {
        toggleSnackBar(true, alert.LOCATION_SELECT);
      }
    } else {
      sendNotificationCall(body);
    }
  };

  const renderItems = (title: any, data: any) => {
    return (
      <div style={{ paddingTop: "8px" }}>
        <Typography gutterBottom variant="body2" style={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        {data}
      </div>
    );
  };

  return (
    <>
      <SimpleBreadcrumbs breadcrumbData={breadData} heading={"Add New"} />
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container justify="center">
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              id="outlined-size-small"
              label="PROJECTED USERS FOR NOTIFICATION"
              variant="outlined"
              value={mobileUsersCount}
              InputProps={{
                readOnly: true,
              }}
            />

            <SelectMenu
              heading={"TYPE"}
              selectedValue={selectedType}
              data={types}
              setValueCallback={(data: any) => setSelectedType(data)}
            />
            <Typography
              gutterBottom
              variant="body2"
              style={{ fontWeight: "bold", marginBottom: "13px" }}
            >
              <span className={classes.notificationBar}>
                {"Text that will show in alert bubble & banner."}
              </span>
            </Typography>
            <TextField
              className={classes.textField}
              id="outlined-multiline-static"
              label="SHORT TEXT"
              multiline
              rows={4}
              value={shortText}
              onChange={(event: Event) => setShortText(event.target.value)}
              variant="outlined"
            />

            <div style={{ paddingTop: "8px" }}>
              <Typography
                gutterBottom
                variant="body2"
                style={{ fontWeight: "bold" }}
              >
                {"FULL TEXT"}
              </Typography>
              <TrumbowygTextEditor
                id="notification"
                key={fullTextKey}
                textData={defaultFullText}
                setDataCallback={(data: any) => setFullText(data)}
              />
            </div>
            {selectedType !== "Banner" ? (
              <Grid className={classes.padderBoth}>
                <SelectMenu
                  heading={"USE DISTANCE"}
                  selectedValue={useDistance}
                  data={useDistanceArr}
                  setValueCallback={(data: any) => setUseDistance(data)}
                />
              </Grid>
            ) : null}
            {useDistance === "yes" ? (
              <Typography
                gutterBottom
                variant="body2"
                style={{ fontWeight: "bold" }}
              >
                <span className={classes.notificationBar}>
                  Please select one location/radius
                </span>
              </Typography>
            ) : null}
            {useDistance === "yes" && selectedType !== "Banner" ? (
              renderItems(
                "",
                <Grid>
                  <div id="map" className={classes.map_height}></div>
                  <Button
                    className={classes.delete_circle_button}
                    variant="contained"
                    onClick={() => clearBounds()}
                  >
                    Delete Selected Area
                  </Button>
                </Grid>
              )
            ) : (
              <div id="map"></div>
            )}
            {useDistance === "yes"
              ? renderItems(
                  <span className={classes.notificationBar}>
                    Max times an user can receive notifications
                  </span>,
                  <TextField
                    className={classes.textField_2}
                    id="outlined-secondary"
                    label="NUMBER OF NOTIFICATIONS"
                    variant="outlined"
                    value={numOfNotification}
                    onChange={(event: any) =>
                      setNumOfNotification(event.target.value)
                    }
                  />
                )
              : null}

            {useDistance === "yes" && selectedType !== "Banner"
              ? renderItems(
                  <span className={classes.notificationBar}>
                    Min time delay between notifications (in Days)
                  </span>,
                  <TextField
                    className={classes.textField_3}
                    id="outlined-secondary"
                    label="NOTIFICATION DIFFERENCE"
                    variant="outlined"
                    value={notificationDifference}
                    onChange={(event: any) =>
                      setNotificationDifference(event.target.value)
                    }
                  />
                )
              : null}
            {selectedType !== "Banner" ? (
              <SelectMenu
                heading={"CATEGORY"}
                selectedValue={category}
                data={categoryData}
                setValueCallback={(data: any) => setCategory(data)}
              />
            ) : null}

            <Typography
              gutterBottom
              variant="body2"
              style={{ fontWeight: "bold" }}
            >
              <span className={classes.notificationBar}>
                Leave Blank to send immediatly
              </span>
            </Typography>
            <Grid className={classes.padderBoth}>
              <TextField
                className={classes.textField}
                id="datetime-local"
                label="SCHEDULE FOR"
                variant="outlined"
                type="datetime-local"
                value={scheduleFor}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event: Event) => setScheduleFor(event.target.value)}
                InputProps={{
                  endAdornment: scheduleFor ? (
                    <IconButton onClick={() => setScheduleFor("")}>
                      <ClearIcon />
                    </IconButton>
                  ) : null,
                }}
              />
            </Grid>

            {selectedType !== "Notification" ? (
              <Grid>
                <TextField
                  className={classes.textField}
                  id="datetime-local"
                  label="EXPIRES"
                  variant="outlined"
                  type="datetime-local"
                  value={expire}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event: Event) => setExpire(event.target.value)}
                  InputProps={{
                    endAdornment: expire ? (
                      <IconButton onClick={() => setExpire("")}>
                        <ClearIcon />
                      </IconButton>
                    ) : null,
                  }}
                />
              </Grid>
            ) : null}
            <Grid
              container
              xs={12}
              justify="center"
              className={classes.btnItem}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.btnStyle}
                size="large"
                onClick={generateAndValidatePayload}
              >
                <Typography className={classes.buttonTextStyle}>
                  {"Send Notification"}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
      <Loading isFetching={loading} />
    </>
  );
};

const mapState = (state: any) => {
  return {
    mobileUserCountSuccessFailure:
      state.notifications.mobileUserCountSuccessFailure,
    notificationsCategoriesSuccessFailure:
      state.notifications.notificationsCategoriesSuccessFailure,
  };
};

const mapDispatch = {
  getMobileUsersCount: () => getMobileUsersCount(),
  getNotificationsCategories: () => getNotificationsCategories(),
  sendNotification: (body: notification_payload) => sendNotification(body),
};

const connector = connect(mapState, mapDispatch);
export default connector(CreateNotification);
