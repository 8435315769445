export const STORIES_LIST_SUCCESS_FAILURE: string =
  "STORIES_LIST_SUCCESS_FAILURE";
export const CHANGE_INSTAIMG_PUBLISH_STATUS_SUCCESS_FAILURE: string =
  "CHANGE_INSTAIMG_PUBLISH_STATUS_SUCCESS_FAILURE";
export const DELETE_STORY_SUCCESS_FAILURE: string =
  "DELETE_STORY_SUCCESS_FAILURE";
  export const REPORT_STORIES_LIST_SUCCESS_FAILURE: string =
  "REPORT_STORIES_LIST_SUCCESS_FAILURE";


export interface InitialState {
  storiesSuccessFailure:any;
  reportStoriesSuccessFailure:any
}
export interface Action {
  type: string;
  data: object | boolean;
}


export interface story {
  id:number;
  title:string | undefined;
  description:string | undefined;
  img_url:string;
  email: string;
  user_name:string;
}

export interface storiesReport{
    reportStoryId : number,
    story_id : number,
    report_person_email : string,
    report_person_name : string,
    reason : string,
    title : string,
    description? : string,
    img_url?: string,
    userEmail : string,
    user_name?: string,
    is_enable: number,
}
export interface AdsTableColumn {
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

export interface AdsTableData {}

export interface publishStatusChange {
  status: number;
}

export interface createNewAdvertisement {
  imageFile: any;
  imgName: string;
  published: number;
  display_general: number;
  link: string;
  categoryIds: any;
}

export interface editAdvertisement {
  imageFlag?: boolean;
  imageFile?: any;
  imgName?: string;
  published: number;
  display_general: number;
  link: string;
  categoryIds: any;
}
