import React, { memo, useEffect, useState, useMemo } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TRUMBOWYG_EVENT, Event } from "../../commonTypes/types";
import { rolesArray, routePathNames, alert } from "../../utils/constants";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import _ from "lodash";
import ShowSnackBar from "../../commonComponents/snackBar";
import Typography from "@material-ui/core/Typography";
import Loading from "../../commonComponents/loading";
import * as utils from "../../utils/index";
import ImageDropZone from "../../commonComponents/imageDropZone";
import TrumbowygTextEditor from "../../commonComponents/trumbowygTextEditor";
import { icons, windDirectionList, nextTideList } from "./constants/index";
import Menu from "./components/menu";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import {
  saveAppContent,
  getIconsList,
  getWeatherDeatils,
  getAppContentDetails,
} from "./redux/actions/appContent";
import { APP_CONTENT_PAYLOAD } from "./types/home";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
    },
    btnStyle: {
      width: "50%",
      marginTop: "20px",
      fontWeight: "bold",
    },
    buttonTextStyle: {
      color: "#fff",
      fontWeight: "bold",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#fff",
      marginTop: theme.spacing(2),
    },
    imageItem: {
      order: 2,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        order: 1,
      },
    },
    contentItem: {
      order: 1,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        order: 2,
      },
    },
    btnItem: {
      width: "100%",
      order: 3,
    },
    reportText: {
      fontWeight: "bold",
    },
    dailyBeachReport: {
      backgroundColor: "#fff",
      //   margin: theme.spacing(1, "auto"),
      padding: theme.spacing(2),
    },
    weatherReportBlock: {
      backgroundColor: "#fff",
      marginTop: 60,
      padding: theme.spacing(2),
    },
    dailyBeachTitle: {
      position: "absolute",
      backgroundColor: "#5eb9df",
      color: "#fff",
      padding: 10,
      fontWeight: "bold",
      marginTop: -35,
    },
    root: {
      marginTop: 40,
    },
    dailyBeachBlock: {
      marginTop: 20,
    },
    marginFromTop: {
      marginTop: 16,
    },
    marginTop25: {
      marginTop: 24,
    },
    blankFeildsText: {
      marginTop: 32,
      color: "#757575",
    },
    currentWeatherDetails: {
      backgroundColor: "#f2f5f6",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        marginTop: theme.spacing(2),
      },
    },
    textAlignCenter: {
      textAlign: "center",
    },
    verticalPadding_8: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    longWordBreak: {
      wordWrap: "break-word",
    },
  })
);

const Home = (props: any) => {
  const { location } = props;
  const classes = useStyles();
  const [weatherReportDetails, setWeatherReportDetails] = React.useState(
    {} as any
  );
  const [iconsList, setIconsList] = React.useState([]);
  const [icon, setIcon] = React.useState("");
  const [currentTemp, setCurrentTemp] = React.useState("");
  const [lowTemp, setLowTemp] = React.useState("");
  const [highTemp, setHighTemp] = React.useState("");
  const [windDirection, setWindDirection] = React.useState("");
  const [windSpeed, setWindSpeed] = React.useState("");
  const [waterTemp, setWaterTemp] = React.useState("");
  const [swellMaxHeight, setSwellMaxHeight] = React.useState("");
  const [nextTide, setNextTide] = React.useState("");
  const [tideTime, setTideTime] = React.useState("");
  const [reportText, setReportText] = React.useState("");
  const [weatherSummary, setWeatherSummary] = React.useState("");
  const [imageData, setImageData] = React.useState([{}]);
  const [loading, setLoading] = React.useState(false);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [apiFetchedReportText, setApiFetchedReportText] = React.useState("");
  const [
    apiFetchedWeatherSummary,
    setApiFetchedWeatherSummary,
  ] = React.useState("");
  const [apiFetchedImage, setApiFetchedImage] = React.useState("");

  const breadData = [
    { title: "App Content", link: routePathNames.APP_CONTENT },
  ];

  useEffect(() => {
    console.log("Home ~ props", props);
    fetchIcons();
    fetchWeatherReport();
    fetchAppContentDetails();
  }, []);

  const fetchIcons = () => {
    let iconsArray = props.iconsListSuccessFailure;
    if (iconsArray && iconsArray.isSuccess) {
      console.log("iconsArrayiconsArray", iconsArray);
      // setIconsList(iconsArray.data.data);
      let resultArray = _.cloneDeep(iconsArray.data.data);
      resultArray.unshift({ label: "None", value: "" });
      setIconsList(resultArray);
    } else {
      setLoading(true);
      props
        .getIconsList()
        .then((result: any) => {
          console.log("getIconsList", result.data.data);
          let resultArray = _.cloneDeep(result.data.data);
          resultArray.unshift({ label: "None", value: "" });
          setIconsList(resultArray);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }
  };

  const fetchWeatherReport = () => {
    let weatherList = props.weatherDeatilsSuccessFailure;
    if (weatherList && weatherList.isSuccess) {
      console.log("weatherListweatherList", weatherList);
      setWeatherReportDetails(weatherList.data.data);
    } else {
      setLoading(true);
      props
        .getWeatherDeatils()
        .then((result: any) => {
          console.log("getWeatherDeatils", result.data.data);
          setWeatherReportDetails(result.data.data);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }
  };

  const fetchAppContentDetails = () => {
    setLoading(true);
    props
      .getAppContentDetails()
      .then((result: any) => {
        setLoading(false);
        console.log("getAppContentDetails", result.data.data);
        let pageData = result.data.data[0];
        preFilledData(pageData);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const preFilledData = (data: any) => {
    let weatherDetails = data && data.weather ? data.weather : {};
    console.log("datadata", data, weatherDetails);
    setIcon(weatherDetails.icon);
    setCurrentTemp(weatherDetails.currentTemperatureF);
    setLowTemp(weatherDetails.temperatureMin);
    setHighTemp(weatherDetails.temperatureMax);
    setWindDirection(weatherDetails.windDirection);
    setWindSpeed(weatherDetails.windSpeed);
    setWaterTemp(weatherDetails.currentWaterTemperature);
    setSwellMaxHeight(weatherDetails.swellMaxHeight);
    setNextTide(weatherDetails.tide);
    setTideTime(weatherDetails.tideTime);

    //Trumbowyg Text Data
    setWeatherSummary(weatherDetails.summary);
    setReportText(data.beachReport);
    setApiFetchedReportText(data.beachReport);
    setApiFetchedWeatherSummary(weatherDetails.summary);
    if (data && data.dailyPhoto) {
      let imgData = {
        preview: data.dailyPhoto,
      };
      setImageData([imgData]);
      setApiFetchedImage(data.dailyPhoto);
    }
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const syncImageData = (data: any) => {
    console.log("Home ~ data", data, imageData);
    if (data && data.length) {
      setImageData(data);
    } else {
      setImageData([{}]);
      // setApiFetchedImage("");
    }
  };

  const saveAppContentApiCall = (body: APP_CONTENT_PAYLOAD) => {
    setLoading(true);
    props
      .saveAppContent(body)
      .then((result: any) => {
        console.log("saveAppContent", result);
        setLoading(false);
        if (result && result.isSuccess) {
          fetchAppContentDetails();
          // preFilledData(result.data.data);
          toggleSnackBar(true, result.data.message);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const dailyBeachReportModule = () => {
    return (
      <Grid className={classes.dailyBeachReport}>
        <Typography
          variant="subtitle2"
          gutterBottom
          className={classes.dailyBeachTitle}
        >
          Daily Beach Report
        </Typography>
        <Grid container spacing={2} className={classes.dailyBeachBlock}>
          <Grid item md={3} xs={12} className={classes.imageItem}>
            <ImageDropZone
              placeHolderText={
                "Click or Drag & Drop files here to upload/replace current daily photo."
              }
              height={"350px"}
              width={"100%"}
              imageData={imageData}
              syncImageDataCallback={syncImageData}
            />
          </Grid>
          <Grid item md={9} xs={12} className={classes.contentItem}>
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.reportText}
            >
              REPORT TEXT
            </Typography>
            <TrumbowygTextEditor
              id={"dailyBeach"}
              textData={apiFetchedReportText}
              setDataCallback={setReportTextTrumbowygText}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const setWeatherTrumbowygText = (data: any) => {
    setWeatherSummary(data);
    // setApiFetchedWeatherSummary("");
  };

  const setReportTextTrumbowygText = (data: any) => {
    setReportText(data);
    // setApiFetchedReportText("");
  };

  const weatherModule = () => {
    return (
      <Grid className={classes.weatherReportBlock}>
        <Typography
          variant="subtitle2"
          gutterBottom
          className={classes.dailyBeachTitle}
        >
          Weather
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className={classes.blankFeildsText}
        >
          {"Blank fields will use weather data from API"}
        </Typography>
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.marginTop25}
          spacing={2}
        >
          <Grid item md={9} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <Menu
                  heading={"ICON"}
                  selectedValue={icon}
                  data={iconsList}
                  setValueCallback={(data: any) => setIcon(data)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.marginFromTop}
            >
              <Grid item md={4} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="CURRENT TEMP. °F"
                  variant="outlined"
                  value={currentTemp}
                  onChange={(event: Event) =>
                    setCurrentTemp(event.target.value)
                  }
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="LOW TEMP. °F"
                  variant="outlined"
                  value={lowTemp}
                  onChange={(event: Event) => setLowTemp(event.target.value)}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="HIGH TEMP. °F"
                  variant="outlined"
                  value={highTemp}
                  onChange={(event: Event) => setHighTemp(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item className={classes.marginFromTop}>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={classes.reportText}
              >
                WEATHER SUMMARY
              </Typography>
              <TrumbowygTextEditor
                id={"weather"}
                textData={apiFetchedWeatherSummary}
                setDataCallback={setWeatherTrumbowygText}
              />
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.marginFromTop}
            >
              <Grid item md={6} xs={12}>
                <Menu
                  heading={"WIND DIRECTION"}
                  selectedValue={windDirection}
                  data={utils.makeValueLabelArray(windDirectionList)}
                  setValueCallback={(data: any) => setWindDirection(data)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="WIND SPEED MPH"
                  variant="outlined"
                  value={windSpeed}
                  onChange={(event: Event) => setWindSpeed(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="WATER TEMPERATURE"
                  variant="outlined"
                  value={waterTemp}
                  onChange={(event: Event) => setWaterTemp(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="SWELL MAX HEIGHT"
                  variant="outlined"
                  value={swellMaxHeight}
                  onChange={(event: Event) =>
                    setSwellMaxHeight(event.target.value)
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Menu
                  heading={"NEXT TIDE"}
                  selectedValue={nextTide}
                  data={utils.makeValueLabelArray(nextTideList)}
                  setValueCallback={(data: any) => setNextTide(data)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="TIDE TIME"
                  variant="outlined"
                  value={tideTime}
                  onChange={(event: Event) => setTideTime(event.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container md={3} xs={12}>
            <Grid item xs={12}>
              <Box pt={2} p={2} className={classes.currentWeatherDetails}>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.textAlignCenter}
                  style={{ fontWeight: "bold" }}
                >
                  Current Weather from API
                </Typography>
                {_.isEmpty(weatherReportDetails) ? null : (
                  <>
                    {renderWeatherItems("ICON", weatherReportDetails.icon)}
                    {renderWeatherItems(
                      "CURRENT SUMMARY",
                      weatherReportDetails.currentSummary
                    )}
                    {renderWeatherItems(
                      "CURRENT TEMPERATURE",
                      weatherReportDetails.currentTemperatureF
                    )}
                    {renderWeatherItems(
                      "TEMPERATURE MIN",
                      weatherReportDetails.temperatureMin
                    )}
                    {renderWeatherItems(
                      "TEMPERATURE MAX",
                      weatherReportDetails.temperatureMax
                    )}
                    {renderWeatherItems("TIDE", weatherReportDetails.tide)}
                    {renderWeatherItems(
                      "TIDE TIME",
                      weatherReportDetails.tideTime
                    )}
                    {renderWeatherItems(
                      "WIND DIRECTION",
                      weatherReportDetails.windDirection
                    )}
                    {renderWeatherItems(
                      "WIND SPEED",
                      weatherReportDetails.windSpeed
                    )}
                    {renderWeatherItems(
                      "SWELL MAX HEIGHT",
                      weatherReportDetails.swellMaxHeight
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderWeatherItems = (title: string, data: string) => {
    return (
      <>
        {data ? (
          <div className={classes.verticalPadding_8}>
            <Typography
              className={classes.longWordBreak}
              variant="body2"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              className={classes.longWordBreak}
            >
              {data}
            </Typography>
          </div>
        ) : null}
      </>
    );
  };

  const onSave = () => {
    let data: any = imageData[0];
    let baseData =
      data && data.base64 ? data.base64.split(",")[1] : apiFetchedImage;
    let imagePreview = data && data.preview ? data.preview : "";
    let isImageChange = apiFetchedImage === imagePreview ? false : true;

    //save content payload
    let body = {
      // imgFlag: isImageChange,
      imageFile: imagePreview ? baseData : "",
      imgName: data && data.name ? data.name : "",
      beachReport: reportText ? reportText : "",
      weather: {
        icon: icon,
        summary: weatherSummary ? weatherSummary : "",
        currentTemperatureF: currentTemp,
        currentTemperatureC: utils.getCelsiusFromFahrenheit(currentTemp),
        currentWaterTemperature: waterTemp,
        temperatureMin: lowTemp,
        temperatureMax: highTemp,
        tide: nextTide,
        tideTime: tideTime,
        windSpeed: windSpeed,
        windDirection: windDirection,
        swellMaxHeight: swellMaxHeight,
      },
    };
    console.log("onSave ~ body", body);
    saveAppContentApiCall(body);
  };

  return (
    <React.Fragment>
      <SimpleBreadcrumbs breadcrumbData={breadData} heading={"Manage"} />
      <form noValidate autoComplete="off">
        <Grid className={classes.root}>
          {dailyBeachReportModule()}
          {weatherModule()}
          <Grid container xs={12} justify="center" className={classes.btnItem}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnStyle}
              size="large"
              onClick={onSave}
            >
              <Typography className={classes.buttonTextStyle}>
                {"Save"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
      <Loading isFetching={loading} />
    </React.Fragment>
  );
};

const mapState = (state: any) => {
  return {
    appContentSuccessFailure: state.saveAppContent.appContentSuccessFailure,
    iconsListSuccessFailure: state.saveAppContent.iconsListSuccessFailure,
    weatherDeatilsSuccessFailure:
      state.saveAppContent.weatherDeatilsSuccessFailure,
  };
};

const mapDispatch = {
  saveAppContent: (body: APP_CONTENT_PAYLOAD) => saveAppContent(body),
  getIconsList: () => getIconsList(),
  getWeatherDeatils: () => getWeatherDeatils(),
  getAppContentDetails: () => getAppContentDetails(),
};

const connector = connect(mapState, mapDispatch);
export default connector(React.memo(Home));
