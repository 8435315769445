export const alert = {
  mandatoryFields: "All fields are mandatory",
  allStarFieldsRequired: "All (*) mark fields are required.",
  inValidEmail: "Email is not valid",
  upload: "Please Upload Image",
  submitSuccess: "Data Submit Successfully",
  EMPTY_EMAIL_VALIDATION: "Please Enter Your Email-ID.",
  EMPTY_CONFIRM_EMAIL_VALIDATION: "Please Enter Your Confirm Email-ID.",
  EMAIL_VALIDATION: "Please Enter Valid Email-ID.",
  EMPTY_PASSWORD_VALIDATION: "Please Enter Your Password.",
  EMPTY_OLD_PASSWORD_VALIDATION: "Please Enter Your Old Password.",
  CONFIRM_EMPTY_PASSWORD_VALIDATION: "Please Enter Your Confirm Password.",
  passwordMandatoryFields: "Please fill the password field",
  primaryContactMandatoryFields: "Please fill all the primary contact field(s)",
  fillTimeHours: "Please select open and close hour(s) for ",
  duplicateCategory: "Category with this name already exist",
  fillCategoryName: "Please enter category name",
  phoneNumber: "Phone number should be 10 digits long.",
  restaurantPhoneNumber: "Restaurant phone number should be 10 digits long.",
  selectCategories: "Please select your categories",
  menuName: "Please fill menu name",
  selectCategory: "Please select category(ies)",
  addDish: "Please add some dish in category menu",
  daysAndTime: "Please select weekday(s) from Availability",
  categoryPresent: "Please add categories from manage categories first",
  fillRestaurantName: "Please enter restaurant name",
  someThingWentWRong: "something went wrong please try again later",
  samePassword: "Password and confirm password should be same",
  SAME_EMAIL: "Email and confirm email should be same",
  CHECK_CREDENTIALS: "Username or password is incorrect.",
  SHORT_TEXT: "Please fill short text.",
  LONG_TEXT: "Please fill long text.",
  DATE_TIME: "Please select date and time.",
  LOCATION_SELECT: "Please select any location",
  AUDIENCE_TYPE: "Please select target audience",
  ROLES_VALIDATION: "Please select any role(s).",
  searchAlert: "Please write any keyword before search.",
  shortFullText: "Please enter short text and full text.",
  scheduleAndExpireDate: "Please select schedule and expire date.",
  numOfnotificationAndDifference:
    "Please select number of notification and notification difference fields.",
};

export const routePathNames = {
  SIGNIN: "/",
  FORGOT_PASSWORD: "/forgotPassword",
  RESET_PASSWORD: "/resetPassword/:any",
  DASHBOARD: "/dashboard",
  CREATE_USER: "/dashboard/users/createUser",
  EDIT_USER: "/dashboard/users/editUser",
  USERS: "/dashboard/users",
  ADVERTISEMENTS: "/dashboard/advertisements",
  CREATE_ADVERTISEMENT: "/dashboard/advertisements/createNew",
  EDIT_ADVERTISEMENT: "/dashboard/advertisements/edit",
  APP_CONTENT: "/dashboard/appContent",
  CREATE_NOTIFICATION: "/dashboard/notifications/addNew",
  NOTIFICATIONS: "/dashboard/notifications",
  PLACES: "/dashboard/places",
  ADD_PLACES: "/dashboard/places/addNew",
  EDIT_PLACES: "/dashboard/places/edit",
  VENDOR_EDIT_PLACES: "/dashboard/places/vendorEdit",
  ADMIN_EDIT_REVISION: "/dashboard/places/revisions/edit",
  PENDING_REVISION: "/dashboard/places/revisions",
  EVENTS: "/dashboard/events",
  ADD_EVENTS: "/dashboard/events/addNew",
  EDIT_EVENT: "/dashboard/events/edit",
  INSTAGRAM: "/dashboard/instagram/list",
  USER_STORIES: "/dashboard/stories/userStories",
  REPORTED_STORIES: "/dashboard/stories/reportedStories",
  HOME_SCREEN_IMAGES:"/dashboard/homeScreenImages",
  CREATE_HOMESCREEN_IMAGES:"/dashboard/homeScreenImages/addImage",
  EDIT_HOMESCREEN_IMAGES:"/dashboard/homeScreenImages/editImage"
  
};

export const vendorRoute = [
  {
    name: "Places",
    path: routePathNames.PLACES,
    childs: [
      {
        name: "Manage",
        path: [
          routePathNames.PLACES,
          routePathNames.EDIT_PLACES,
          routePathNames.VENDOR_EDIT_PLACES,
        ],
      },
    ],
  },
];

export const adminRoutes = [
  {
    name: "App Content",
    path: routePathNames.APP_CONTENT,
    childs: [{ name: "Manage", path: [routePathNames.APP_CONTENT] }],
  },
  {
    name: "Places",
    path: routePathNames.PLACES,
    childs: [
      {
        name: "Add New",
        path: [routePathNames.ADD_PLACES],
      },
      {
        name: "Manage",
        path: [routePathNames.PLACES, routePathNames.EDIT_PLACES],
      },
      {
        name: "Pending Revisions",
        path: [
          routePathNames.PENDING_REVISION,
          routePathNames.ADMIN_EDIT_REVISION,
        ],
      },
    ],
  },
  {
    name: "Events",
    path: routePathNames.EVENTS,
    childs: [
      // { name: "Add New", path: [routePathNames.ADD_EVENTS] },
      {
        name: "Manage",
        path: [routePathNames.EVENTS, routePathNames.EDIT_EVENT],
      },
    ],
  },
  {
    name: "Advertisements",
    path: routePathNames.ADVERTISEMENTS,
    childs: [
      { name: "Add New", path: [routePathNames.CREATE_ADVERTISEMENT] },
      {
        name: "Manage",
        path: [
          routePathNames.ADVERTISEMENTS,
          routePathNames.EDIT_ADVERTISEMENT,
        ],
      },
    ],
  },
  {
    name: "Users",
    path: routePathNames.USERS,
    childs: [
      { name: "Add New", path: [routePathNames.CREATE_USER] },
      {
        name: "Manage",
        path: [routePathNames.USERS, routePathNames.EDIT_USER],
      },
    ],
  },
  {
    name: "Notifications",
    path: routePathNames.NOTIFICATIONS,
    childs: [
      { name: "New Notification", path: [routePathNames.CREATE_NOTIFICATION] },
      { name: "History", path: [routePathNames.NOTIFICATIONS] },
    ],
  },
  {
    name: "Instagram Images",
    path: routePathNames.INSTAGRAM,
    childs: [{ name: "Manage", path: [routePathNames.INSTAGRAM] }],
  },
  {
    name: "User Stories",
    path: routePathNames.USER_STORIES,
    childs: [
      { name: "Manage Stories", path: [routePathNames.USER_STORIES] },
      { name: "Reported Stories", path: [routePathNames.REPORTED_STORIES] },
    ],
  },
  {
    name: "Home Screen Images",
    path: routePathNames.HOME_SCREEN_IMAGES,
    childs: [
      { name: "Add New", path: [routePathNames.CREATE_HOMESCREEN_IMAGES] },
      {
        name: "Manage",
        path: [
          routePathNames.HOME_SCREEN_IMAGES,
          routePathNames.EDIT_HOMESCREEN_IMAGES,
        ],
      },
    ],
  },
];

export const rolesArray = [
  {
    name: "Administrator",
    id: "Administrator",
  },
  {
    name: "Vendor",
    id: "Vendor",
  },
];
