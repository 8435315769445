import {
  GET_PLACES_SUCCESS_FAILURE,
  InitialState,
  Action,
  CHANGE_PLACE_STATUS_SUCCESS_FAILURE,
  DELETE_PLACE_STATUS_SUCCESS_FAILURE,
  VENDORS_LIST_SUCCESS_FAILURE,
  ADD_PLACE_SUCCESS_FAILURE,
  UPDATE_PLACE_SUCCESS_FAILURE,
  SINGLE_PLACE_DATA_SUCCESS_FAILURE,
} from "../../types/places";

const initialState: InitialState = {
  placesSuccessFailure: [],
  changePlaceStatusSuccessFailure: undefined,
  deletePlaceStatusSuccessFailure: undefined,
  vendorsListSuccessFailure: undefined,
  addPlaceSuccessFailure: undefined,
  updatePlaceSuccessFailure: undefined,
  singlePlaceDataSuccessFailure: undefined,
};

const getPlaces = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case GET_PLACES_SUCCESS_FAILURE:
      return {
        ...state,
        placesSuccessFailure: actions.data,
      };
    case CHANGE_PLACE_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        changePlaceStatusSuccessFailure: actions.data,
      };
    case DELETE_PLACE_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        deletePlaceStatusSuccessFailure: actions.data,
      };
    case VENDORS_LIST_SUCCESS_FAILURE:
      return {
        ...state,
        vendorsListSuccessFailure: actions.data,
      };
    case ADD_PLACE_SUCCESS_FAILURE:
      return {
        ...state,
        addPlaceSuccessFailure: actions.data,
      };
    case UPDATE_PLACE_SUCCESS_FAILURE:
      return {
        ...state,
        updatePlaceSuccessFailure: actions.data,
      };
    case SINGLE_PLACE_DATA_SUCCESS_FAILURE:
      return {
        ...state,
        singlePlaceDataSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default getPlaces;
