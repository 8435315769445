import AxiosInstance from "../api/axios";
import _ from 'lodash';
var moment = require('moment');
var jwt = require('jsonwebtoken');

//jwt secret
const secret = "Ocean_city_vacation_secret"

export const phoneNumberValidation = phoneNumber => {
    if (phoneNumber.includes('+91')) return phoneNumber.length === 13;
    else return phoneNumber.length === 10;
};

export const validateEmail = email => {
    var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
};

export const getFormattedAddress = geocodeSuccessFailure => {
    return geocodeSuccessFailure && geocodeSuccessFailure.isSuccess
        ? geocodeSuccessFailure.response[0].formattedAddress
        : '';
};

export const formatDate = (dateVal) => {
    var newDate = new Date(dateVal);

    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var sMinute = padValue(newDate.getMinutes());
    var sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
        sAMPM = "PM";
        sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
        sHour = "12";
    }

    sHour = padValue(sHour);

    return sMonth + "-" + sDay + "-" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
}

function padValue(value) {
    return (value < 10) ? "0" + value : value;
}

export const isConfirmPasswordSame = (password, confirmPassword) => {
    return password === confirmPassword;
};

export const passwordLength = password => {
    return password.length >= 5;
};

export const checkPassword = password => {
    let phoneno = /^[ 0-9-.()]*$/;
    return phoneno.test(password);
};

export const setLoginDetails = (data) => {
    localStorage.setItem("login", JSON.stringify(data));
}

export const getLoginDetails = () => {
    return jwt.verify(getAccessToken(),
        secret,
        (err, decoded) => {
            let userData = decoded && decoded.userData ? decoded.userData : {};
            let roles = decoded && decoded.role ? decoded.role : []
            userData.role = roles;
            return userData;
        });
}

export const getRole = (tokenIf) => {
    const token = tokenIf ? tokenIf : getAccessToken();
    return jwt.verify(token,
        secret,
        (err, decoded) => {
            let roles = decoded && decoded.role ? decoded.role : []
            if (roles && roles.length === 2) {
                return "Administrator";
            } else {
                if (roles[0] === "Vendor") {
                    return "Vendor";
                } else {
                    return "Administrator";
                }
            }
        });
}

export const setAuthToken = (token) => {
    console.log("setAuthToken -> token", token)
    AxiosInstance.defaults.headers['x-access-token'] = token;
}

export const setCorsHeader = () => {
    AxiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
}

export const saveToken = async (token) => {
    await localStorage.setItem("token", JSON.stringify(token));
}

export const setToken = () => {
    let savedData = JSON.parse(localStorage.getItem("token"));
    console.log("setToken -> savedData", savedData)
    AxiosInstance.defaults.headers['x-access-token'] = savedData;
    AxiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
}

export const getAccessToken = () => {
    let savedData = JSON.parse(localStorage.getItem("token"));
    return savedData;
}

//fetch the client ip from the browser
export const fetchClientIpAddress = async () => {
    console.log("fetchClientIpAddress", getClientIp())
    if (getClientIp() === null) {
        fetch("https://api.ipify.org?format=json")
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                let myIp = _.get(res, "ip");
                saveClientIp(myIp);
                console.log("myIpmyIpmyIp", myIp);
            })
            .catch((err) => console.log(err));
    }
}

export const saveClientIp = async (token) => {
    await localStorage.setItem("client_ip", JSON.stringify(token));
}

export const getClientIp = () => {
    let savedData = JSON.parse(localStorage.getItem("client_ip"));
    return savedData;
}

export const convertDate = (date) => {
    let day = date.getDay();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let MM_dd_yyyy = month + '/' + year + '/' + day; // That’s your formatted date.
    return MM_dd_yyyy;
}

export const convertTimeFormat = (time) => {
    return moment(time, ["h:mm A"]).format("HH:mm");
}

export const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
}

export const makeValueLabelArray = (data) => {
    let tempArray = [];
    data.map((item) => {
        tempArray.push({ label: item, value: item, })
    })
    tempArray.unshift({ label: 'None', value: "" })
    return tempArray;
}

export const getCelsiusFromFahrenheit = (fahrenheit) => {
    return Math.round((fahrenheit - 32) * (5 / 9));
}

export const getFahrenheitFromCelsius = (celsius) => {
    return (celsius * (9 / 5)) + 32;
}

export const getReviewStatus = (data) => {
    return data === 0 ? 'Pending Approval' : 'Approval Rejected'
}

export const convertDateInRevision = (date) => {
    var momentDate = moment(date).format('DD/MM/YYYY hh:mm A');
    return momentDate;
}

export const convertDateInEvents = (date, time) => {
    let momentDate = moment(date).format('MM/DD/YYYY');
    let momentTime = moment(time, ["hh:mm:ss"]).format("h:mm A");
    return (momentDate + " " + momentTime);
}

export const convertDateInYYYYMMDDFormat = (date) => {
    let momentDate = moment(date).format('YYYY-MM-DD');
    return momentDate
}

export const convertTime24To12 = (time) => {
    let momentTime = moment(time, ["hh:mm:ss"]).format("h:mm A");
    return momentTime;
}

export const convertDateForNotification = (date) => {
    let momentDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    return momentDate
}

export const valiateNotificationDiffernece = text => {
    // it takes value from 0.1 to 100
    let numbers = /^(0\.[1-9]|[1-9][0-9]{,2}\.[0-9])$/;
    return numbers.test(text);
};

export const removeHtmltagsAndSpace = text => {
    // it removes HTML tags
    let tagsRemoved = text.replace(/<(.|\n)*?>/g, "");
    // it removes &nbsp spaces
    return tagsRemoved.replace(/&nbsp;/g, "");
};

