import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import { USER_SIGN_IN_SUCCESS_FAILURE, Login } from "../../types/signin";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const signInUserSuccessFailure = (data: any) => {
  return {
    type: USER_SIGN_IN_SUCCESS_FAILURE,
    data,
  };
};

export const userSignIn = (body: Login) => {
  return (dispatch: Dispatch) => {
    const url = "logIn/";
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(signInUserSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(signInUserSuccessFailure(result));
        return result;
      });
  };
};
