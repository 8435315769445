import {
  InitialState,
  Action,
  GET_EVENTS_SUCCESS_FAILURE,
  CHANGE_EVENT_STATUS_SUCCESS_FAILURE,
  DELETE_EVENT_STATUS_SUCCESS_FAILURE,
  ADD_EVENT_SUCCESS_FAILURE,
  UPDATE_EVENT_SUCCESS_FAILURE,
  SINGLE_EVENT_DATA_SUCCESS_FAILURE,
  EVENT_CATEGORY_SUCCESS_FAILURE,
  EVENT_VENUES_SUCCESS_FAILURE,
} from "../../types/events";

const initialState: InitialState = {
  eventsSuccessFailure: undefined,
  changeEventStatusSuccessFailure: undefined,
  deleteEventSuccessFailure: undefined,
  addEventSuccessFailure: undefined,
  updateEventSuccessFailure: undefined,
  singleEventDataSuccessFailure: undefined,
  getEventCategorySuccessFailure: undefined,
  getEventVenuesSuccessFailure: undefined,
};

const getEvents = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case GET_EVENTS_SUCCESS_FAILURE:
      return {
        ...state,
        eventsSuccessFailure: actions.data,
      };
    case CHANGE_EVENT_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        changeEventStatusSuccessFailure: actions.data,
      };
    case DELETE_EVENT_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        deleteEventSuccessFailure: actions.data,
      };
    case ADD_EVENT_SUCCESS_FAILURE:
      return {
        ...state,
        addEventSuccessFailure: actions.data,
      };
    case UPDATE_EVENT_SUCCESS_FAILURE:
      return {
        ...state,
        updateEventSuccessFailure: actions.data,
      };
    case SINGLE_EVENT_DATA_SUCCESS_FAILURE:
      return {
        ...state,
        singleEventDataSuccessFailure: actions.data,
      };
    case EVENT_CATEGORY_SUCCESS_FAILURE:
      return {
        ...state,
        getEventCategorySuccessFailure: actions.data,
      };
    case EVENT_VENUES_SUCCESS_FAILURE:
      return {
        ...state,
        getEventVenuesSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default getEvents;
