import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  CREATE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE,
  EDIT_HOME_SCREEN_IMAGES_SUCCESS_FAILURE,
  HOMESCREENIMAGES_LIST_SUCCESS_FAILURE,
  CHANGE_HOME_SCREEN_IMAGES_PUBLISH_STATUS_SUCCESS_FAILURE,
  DELETE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE,
  createNewHomeScreenImage,
  editHomeScreenImage,
  publishStatusChange,
} from "../../types/homescreenimages";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const createHomeScreenImagesSuccessFailure = (data: any) => {
  return {
    type: CREATE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE,
    data,
  };
};

const editHomeScreenImagesSuccessFailure = (data: any) => {
  return {
    type: EDIT_HOME_SCREEN_IMAGES_SUCCESS_FAILURE,
    data,
  };
};

const homeScreenImagesSuccessFailure = (data: any) => {
  return {
    type: HOMESCREENIMAGES_LIST_SUCCESS_FAILURE,
    data,
  };
};

const homeScreenImagesPublishStatusSuccessFailure = (data: any) => {
  return {
    type: CHANGE_HOME_SCREEN_IMAGES_PUBLISH_STATUS_SUCCESS_FAILURE,
    data,
  };
};

const deletehomeScreenImagesSuccessFailure = (data: any) => {
  return {
    type: DELETE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE,
    data,
  };
};

export const getHomeScreenImages = (pageNo: number) => {
  return (dispatch: Dispatch) => {
    const url = "homeScreenImage/" + pageNo;

    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(homeScreenImagesSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(homeScreenImagesSuccessFailure(result));
        return result;
      });
  };
};

export const deleteHomeScreenImages = (id: number) => {
  return (dispatch: Dispatch) => {
    const url = "homeScreenImage/" + id;
    return AxiosInstance.delete(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(deletehomeScreenImagesSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(deletehomeScreenImagesSuccessFailure(result));
        return result;
      });
  };
};

export const changeHomeScreenImagesPublishStatus = (
  id: number,
  body: publishStatusChange
) => {
  return (dispatch: Dispatch) => {
    const url = "homeScreenImage/status/" + id;
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(homeScreenImagesPublishStatusSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(homeScreenImagesPublishStatusSuccessFailure(result));
        return result;
      });
  };
};

export const createHomeScreenImages = (body: createNewHomeScreenImage) => {
  return (dispatch: Dispatch) => {
    const url = "homeScreenImage";
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(createHomeScreenImagesSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(createHomeScreenImagesSuccessFailure(result));
        return result;
      });
  };
};

export const editHomeScreenImagesCall = (
  id: number,
  body: editHomeScreenImage
) => {
  console.log("editHomeScreenImagesCall", id, body);
  return (dispatch: Dispatch) => {
    const url = "homeScreenImage/" + id;
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(editHomeScreenImagesSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(editHomeScreenImagesSuccessFailure(result));
        return result;
      });
  };
};
