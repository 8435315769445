import React, { memo, useEffect, useState, useMemo } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TRUMBOWYG_EVENT, Event } from "../../commonTypes/types";
import { rolesArray, routePathNames, alert } from "../../utils/constants";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import _ from "lodash";
import ShowSnackBar from "../../commonComponents/snackBar";
import Typography from "@material-ui/core/Typography";
import Loading from "../../commonComponents/loading";
import * as utils from "../../utils/index";
import ImageDropZone from "../../commonComponents/imageDropZone";
import TrumbowygTextEditor from "../../commonComponents/trumbowygTextEditor";
import Menu from "../appContent/components/menu";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { publishUnpublish, adminPublishPlaceActions } from "./constants/index";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import CategoryListComponent from "../../commonComponents/categoryList";
import { getCategories } from "../advertisements/redux/actions/advertisement";
import {
  addPlace,
  getVendorsList,
  getSinglePlace,
  updatePlace,
  getClientIpAddress,
} from "./redux/actions/getPlaces";
import VendorsList from "./components/vendorsList";
import { createPlace, editPlace } from "./types/places";
import {
  vendorSubmitPlaceForReview,
  adminUpdatePendingRevision,
} from "./redux/actions/revisions";
import {
  vendorEditPlaceForRevision,
  adminPendingRevision,
} from "./types/revisions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
    },
    textFieldWithBottomMargin: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
      marginBottom: "16px",
    },
    btnStyle: {
      width: "50%",
      marginTop: "20px",
      fontWeight: "bold",
    },
    buttonTextStyle: {
      color: "#fff",
      fontWeight: "bold",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#fff",
      marginTop: theme.spacing(2),
    },
    imageItem: {
      order: 2,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        order: 1,
      },
    },
    contentItem: {
      order: 1,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        order: 2,
      },
    },
    btnItem: {
      width: "100%",
      order: 3,
    },
    reportText: {
      fontWeight: "bold",
    },
    dailyBeachReport: {
      backgroundColor: "#fff",
      //   margin: theme.spacing(1, "auto"),
      padding: theme.spacing(2),
    },
    weatherReportBlock: {
      backgroundColor: "#fff",
      marginTop: 60,
      padding: theme.spacing(2),
    },
    dailyBeachTitle: {
      position: "absolute",
      backgroundColor: "#5eb9df",
      color: "#fff",
      padding: 10,
      fontWeight: "bold",
      marginTop: -35,
    },
    root: {
      marginTop: 40,
    },
    dailyBeachBlock: {
      marginTop: 20,
    },
    marginFromTop: {
      marginTop: 16,
    },
    marginTop25: {
      marginTop: 25,
    },
    currentWeatherDetails: {
      backgroundColor: "#f2f5f6",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        marginTop: theme.spacing(2),
      },
    },
    textAlignCenter: {
      textAlign: "center",
    },
    verticalPadding_8: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    longWordBreak: {
      wordWrap: "break-word",
    },
  })
);

const AddPlaces = (props: any) => {
  console.log("AddPlaces ~ props", props);
  const { location } = props;
  const classes = useStyles();
  const [placeReviewObject, setPlaceReviewObject] = React.useState({} as any);
  const [placeId, setPlaceId] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [street, setStreet] = React.useState("");

  const [city, setCity] = React.useState("");
  const [federalState, setFederalState] = React.useState("");
  const [postCode, setPostCode] = React.useState("");
  const [county, setCounty] = React.useState("");
  const [country, setCountry] = React.useState("");

  const [status, setStatus] = React.useState(1);
  const [adminAction, setAdminAction] = React.useState(1);
  const [descriptionKey, setDescriptionKey] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [apiFetchedDescription, setApiFetchedDescription] = React.useState("");

  const [outsideOceanCityValue, setOutsideOceanCityValue] = React.useState(
    false
  );

  const [categoriesData, setCategoriesData] = React.useState([]);
  const [selectedCategories, setSelectedCategoriesData] = React.useState([]);

  const [phone, setPhone] = React.useState("");
  const [fax, setFax] = React.useState("");
  const [website, setWebsite] = React.useState("");

  const [latitude, setLatitude] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [contactPerson, setContactPerson] = React.useState("");
  const [hotline, setHotline] = React.useState("");

  const [vendorsListArray, setVendorsListArray] = React.useState([]);
  const [selectedVendors, setSelectedVendors] = React.useState([]);

  const [imageData, setImageData] = React.useState([{}]);
  const [apiFetchedImage, setApiFetchedImage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [{ title: "Places", link: routePathNames.PLACES }];
  const pendingRevisonBreadData = [
    { title: "Places", link: routePathNames.PLACES },
    { title: "Pending Revisions", link: routePathNames.PENDING_REVISION },
  ];

  const actionType = props.actionType;

  useEffect(() => {
    fetchCategories();
    fetchVendorsList();
    utils.fetchClientIpAddress();
  }, []);

  useEffect(() => {
    if (vendorsListArray && vendorsListArray.length) {
      checkUrlProps();
    }
  }, [vendorsListArray]);

  const checkUrlProps = () => {
    if (location && location.state && location.state.data) {
      let data = location.state.data;
      console.log("checkUrlProps ~ data", data);
      let id = data && data.id ? data.id : "";
      // setUpdateLoading(true);
      if (actionType === "edit" || actionType === "vendorEdit") {
        fetchSinglePlaceData(id);
      } else if (actionType === "revision") {
        console.log("datadatadatadatadata", data);
        let reviewData = _.cloneDeep(data);
        setPlaceReviewObject(reviewData);
        let body = reviewData.data;
        body.id = reviewData.place_id;
        setPlaceData(body);
      }
    }
  };

  const fetchSinglePlaceData = (id: number) => {
    setLoading(true);
    props
      .getSinglePlace(id)
      .then((result: any) => {
        console.log("fetchSinglePlaceDataresult", result);
        setLoading(false);
        let placeData = result.data.data;
        setPlaceData(placeData);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const setPlaceData = (data: any) => {
    setPlaceId(data.id);
    setTitle(data.title);
    setStreet(data.street);
    setCity(data.city);
    setFederalState(data.federal_state);
    setPostCode(data.postcode);
    setCounty(data.county);
    setCountry(data.country);
    setStatus(data.state);
    setDescription(data.description);
    setApiFetchedDescription(data.description);
    setOutsideOceanCityValue(data && data.outside_ocnj === "1" ? true : false);
    setSelectedCategoriesData(data.categoryIds);
    setPhone(data.phone);
    setFax(data.fax);
    setWebsite(data.website);
    setLatitude(data.latitude);
    setLongitude(data.longitude);
    setEmail(data.email);
    setContactPerson(data.contact_person);
    setHotline(data.hotline);
    if (data && data.vendorIds && data.vendorIds.length) {
      const apiFetchedVendorsFilter = vendorsListArray.filter((vendor: any) =>
        data.vendorIds.includes(vendor.id)
      );
      setSelectedVendors(apiFetchedVendorsFilter);
    }
    let imageUrlOnUpdate = data && data.image ? data.image : "";
    if (imageUrlOnUpdate) {
      let imageData = [
        {
          preview: imageUrlOnUpdate,
        },
      ];
      setImageData(imageData);
      setApiFetchedImage(imageUrlOnUpdate);
    }

    setTimeout(() => {
      setUpdateLoading(false);
    }, 500);
  };

  const fetchVendorsList = () => {
    setLoading(true);
    props
      .getVendorsList()
      .then((result: any) => {
        console.log("vendorData", result.data.results);
        let vendorData: any = _.cloneDeep(result.data.results);
        setVendorsListArray(vendorData);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const fetchCategories = () => {
    let isAlreadyFetchedCategory = props.categoriesSuccessFailure;
    if (isAlreadyFetchedCategory && isAlreadyFetchedCategory.isSuccess) {
      setCategoriesData(isAlreadyFetchedCategory.data.category);
    } else {
      setLoading(true);
      props
        .getCategories()
        .then((result: any) => {
          console.log("resultresult", result, props.categoriesSuccessFailure);
          setCategoriesData(result.data.category);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const syncImageData = (data: any) => {
    console.log("AddPlaces ~ data", data, imageData);
    if (data && data.length) {
      setImageData(data);
    } else {
      setImageData([{}]);
      setApiFetchedImage("");
    }
  };

  const setDescriptionTextTrumbowygText = (data: any) => {
    setDescription(data);
  };

  const selectedCatgoriesSync = (data: any) => {
    console.log("data1212", data);
    setSelectedCategoriesData(data);
  };

  const handleCheckboxChange = () => {
    let currentStatus = outsideOceanCityValue;
    setOutsideOceanCityValue(!currentStatus);
  };

  const syncSelectedVendors = (data: any) => {
    setSelectedVendors(data);
  };

  const checkEmpty = () => {
    if (
      title === "" ||
      street === "" ||
      city === "" ||
      federalState === "" ||
      postCode === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const apiCallAdminReviewedRevision = (body: adminPendingRevision) => {
    setLoading(true);
    props
      .adminUpdatePendingRevision(body)
      .then((result: any) => {
        console.log("apiCallAdminReviewedRevision", result);
        setLoading(false);
        if (result && result.isSuccess) {
          clearAllState();
          setTimeout(() => {
            props.history.goBack();
          }, 1000);
          toggleSnackBar(true, result.data.message);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const updatePlaceForReviewByVendor = (body: vendorEditPlaceForRevision) => {
    setLoading(true);
    props
      .vendorSubmitPlaceForReview(body)
      .then((result: any) => {
        console.log("updatePlaceForReviewByVendor", result);
        setLoading(false);
        if (result && result.isSuccess) {
          clearAllState();
          setTimeout(() => {
            props.history.goBack();
          }, 1000);
          toggleSnackBar(true, result.data.message);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const addPlacesApiCall = (body: createPlace) => {
    setLoading(true);
    props
      .addPlace(body)
      .then((result: any) => {
        console.log("addPlacesApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          clearAllState();
          toggleSnackBar(true, result.data.message);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const editPlacesApiCall = (body: editPlace) => {
    setLoading(true);
    props
      .updatePlace(placeId, body)
      .then((result: any) => {
        console.log("editPlacesApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          clearAllState();
          setTimeout(() => {
            props.history.goBack();
          }, 1000);
          toggleSnackBar(true, result.data.message);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const validateEmailAddress = () => {
    if (email) {
      if (utils.validateEmail(email)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const validate = () => {
    let loggedInuserDetails = utils.getLoginDetails();
    console.log("loggedInuserDetails", loggedInuserDetails);
    if (checkEmpty()) {
      if (validateEmailAddress()) {
        if (selectedCategories && selectedCategories.length) {
          let data: any = imageData[0];
          let baseData =
            data && data.base64 ? data.base64.split(",")[1] : apiFetchedImage;
          let imagePreview = data && data.preview ? data.preview : "";
          let isImageChange = apiFetchedImage === imagePreview ? false : true;
          let body: any = {
            title: title,
            street: street,
            city: city,
            federal_state: federalState,
            postcode: postCode,
            county: county,
            country: country,
            phone: phone,
            fax: fax,
            website: website,
            latitude: latitude,
            longitude: longitude,
            email: email,
            contact_person: contactPerson,
            hotline: hotline,
            description: description,
            outside_ocnj: outsideOceanCityValue ? 1 : 0,
            imageFile: baseData,
            image: baseData,
            imgName: data && data.name ? data.name : "",
            state: status,
            categoryIds: selectedCategories,
            vendorIds:
              selectedVendors && selectedVendors.length
                ? _.map(selectedVendors, "id")
                : [],
            imageFlag: isImageChange,
            userIp: utils.getClientIp(),
          };
          console.log("validate ~ body", body);
          if (actionType === "add") {
            delete body.imageFlag;
            delete body.image;
            console.log("body add", body);
            addPlacesApiCall(body);
          } else if (actionType === "edit") {
            console.log("edit else ~ body", body);
            editPlacesApiCall(body);
          } else if (actionType === "vendorEdit") {
            delete body.state;
            delete body.vendorIds;
            body["place_id"] = placeId;
            body["user_id"] =
              loggedInuserDetails && loggedInuserDetails.userId
                ? loggedInuserDetails.userId
                : "";
            body["Vendoremail"] =
              loggedInuserDetails && loggedInuserDetails.email
                ? loggedInuserDetails.email
                : "";
            let payload = {
              data: body,
            };
            console.log("vendorEdit else ~ body", payload);
            updatePlaceForReviewByVendor(payload);
          } else if (actionType === "revision") {
            delete body.state;
            delete body.vendorIds;
            body["adminAction"] = adminAction === 0 ? false : true;
            body["id"] =
              placeReviewObject && placeReviewObject.id
                ? placeReviewObject.id
                : "";
            body["place_id"] =
              placeReviewObject && placeReviewObject.place_id
                ? placeReviewObject.place_id
                : "";
            body["user_id"] =
              placeReviewObject && placeReviewObject.user_id
                ? placeReviewObject.user_id
                : "";
            body["Vendoremail"] =
              placeReviewObject &&
              placeReviewObject.data &&
              placeReviewObject.data.Vendoremail
                ? placeReviewObject.data.Vendoremail
                : "";
            console.log("revision else ~ body", body);
            apiCallAdminReviewedRevision(body);
          }
        } else {
          toggleSnackBar(true, alert.selectCategory);
        }
      } else {
        toggleSnackBar(true, alert.EMAIL_VALIDATION);
      }
    } else {
      toggleSnackBar(true, alert.allStarFieldsRequired);
    }
  };

  const clearAllState = () => {
    setDescriptionKey(!descriptionKey);
    setTitle("");
    setStreet("");
    setCity("");
    setFederalState("");
    setPostCode("");
    setCounty("");
    setCountry("");
    setStatus(1);
    setDescription("");
    setApiFetchedDescription("");
    setOutsideOceanCityValue(false);
    setSelectedCategoriesData([]);
    setPhone("");
    setFax("");
    setWebsite("");
    setLatitude("");
    setLongitude("");
    setEmail("");
    setContactPerson("");
    setHotline("");
    setSelectedVendors([]);
    setImageData([{}]);
  };

  const listCategory = useMemo(() => {
    return (
      <CategoryListComponent
        height={320}
        data={categoriesData}
        selectedData={selectedCategories}
        selectedCatgoriesCallback={selectedCatgoriesSync}
      />
    );
  }, [categoriesData, selectedCategories]);

  const vendorListSection = useMemo(() => {
    return (
      <VendorsList
        title={"Vendor Editor(s)"}
        multiple={true}
        data={vendorsListArray}
        selectedData={selectedVendors}
        selectedVendorsCallback={syncSelectedVendors}
      />
    );
  }, [vendorsListArray, selectedVendors]);

  const renderAddressBlock = () => {
    return (
      <Grid
        container
        item
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item md={4} xs={12}>
          <TextField
            required
            className={classes.textField}
            id="outlined-size-small"
            label="CITY"
            variant="outlined"
            value={city}
            onChange={(event: Event) => setCity(event.target.value)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            required
            className={classes.textField}
            id="outlined-size-small"
            label="FEDERAL STATE"
            variant="outlined"
            value={federalState}
            onChange={(event: Event) => setFederalState(event.target.value)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            required
            className={classes.textField}
            id="outlined-size-small"
            label="POSTCODE"
            variant="outlined"
            value={postCode}
            onChange={(event: Event) => setPostCode(event.target.value)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            className={classes.textField}
            id="outlined-size-small"
            label="COUNTY"
            variant="outlined"
            value={county}
            onChange={(event: Event) => setCounty(event.target.value)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            className={classes.textField}
            id="outlined-size-small"
            label="COUNTRY"
            variant="outlined"
            value={country}
            onChange={(event: Event) => setCountry(event.target.value)}
          />
        </Grid>
      </Grid>
    );
  };

  const getBreadCrumbHeading = () => {
    switch (actionType) {
      case "add":
        return "Add New Place";
        break;
      case "edit":
        return "Update Place";
        break;
      case "revision":
        return "Revision Review";
        break;
      default:
        return "Update Place";
    }
  };

  const hideMenus =
    actionType === "revision" || actionType === "vendorEdit" ? true : false;

  return (
    <React.Fragment>
      <SimpleBreadcrumbs
        breadcrumbData={
          actionType === "revision" ? pendingRevisonBreadData : breadData
        }
        heading={getBreadCrumbHeading()}
      />
      <form noValidate autoComplete="off">
        <Grid container direction="row" spacing={2}>
          <Grid container item md={8} xs={12}>
            <Grid item xs={12}>
              <TextField
                required
                className={classes.textFieldWithBottomMargin}
                id="outlined-size-small"
                label="TITLE"
                variant="outlined"
                value={title}
                onChange={(event: Event) => setTitle(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                className={classes.textFieldWithBottomMargin}
                id="outlined-size-small"
                label="STREET"
                variant="outlined"
                value={street}
                onChange={(event: Event) => setStreet(event.target.value)}
              />
            </Grid>
            {renderAddressBlock()}
            <Grid
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={2}
              style={{ marginTop: "8px", marginBottom: "8px" }}
            >
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="PHONE"
                  variant="outlined"
                  value={phone}
                  onChange={(event: Event) => setPhone(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="FAX"
                  variant="outlined"
                  value={fax}
                  onChange={(event: Event) => setFax(event.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="WEBSITE"
                  variant="outlined"
                  value={website}
                  onChange={(event: Event) => setWebsite(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="LONGITUDE"
                  variant="outlined"
                  value={longitude}
                  onChange={(event: Event) => setLongitude(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="LATITUDE"
                  variant="outlined"
                  value={latitude}
                  onChange={(event: Event) => setLatitude(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="EMAIL"
                  variant="outlined"
                  value={email}
                  onChange={(event: Event) => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="CONTACT PERSON"
                  variant="outlined"
                  value={contactPerson}
                  onChange={(event: Event) =>
                    setContactPerson(event.target.value)
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.textField}
                  id="outlined-size-small"
                  label="HOTLINE"
                  variant="outlined"
                  value={hotline}
                  onChange={(event: Event) => setHotline(event.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={classes.reportText}
              >
                DESCRIPTION
              </Typography>
              <TrumbowygTextEditor
                key={descriptionKey}
                id={"description"}
                textData={apiFetchedDescription}
                setDataCallback={setDescriptionTextTrumbowygText}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    onChange={() => handleCheckboxChange()}
                    checked={outsideOceanCityValue}
                  />
                }
                label={"Outside Ocean City?"}
              />
            </Grid>
            <Grid item xs={12}>
              <ImageDropZone
                placeHolderText={
                  "Drag 'n' drop single image file here, or click here to select the file."
                }
                height={"300px"}
                width={"100%"}
                imageData={imageData}
                syncImageDataCallback={syncImageData}
              />
            </Grid>
            {actionType === "revision" ? (
              <Grid item xs={12} style={{ paddingTop: "16px" }}>
                <Menu
                  heading={"ADMIN ACTION"}
                  selectedValue={adminAction}
                  data={adminPublishPlaceActions}
                  setValueCallback={(data: any) => setAdminAction(data)}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid container item md={4} xs={12}>
            <Grid item xs={12}>
              {hideMenus ? null : (
                <Menu
                  heading={"STATUS"}
                  selectedValue={status}
                  data={publishUnpublish}
                  setValueCallback={(data: any) => setStatus(data)}
                />
              )}
              {hideMenus ? (
                <div style={{ marginTop: "-15px" }}>{listCategory}</div>
              ) : (
                listCategory
              )}
              {hideMenus ? null : vendorListSection}
            </Grid>
            {/* <Grid item>{listCategory}</Grid> */}
          </Grid>
          <Grid container xs={12} justify="center" className={classes.btnItem}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnStyle}
              size="large"
              onClick={validate}
            >
              <Typography className={classes.buttonTextStyle}>
                {"Save"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
      <Loading isFetching={loading || updateLoading ? true : false} />
    </React.Fragment>
  );
};

const mapState = (state: any) => {
  return {
    categoriesSuccessFailure: state.advertisement.categoriesSuccessFailure,
    vendorsListSuccessFailure: state.getPlaces.vendorsListSuccessFailure,
  };
};

const mapDispatch = {
  getCategories: () => getCategories(),
  addPlace: (body: createPlace) => addPlace(body),
  getVendorsList: () => getVendorsList(),
  getSinglePlace: (id: number) => getSinglePlace(id),
  updatePlace: (id: number, body: editPlace) => updatePlace(id, body),
  vendorSubmitPlaceForReview: (body: vendorEditPlaceForRevision) =>
    vendorSubmitPlaceForReview(body),
  adminUpdatePendingRevision: (body: adminPendingRevision) =>
    adminUpdatePendingRevision(body),
  getClientIpAddress: () => getClientIpAddress(),
};

const connector = connect(mapState, mapDispatch);
export default connector(React.memo(AddPlaces));
