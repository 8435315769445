import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  PLACES_REVISIONS_SUCCESS_FAILURE,
  VENDOR_ADD_PLACE_REVIEW_SUCCESS_FAILURE,
  vendorEditPlaceForRevision,
  ADMIN_PENDING_REVIEW_SUCCESS_FAILURE,
  adminPendingRevision,
  DELETE_REVISION_ADMIN_SUCCESS_FAILURE,
} from "../../types/revisions";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const placesRevisionSuccessFailure = (data: any) => {
  return {
    type: PLACES_REVISIONS_SUCCESS_FAILURE,
    data,
  };
};

const vendorAddPlaceForReviewSuccessFailure = (data: any) => {
  return {
    type: VENDOR_ADD_PLACE_REVIEW_SUCCESS_FAILURE,
    data,
  };
};

const adminPendingReviewSuccessFailure = (data: any) => {
  return {
    type: ADMIN_PENDING_REVIEW_SUCCESS_FAILURE,
    data,
  };
};

const deleteRevisionByAdminSuccessFailure = (data: any) => {
  return {
    type: DELETE_REVISION_ADMIN_SUCCESS_FAILURE,
    data,
  };
};

export const getPendingRevisionsList = () => {
  console.log("getPendingRevisionsList");
  return (dispatch: Dispatch) => {
    const url = "places/pendingRevisions";
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(placesRevisionSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(placesRevisionSuccessFailure(result));
        return result;
      });
  };
};

export const vendorSubmitPlaceForReview = (
  body: vendorEditPlaceForRevision
) => {
  console.log("getPendingRevisionsList", body);
  return (dispatch: Dispatch) => {
    const url = "places/vendor/pendingRevisions";
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(vendorAddPlaceForReviewSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(vendorAddPlaceForReviewSuccessFailure(result));
        return result;
      });
  };
};

export const adminUpdatePendingRevision = (body: adminPendingRevision) => {
  console.log("getPendingRevisionsList", body);
  return (dispatch: Dispatch) => {
    const url = "places/vendor/reviewed";
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(adminPendingReviewSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(adminPendingReviewSuccessFailure(result));
        return result;
      });
  };
};

export const deleteRevisionByAdmin = (id: number) => {
  console.log("deleteRevisionByAdmin", id);
  return (dispatch: Dispatch) => {
    const url = "places/vendor/reviewed/" + id;
    return AxiosInstance.delete(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(deleteRevisionByAdminSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(deleteRevisionByAdminSuccessFailure(result));
        return result;
      });
  };
};
