import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  NOTIFICATIONS_SUCCESS_FAILURE,
  CATEGORIES_SUCCESS_FAILURE,
  MOBILE_USERS_COUNT_SUCCESS_FAILURE,
  SEND_NOTIFICATION_SUCCESS_FAILURE,
} from "../../types/notifications";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

export const notificationsSuccessFailure = (data: any) => {
  return {
    type: NOTIFICATIONS_SUCCESS_FAILURE,
    data,
  };
};

export const mobileUserCountSuccessFailure = (data: any) => {
  return {
    type: MOBILE_USERS_COUNT_SUCCESS_FAILURE,
    data,
  };
};

export const notificationsCategoriesSuccessFailure = (data: any) => {
  return {
    type: CATEGORIES_SUCCESS_FAILURE,
    data,
  };
};

export const sendNotificationSuccessFailure = (data: any) => {
  return {
    type: SEND_NOTIFICATION_SUCCESS_FAILURE,
    data,
  };
};

export const getNotifications = (pageNo: number) => {
  return (dispatch: Dispatch) => {
    const url = "notifications/" + pageNo;
    return AxiosInstance.get(url)
      .then((response: any) => {
        console.log("response ===>", response);
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(notificationsSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(notificationsSuccessFailure(result));
        return result;
      });
  };
};

export const getNotificationsCategories = () => {
  return (dispatch: Dispatch) => {
    const url = "notifications/getCategories";
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(notificationsCategoriesSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        console.log("err==>", err);
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(notificationsCategoriesSuccessFailure(result));
        return result;
      });
  };
};

export const getMobileUsersCount = () => {
  console.log("coming inside user count");
  return (dispatch: Dispatch) => {
    const url = "notifications/getUsersCount";
    return AxiosInstance.get(url)
      .then((response: any) => {
        console.log("response ===>", response);
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(mobileUserCountSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(mobileUserCountSuccessFailure(result));
        return result;
      });
  };
};

export const sendNotification = (body: any) => {
  return (dispatch: Dispatch) => {
    const url = "notifications/addNew";
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        console.log("response ===>", response);
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(sendNotificationSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(sendNotificationSuccessFailure(result));
        return result;
      });
  };
};
