import React, { useCallback, useEffect, useState } from "react";
// @ts-ignore
import Trumbowyg from "react-trumbowyg";
import "react-trumbowyg/dist/trumbowyg.min.css";

const TrumbowygTextEditor = (props: any) => {
  return (
    <Trumbowyg
      key={props && props.key ? props.key : props.id}
      id={props.id}
      data={props && props.textData ? props.textData : ""}
      onChange={(event: any) => props.setDataCallback(event.target.innerHTML)}
      // onChange={(event: any) =>
      //   console.log("eventevent", event.target.innerHTML)
      // }
    />
  );
};

export default React.memo(TrumbowygTextEditor);
