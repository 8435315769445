import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Colors } from "../../utils/colors";
import Loading from "../../commonComponents/loading";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import { alert, rolesArray, routePathNames } from "../../utils/constants";
import PaginationComponent from "../../commonComponents/pagination";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import {
  getPlaces,
  changePlaceStatus,
  deletePlace,
} from "./redux/actions/getPlaces";
import { connect, useSelector } from "react-redux";
import ShowSnackBar from "../../commonComponents/snackBar";
import logoImage from "../../assets/ocnj-logo.png";
import Toggle from "../../commonComponents/publishToggle";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { PLACES_ITEM, PlacesTableColumn } from "./types/places";
import TextField from "@material-ui/core/TextField";
import { Event } from "../../commonTypes/types";
import EmptyComponent from "../../commonComponents/emptyComponent";
import * as utils from "../../utils/index";
import ManageButton from "../../commonComponents/manageButtons";

// @ts-ignore
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

const columns: PlacesTableColumn[] = [
  { label: "Place	", minWidth: 120 },
  { label: "Location", minWidth: 100 },
  { label: "Phone", minWidth: 20 },
  {
    label: "Category(ies)",
    minWidth: 100,
  },
  { label: "Status", minWidth: 20 },
  {
    label: "Manage",
    minWidth: 100,
  },
];

const vendorTableColumns: PlacesTableColumn[] = [
  { label: "Place	", minWidth: 120 },
  { label: "Location", minWidth: 100 },
  { label: "Phone", minWidth: 20 },
  {
    label: "Category(ies)",
    minWidth: 100,
  },
  {
    label: "Manage",
    minWidth: 100,
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "58vh",
  },
  ellipsisModeStyle: {
    whiteSpace: "nowrap",
    maxWidth: 240,
    overflow: "hidden",
    textOverflow: "ellipsis",
    OTextOverflow: "ellipsis",
    display: "table-cell",
  },
  imageStyle: {
    display: "block",
    width: "150px",
    height: "150px",
    backgroundColor: "#000",
    cursor: "pointer",
  },
  longWordBreak: {
    wordWrap: "break-word",
    maxWidth: 240,
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: 400,
  },
  textField: {
    fontWeight: 500,
    backgroundColor: "#fff",
    width: "100%",
  },
  textFieldWithBottomMargin: {
    fontWeight: 500,
    backgroundColor: "#fff",
    width: "100%",
    marginBottom: "16px",
  },
  btnStyle: {
    width: "100%",
    fontWeight: "bold",
    marginBottom: "16px",
  },
  buttonTextStyle: {
    color: "#fff",
    fontWeight: "bold",
  },
  alignInOne: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: Colors.brightBlue,
      color: Colors.white,
      fontWeight: "bold",
    },
    body: {
      fontSize: 18,
    },
  })
)(TableCell);

const PlacesList = (props: any) => {
  const classes = useStyles();
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchInputText, setSearchInputText] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [{ title: "Places", link: routePathNames.PLACES }];
  const tableColumns =
    utils.getRole() === "Administrator" ? columns : vendorTableColumns;

  const fetchPlacesList = () => {
    let body = {
      searchText: searchKeyword,
    };
    setLoading(true);
    props
      .getPlaces(currentPage, body)
      .then((result: any) => {
        console.log("resultresult", result);
        setData(result.data.places);
        setTotalPage(result.data.pageCount);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        setData([]);
      });
  };

  const pageChangeHandlerCallback = (newPage: number) => {
    console.log("pageChangeHandlerCallback ~ newPage", newPage);
    setCurrentPage(newPage);
  };

  //   useEffect(() => {
  //     console.log("useEffect1-", currentPage, searchKeyword);
  //     fetchPlacesList();
  //   }, [currentPage]);

  useEffect(() => {
    console.log("useEffectuseEffect", currentPage, searchKeyword);
    fetchPlacesList();
    utils.fetchClientIpAddress();
  }, [currentPage, searchKeyword]);

  const deleteUser = (item: PLACES_ITEM) => {
    let title = "Delete";
    let message = "Do you want to delete this place?";
    alertify.confirm(
      title,
      message,
      function () {
        deleteUserApiCall(item);
      },
      function () {
        // alertify.error('Cancel');
      }
    );
  };

  const editUser = (item: PLACES_ITEM) => {
    console.log("itemitemitemitem", item);
    if (utils.getRole() === "Administrator") {
      props.history.push({
        pathname: routePathNames.EDIT_PLACES,
        state: {
          data: item,
        },
      });
    } else {
      props.history.push({
        pathname: routePathNames.VENDOR_EDIT_PLACES,
        state: {
          data: item,
        },
      });
    }
  };

  const deleteUserApiCall = (item: PLACES_ITEM) => {
    setLoading(true);
    props
      .deletePlace(item.id)
      .then((result: any) => {
        console.log("deleteUserApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          if (data && data.length === 1) {
            console.log("data", data);
            setCurrentPage(currentPage - 1);
          } else {
            fetchPlacesList();
          }
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const changePublishStatus = (itemId: number, status: number) => {
    console.log("change1PublishStatus ~ itemId", itemId, status);
    let publishStatus = Boolean(Number(status));
    const body = {
      state: !publishStatus,
      userIp: utils.getClientIp(),
    };
    setLoading(true);
    props
      .changePlaceStatus(itemId, body)
      .then((result: any) => {
        console.log("change1PublishStatusresultresult", result);
        setLoading(false);
        if (result && result.isSuccess) {
          const index = data.findIndex(
            (item: PLACES_ITEM) => item.id === itemId
          );
          let addObj: PLACES_ITEM;
          let addsList = _.cloneDeep(data);
          addObj = addsList[index];
          addObj["state"] ^= 1;
          setData(addsList);
          toggleSnackBar(true, result.data.message);
          console.log("data", data, addsList, addObj);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const inputChangeHandler = (text: any) => {
    if (text) {
      setSearchInputText(text);
    } else {
      setSearchInputText(text);
      setSearchKeyword(text);
    }
  };

  const searchWithKeyword = () => {
    if (searchInputText) {
      setCurrentPage(1);
      setSearchKeyword(searchInputText);
    } else {
      toggleSnackBar(true, alert.searchAlert);
    }
  };

  return (
    <React.Fragment>
      <SimpleBreadcrumbs breadcrumbData={breadData} heading={"Places List"} />
      <React.Fragment>
        <Grid
          container
          // md={12}
          // xs={12}
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={10}>
            <TextField
              className={classes.textFieldWithBottomMargin}
              id="outlined-size-small"
              label="Search for a Location"
              variant="outlined"
              size="small"
              value={searchInputText}
              onChange={(event: Event) =>
                inputChangeHandler(event.target.value)
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnStyle}
              size="large"
              onClick={searchWithKeyword}
            >
              <Typography className={classes.buttonTextStyle}>
                {"Search"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
        {data && data.length ? (
          <>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {tableColumns.map((column) => (
                        <StyledTableCell
                          align={column.align}
                          style={{ width: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item: PLACES_ITEM) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={item.id}
                        >
                          <TableCell>{item.title}</TableCell>
                          <TableCell>
                            <Typography className={classes.longWordBreak}>
                              {item.location ? item.location : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {item.phone ? item.phone : "-"}
                          </TableCell>
                          <TableCell>
                            {item &&
                            item.categoryName &&
                            item.categoryName.length
                              ? item.categoryName.join(", ")
                              : ""}
                          </TableCell>
                          {utils.getRole() === "Administrator" ? (
                            <TableCell align={"center"}>
                              <Toggle
                                statusType={item.state}
                                itemId={item.id}
                                changePublishCallback={changePublishStatus}
                              />
                            </TableCell>
                          ) : null}
                          <TableCell>
                            <div className={classes.alignInOne}>
                              <span
                                className="icons-spacing cursor-pointer"
                                onClick={() => editUser(item)}
                              >
                                <ManageButton
                                  title={"Edit Place"}
                                  buttonType={"edit"}
                                />
                              </span>
                              {utils.getRole() === "Administrator" ? (
                                <span
                                  className="cursor-pointer"
                                  onClick={() => deleteUser(item)}
                                >
                                  <ManageButton
                                    title={"Delete Place"}
                                    buttonType={"delete"}
                                  />
                                </span>
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Grid container justify="center">
              <PaginationComponent
                currentPage={currentPage}
                totalPage={totalPage}
                setPageCallback={pageChangeHandlerCallback}
              />
            </Grid>
          </>
        ) : !loading ? (
          <EmptyComponent />
        ) : null}
      </React.Fragment>
      <Loading isFetching={loading} />
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    placesSuccessFailure: state.getPlaces.placesSuccessFailure,
  };
};

const mapDispatch = {
  getPlaces: (pageNo: any, body: any) => getPlaces(pageNo, body),
  changePlaceStatus: (id: number, body: any) => changePlaceStatus(id, body),
  deletePlace: (id: number) => deletePlace(id),
};

const connector = connect(mapStateToProps, mapDispatch);
export default connector(PlacesList);
