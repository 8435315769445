import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Colors } from "../../utils/colors";
import Loading from "../../commonComponents/loading";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import { rolesArray, routePathNames } from "../../utils/constants";
import PaginationComponent from "../../commonComponents/pagination";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import { AdsTableColumn, AdsTableData, instaItems } from "./types/insta";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import {
  getInstaImgs,
  changeInstaImgPublishStatus,
  deleteInstaImg,
} from "./redux/actions/insta";
import { connect, useSelector } from "react-redux";
import ShowSnackBar from "../../commonComponents/snackBar";
import logoImage from "../../assets/ocnj-logo.png";
import Toggle from "../../commonComponents/publishToggle";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import EmptyComponent from "../../commonComponents/emptyComponent";
import ManageButton from "../../commonComponents/manageButtons";

// @ts-ignore
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

const PAGE_LIMIT = 10;

const columns: AdsTableColumn[] = [
  { label: "#", minWidth: 100 },
  { label: "Image", minWidth: 200 },
  {
    label: "Selected Status",
    minWidth: 200,
  },
  {
    label: "Action",
    minWidth: 50,
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 420,
  },
  ellipsisModeStyle: {
    whiteSpace: "nowrap",
    maxWidth: 240,
    overflow: "hidden",
    textOverflow: "ellipsis",
    OTextOverflow: "ellipsis",
    display: "table-cell",
  },
  imageStyle: {
    display: "block",
    width: "150px",
    height: "150px",
    backgroundColor: "#000",
    cursor: "pointer",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: Colors.brightBlue,
      color: Colors.white,
      fontWeight: "bold",
    },
    body: {
      fontSize: 18,
    },
  })
)(TableCell);

const InstagramImagesList = (props: any) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [
    { title: "Instagram Images", link: routePathNames.INSTAGRAM },
  ];

  const fetchInstaImgs = () => {
    setLoading(true);
    props
      .getInstaImgs(currentPage)
      .then((result: any) => {
        console.log("resultresult", result);
        setData(result.data.result);
        setTotalPage(result.data.totalPage);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((err: any) => {
        setLoading(false);
        setData([]);
      });
  };

  const pageChangeHandlerCallback = (newPage: number) => {
    console.log("pageChangeHandlerCallback ~ newPage", newPage);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchInstaImgs();
    // fetchAdvertisements();
  }, [currentPage]);

  const deleteInstaImg = (imgId: number) => {
    let title = "Delete";
    let message = "Do you want to delete this image?";
    alertify.confirm(
      title,
      message,
      function () {
        deleteInstaImgApiCall(imgId);
      },
      function () {
        // alertify.error('Cancel');
      }
    );
  };

  const deleteInstaImgApiCall = (imgId: number) => {
    setLoading(true);
    props
      .deleteInstaImg(imgId)
      .then((result: any) => {
        console.log("deleteUserApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          if (data && data.length === 1) {
            console.log("data", data);
            setCurrentPage(currentPage - 1);
          } else {
            fetchInstaImgs();
          }
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const changePublishStatus = (itemId: number, status: number) => {
    let imgId = status ? null : itemId;
    setLoading(true);
    props
      .changeInstaImgPublishStatus(imgId)
      .then((result: any) => {
        setLoading(false);
        if (result && result.isSuccess) {
          fetchInstaImgs();
          toggleSnackBar(true, result.data.message);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const openImageInNewTab = (url: any) => {
    window.open(url);
  };

  return (
    <React.Fragment>
      <SimpleBreadcrumbs breadcrumbData={breadData} heading={"Images"} />
      {data && data.length ? (
        <React.Fragment>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item: instaItems, index: number) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item.id}
                      >
                        <TableCell>
                          {(currentPage - 1) * PAGE_LIMIT + index + 1}
                        </TableCell>
                        <TableCell>
                          <img
                            src={item.image_url ? item.image_url : logoImage}
                            className={classes.imageStyle}
                            onClick={() => openImageInNewTab(item.image_url)}
                          />
                        </TableCell>
                        <TableCell>
                          <Toggle
                            statusType={item.is_published}
                            itemId={item.id}
                            changePublishCallback={() =>
                              changePublishStatus(item.id, item.is_published)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <span
                            className="cursor-pointer"
                            onClick={() => deleteInstaImg(item.id)}
                          >
                            <ManageButton
                              title={"Delete Image"}
                              buttonType={"delete"}
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Grid container justify="center">
            <PaginationComponent
              currentPage={currentPage}
              totalPage={totalPage}
              setPageCallback={pageChangeHandlerCallback}
            />
          </Grid>
        </React.Fragment>
      ) : !loading ? (
        <EmptyComponent />
      ) : null}
      <Loading isFetching={loading} />
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    usersSuccessFailure: state.instaImgs.instaImgsSuccessFailure,
  };
};

const mapDispatch = {
  getInstaImgs: (pageNo: any) => getInstaImgs(pageNo),
  changeInstaImgPublishStatus: (imgId: number) =>
    changeInstaImgPublishStatus(imgId),
  deleteInstaImg: (imgId: number) => deleteInstaImg(imgId),
};

const connector = connect(mapStateToProps, mapDispatch);
export default connector(InstagramImagesList);
