import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Colors } from "../../utils/colors";
import Loading from "../../commonComponents/loading";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import { rolesArray, routePathNames } from "../../utils/constants";
import PaginationComponent from "../../commonComponents/pagination";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import { AdsTableColumn, AdsTableData, storiesReport } from "./types/Stories";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import {
  getStoriesReports,
  approveDeclineReport,
} from "./redux/actions/stories";
import { connect, useSelector } from "react-redux";
import ShowSnackBar from "../../commonComponents/snackBar";
import logoImage from "../../assets/ocnj-logo.png";
import Toggle from "../../commonComponents/publishToggle";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import EmptyComponent from "../../commonComponents/emptyComponent";

// @ts-ignore
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

const columns: AdsTableColumn[] = [
  {
    label: "Title",
    minWidth: 50,
  },
  { label: "Report Person Name", minWidth: 200 },
  {
    label: "Reason",
    minWidth: 200,
  },
  {
    label: "Description",
    minWidth: 200,
  },
  {
    label: "Image",
    minWidth: 200,
  },
  {
    label: "Action",
    minWidth: 50,
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 420,
  },
  ellipsisModeStyle: {
    whiteSpace: "nowrap",
    maxWidth: 240,
    overflow: "hidden",
    textOverflow: "ellipsis",
    OTextOverflow: "ellipsis",
    display: "table-cell",
  },
  imageStyle: {
    display: "block",
    width: "150px",
    height: "150px",
    backgroundColor: "#000",
    cursor: "pointer",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: Colors.brightBlue,
      color: Colors.white,
      fontWeight: "bold",
    },
    body: {
      fontSize: 18,
    },
  })
)(TableCell);

const ReportedStories = (props: any) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [
    { title: "Stories", link: routePathNames.REPORTED_STORIES },
  ];

  const fetchReportStories = () => {
    setLoading(true);
    props
      .getStoriesReports(currentPage)
      .then((result: any) => {
        console.log("resultresult", result);
        setData(result.data.data);
        setTotalPage(result.data.total);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        setData([]);
      });
  };

  const pageChangeHandlerCallback = (newPage: number) => {
    console.log("pageChangeHandlerCallback ~ newPage", newPage);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchReportStories();
    // fetchAdvertisements();
  }, [currentPage]);

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const approveDeclineReport = (reportId: number, status: number) => {
    setLoading(true);
    props
      .approveDeclineReport(reportId, status)
      .then((result: any) => {
        setLoading(false);
        if (result && result.isSuccess) {
          fetchReportStories();
          toggleSnackBar(true, result.data.message);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const openImageInNewTab = (url: any) => {
    window.open(url);
  };

  return (
    <React.Fragment>
      <SimpleBreadcrumbs
        breadcrumbData={breadData}
        heading={"Reported Stories"}
      />
      {data && data.length ? (
        <React.Fragment>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item: storiesReport) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item.reportStoryId}
                      >
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.report_person_name}</TableCell>
                        <TableCell>{item.reason}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>
                          <img
                            src={item.img_url ? item.img_url : logoImage}
                            className={classes.imageStyle}
                            onClick={() => openImageInNewTab(item.img_url)}
                          />
                        </TableCell>
                        <TableCell align={"center"}>
                          <Toggle
                            statusType={item.is_enable}
                            itemId={item.reportStoryId}
                            changePublishCallback={() =>
                              approveDeclineReport(
                                item.story_id,
                                item.is_enable
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Grid container justify="center">
            <PaginationComponent
              currentPage={currentPage}
              totalPage={totalPage}
              setPageCallback={pageChangeHandlerCallback}
            />
          </Grid>
        </React.Fragment>
      ) : !loading ? (
        <EmptyComponent />
      ) : null}
      <Loading isFetching={loading} />
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    storiesReportSuccessFailure:
      state.storiesReports.storiesReportSuccessFailure,
  };
};

const mapDispatch = {
  getStoriesReports: (pageNo: any) => getStoriesReports(pageNo),
  approveDeclineReport: (reportId: number, status: number) =>
    approveDeclineReport(reportId, status),
};

const connector = connect(mapStateToProps, mapDispatch);
export default connector(ReportedStories);
