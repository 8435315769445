import React, { useCallback, useEffect, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import "./css/users.css";
import { Event } from "../../commonTypes/types";
import { Roles, AddNewUser, EditUser } from "./types/users";
import { rolesArray, routePathNames, alert } from "../../utils/constants";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import _ from "lodash";
import ShowSnackBar from "../../commonComponents/snackBar";
import Typography from "@material-ui/core/Typography";
import { addUser, editUser } from "./redux/actions/getUsers";
import Loading from "../../commonComponents/loading";
import * as utils from "../../utils/index";

//password eye
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    textField: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
      marginBottom: "15px",
    },
    btnStyle: {
      width: "80%",
      marginTop: "15px",
      fontWeight: "bold",
    },
    buttonTextStyle: {
      color: "#fff",
      fontWeight: "bold",
    },
  })
);

const CreateUser = (props: any) => {
  const { location } = props;
  const classes = useStyles();
  const [userId, setUserId] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [roles, setRoles] = React.useState([{}]);
  const [loading, setLoading] = React.useState(false);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const breadData = [{ title: "Users", link: routePathNames.USERS }];
  const actionType = props.actionType;

  useEffect(() => {
    checkUrlProps();
  }, []);

  const checkUrlProps = () => {
    const currentUrl = props.location;
    console.log("checkUrlProps ~ data111", props, currentUrl);
    if (location && location.state && location.state.data) {
      let data = location.state.data;
      console.log("checkUrlProps ~ data", data);
      setName(data.name);
      setEmail(data.email);
      let makeRoles = data.role.map((item: any) => {
        return { name: item, id: item };
      });
      setRoles(makeRoles);
      setUserId(data.id);
    }
  };

  const registerNewUser = (body: AddNewUser) => {
    setLoading(true);
    props
      .addUser(body)
      .then((result: any) => {
        console.log("registerNewUser", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          clearData();
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const editUserCall = (body: EditUser) => {
    setLoading(true);
    props
      .editUser(body, userId)
      .then((result: any) => {
        console.log("editUserCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          setTimeout(() => {
            clearData();
            props.history.goBack();
          }, 1000);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const clearData = () => {
    setName("");
    setEmail("");
    setPassword("");
    setRoles([{}]);
  };

  const handleChange = (props: Roles) => {
    let array = _.cloneDeep(roles);
    let index = _.findIndex(array, {
      name: props.name,
      id: props.id,
    });
    if (index !== -1) {
      array.splice(index, 1);
      console.log("handleChange ~ index if", array);
    } else {
      if (_.isEmpty(roles[0])) {
        array = [{ name: props.name, id: props.id }];
      } else {
        array.push({ name: props.name, id: props.id });
      }
    }
    setRoles(array);
  };

  const checkEmpty = () => {
    if (name === "" || email === "") {
      return false;
    } else {
      return true;
    }
  };

  const validateAndGeneratePayoad = () => {
    let body = {
      name: name,
      email: email,
      password: password,
      role: _.map(roles, "name"),
    };

    if (checkEmpty()) {
      if (utils.validateEmail(email)) {
        if (roles && roles.length && !_.isEmpty(roles[0])) {
          if (actionType === "add") {
            if (password !== "") {
              registerNewUser(body);
            } else {
              toggleSnackBar(true, alert.mandatoryFields);
            }
          } else {
            editUserCall(body);
          }
        } else {
          toggleSnackBar(true, alert.ROLES_VALIDATION);
        }
      } else {
        toggleSnackBar(true, alert.EMAIL_VALIDATION);
      }
    } else {
      toggleSnackBar(true, alert.mandatoryFields);
    }
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <SimpleBreadcrumbs
        breadcrumbData={breadData}
        heading={actionType === "add" ? "Create New User" : "Update User"}
      />
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container justify="center">
          <Grid item xs={8}>
            <TextField
              className={classes.textField}
              id="outlined-size-small"
              label="Name"
              variant="outlined"
              value={name}
              onChange={(event: Event) => setName(event.target.value)}
            />
            <TextField
              className={classes.textField}
              id="outlined-size-small"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(event: Event) => setEmail(event.target.value)}
            />
            <TextField
              {...props}
              className={classes.textField}
              id="outlined-size-s mall"
              label="Password"
              variant="outlined"
              value={password}
              onChange={(event: Event) => setPassword(event.target.value)}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={8} justify="flex-start">
            <span className="roles-title">Roles</span>
            {rolesArray.map((item) => {
              return (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => handleChange(item)}
                        name="checkedB"
                        color="primary"
                        checked={
                          _.findIndex(roles, { name: item.name }) === -1
                            ? false
                            : true
                        }
                      />
                    }
                    label={item.name}
                  />
                </div>
              );
            })}
          </Grid>
          <Grid container xs={8} justify="center">
            <Button
              variant="contained"
              color="primary"
              className={classes.btnStyle}
              size="large"
              onClick={validateAndGeneratePayoad}
            >
              <Typography className={classes.buttonTextStyle}>
                {actionType === "add" ? "Create New User" : "Update User"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
      <Loading isFetching={loading} />
    </>
  );
};

const mapState = (state: any) => {
  return {
    addUserSuccessFailure: state.getUsers.addUserSuccessFailure,
    editUserSuccessFailure: state.getUsers.editUserSuccessFailure,
  };
};

const mapDispatch = {
  addUser: (body: AddNewUser) => addUser(body),
  editUser: (body: EditUser, userId: any) => editUser(body, userId),
};

const connector = connect(mapState, mapDispatch);
export default connector(CreateUser);
