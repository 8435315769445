import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Colors } from "../../utils/colors";
import Loading from "../../commonComponents/loading";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import { alert, rolesArray, routePathNames } from "../../utils/constants";
import PaginationComponent from "../../commonComponents/pagination";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import {
  getPlaces,
  changePlaceStatus,
  deletePlace,
} from "./redux/actions/getPlaces";
import { connect, useSelector } from "react-redux";
import ShowSnackBar from "../../commonComponents/snackBar";
import logoImage from "../../assets/ocnj-logo.png";
import Toggle from "../../commonComponents/publishToggle";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { PENDING_REVISION_ITEM, PlacesTableColumn } from "./types/places";
import TextField from "@material-ui/core/TextField";
import { Event } from "../../commonTypes/types";
import EmptyComponent from "../../commonComponents/emptyComponent";
import {
  getPendingRevisionsList,
  deleteRevisionByAdmin,
} from "./redux/actions/revisions";
import * as utils from "../../utils/index";
import ManageButton from "../../commonComponents/manageButtons";

// @ts-ignore
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

const columns: PlacesTableColumn[] = [
  { label: "Place	", minWidth: 170 },
  { label: "Revised On", minWidth: 170 },
  { label: "Revised By", minWidth: 180 },
  { label: "Status", minWidth: 150 },
  {
    label: "Manage",
    minWidth: 20,
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "70vh",
  },
  ellipsisModeStyle: {
    whiteSpace: "nowrap",
    maxWidth: 240,
    overflow: "hidden",
    textOverflow: "ellipsis",
    OTextOverflow: "ellipsis",
    display: "table-cell",
  },
  imageStyle: {
    display: "block",
    width: "150px",
    height: "150px",
    backgroundColor: "#000",
    cursor: "pointer",
  },
  longWordBreak: {
    wordWrap: "break-word",
    maxWidth: 240,
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: 400,
  },
  textField: {
    fontWeight: 500,
    backgroundColor: "#fff",
    width: "100%",
  },
  textFieldWithBottomMargin: {
    fontWeight: 500,
    backgroundColor: "#fff",
    width: "100%",
    marginBottom: "16px",
  },
  btnStyle: {
    width: "100%",
    fontWeight: "bold",
    marginBottom: "16px",
  },
  buttonTextStyle: {
    color: "#fff",
    fontWeight: "bold",
  },
  alignInOne: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: Colors.brightBlue,
      color: Colors.white,
      fontWeight: "bold",
    },
    body: {
      fontSize: 18,
    },
  })
)(TableCell);

const PendingRevision = (props: any) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [{ title: "Places", link: routePathNames.PLACES }];

  const fetchPendingRevisionsList = () => {
    setLoading(true);
    props
      .getPendingRevisionsList()
      .then((result: any) => {
        console.log("resultresult", result);
        setData(result.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        setData([]);
      });
  };

  useEffect(() => {
    fetchPendingRevisionsList();
  }, []);

  const deleteAlert = (item: any) => {
    let title = "Delete";
    let message = "Do you want to delete this pending revision?";
    alertify.confirm(
      title,
      message,
      function () {
        deletePendingRevisionApiCall(item);
      },
      function () {
        // alertify.error('Cancel');
      }
    );
  };

  const editUser = (item: any) => {
    console.log("itemitemitemitem", item);
    props.history.push({
      pathname: routePathNames.ADMIN_EDIT_REVISION,
      state: {
        data: item,
      },
    });
  };

  const deletePendingRevisionApiCall = (item: any) => {
    console.log("deletePendingRevisionApiCall item", item);
    setLoading(true);
    props
      .deleteRevisionByAdmin(item.id)
      .then((result: any) => {
        console.log("deletePendingRevisionApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          fetchPendingRevisionsList();
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const changePublishStatus = (itemId: number, status: number) => {
    console.log("change1PublishStatus ~ itemId", itemId, status);
  };

  return (
    <React.Fragment>
      <SimpleBreadcrumbs
        breadcrumbData={breadData}
        heading={"Pending Revisions List"}
      />
      <React.Fragment>
        {data && data.length ? (
          <>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          align={column.align}
                          style={{ width: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item: any) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={item.id}
                        >
                          <TableCell>
                            <Typography className={classes.longWordBreak}>
                              {item && item.data && item.data.title
                                ? item.data.title
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.longWordBreak}>
                              {item && item.created_at
                                ? utils.convertDateInRevision(item.created_at)
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.longWordBreak}>
                              {item && item.data && item.data.Vendoremail
                                ? item.data.Vendoremail
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {utils.getReviewStatus(item.status)}
                          </TableCell>
                          <TableCell>
                            <div className={classes.alignInOne}>
                              <span
                                className="icons-spacing cursor-pointer"
                                onClick={() => editUser(item)}
                              >
                                <ManageButton
                                  title={"Review Revision"}
                                  buttonType={"edit"}
                                />
                              </span>
                              <span
                                className="cursor-pointer"
                                onClick={() => deleteAlert(item)}
                              >
                                <ManageButton
                                  title={"Delete Revision"}
                                  buttonType={"delete"}
                                />
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        ) : !loading ? (
          <EmptyComponent />
        ) : null}
      </React.Fragment>
      <Loading isFetching={loading} />
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    placesSuccessFailure: state.getPlaces.placesSuccessFailure,
  };
};

const mapDispatch = {
  getPendingRevisionsList: () => getPendingRevisionsList(),
  deleteRevisionByAdmin: (id: number) => deleteRevisionByAdmin(id),
};

const connector = connect(mapStateToProps, mapDispatch);
export default connector(PendingRevision);
