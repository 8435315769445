/* eslint-disable no-use-before-define */
import React, { memo, useEffect, useState, useMemo } from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "16px",
      backgroundColor: "#fff",
    },
  })
);

const VenueList = (props: any) => {
  const classes = useStyles();

  const onTagsChange = (event: any, values: any) => {
    console.log("event values", values);
    props.selectedVenueCallback(values);
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        onChange={onTagsChange}
        color="primary"
        id="tags-outlined"
        options={props.data}
        getOptionLabel={(option) => option.venue}
        value={props.selectedData}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={props.title} />
        )}
      />
    </div>
  );
};

export default React.memo(VenueList);
