import {
  RESET_PASSWORD_SUCCESS_FAILURE,
  InitialState,
  Action,
} from "../../types/resetPassword";

const initialState: InitialState = {
  resetPasswordSuccessFailure: [],
};
const resetPassword = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case RESET_PASSWORD_SUCCESS_FAILURE:
      return {
        ...state,
        resetPasswordSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default resetPassword;
