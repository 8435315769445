import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Colors } from "../../utils/colors";
import Loading from "../../commonComponents/loading";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import { alert, rolesArray, routePathNames } from "../../utils/constants";
import PaginationComponent from "../../commonComponents/pagination";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import {
  getEvents,
  changeEventStatus,
  deleteEvent,
} from "./redux/actions/getEvents";
import { connect, useSelector } from "react-redux";
import ShowSnackBar from "../../commonComponents/snackBar";
import logoImage from "../../assets/ocnj-logo.png";
import Toggle from "../../commonComponents/publishToggle";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { EVENT_ITEM, EventTableColumn } from "./types/events";
import TextField from "@material-ui/core/TextField";
import { Event } from "../../commonTypes/types";
import EmptyComponent from "../../commonComponents/emptyComponent";
import * as utils from "../../utils/index";
import ManageButton from "../../commonComponents/manageButtons";

// @ts-ignore
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

const columns: EventTableColumn[] = [
  { label: "Event	", minWidth: 280 },
  { label: "Date", minWidth: 150 },
  {
    label: "Category(ies)",
    minWidth: 80,
  },
  { label: "Venue", minWidth: 20 },
  { label: "Status", minWidth: 20 },
  {
    label: "Manage",
    minWidth: 100,
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "58vh",
  },
  ellipsisModeStyle: {
    whiteSpace: "nowrap",
    maxWidth: 240,
    overflow: "hidden",
    textOverflow: "ellipsis",
    OTextOverflow: "ellipsis",
    display: "table-cell",
  },
  imageStyle: {
    display: "block",
    width: "150px",
    height: "150px",
    backgroundColor: "#000",
    cursor: "pointer",
  },
  longWordBreak: {
    wordWrap: "break-word",
    maxWidth: 240,
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: 400,
  },
  textField: {
    fontWeight: 500,
    backgroundColor: "#fff",
    width: "100%",
  },
  textFieldWithBottomMargin: {
    fontWeight: 500,
    backgroundColor: "#fff",
    width: "100%",
    marginBottom: "16px",
  },
  btnStyle: {
    width: "100%",
    fontWeight: "bold",
    marginBottom: "16px",
  },
  buttonTextStyle: {
    color: "#fff",
    fontWeight: "bold",
  },
  alignInOne: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: Colors.brightBlue,
      color: Colors.white,
      fontWeight: "bold",
    },
    body: {
      fontSize: 18,
    },
  })
)(TableCell);

const EventList = (props: any) => {
  const classes = useStyles();
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const [searchInputText, setSearchInputText] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [{ title: "Events", link: routePathNames.EVENTS }];

  const fetchEventsList = () => {
    let body = {
      searchText: searchKeyword,
    };
    setLoading(true);
    props
      .getEvents(currentPage, body)
      .then((result: any) => {
        console.log("resultresult", result);
        setData(result.data.data);
        setTotalPage(result.data.totalPage);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        setData([]);
      });
  };

  const pageChangeHandlerCallback = (newPage: number) => {
    console.log("pageChangeHandlerCallback ~ newPage", newPage);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    console.log("useEffectuseEffect", currentPage, searchKeyword);
    fetchEventsList();
  }, [currentPage, searchKeyword]);

  const deleteEvent = (item: EVENT_ITEM) => {
    let title = "Delete";
    let message = "Do you want to delete this place?";
    alertify.confirm(
      title,
      message,
      function () {
        deleteEventApiCall(item);
      },
      function () {
        // alertify.error('Cancel');
      }
    );
  };

  const editEvent = (item: EVENT_ITEM) => {
    console.log("itemitemitemitem", item);
    props.history.push({
      pathname: routePathNames.EDIT_EVENT,
      state: {
        data: item,
      },
    });
  };

  const deleteEventApiCall = (item: EVENT_ITEM) => {
    setLoading(true);
    props
      .deleteEvent(item.id)
      .then((result: any) => {
        console.log("deleteEventApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          if (data && data.length === 1) {
            console.log("data", data);
            setCurrentPage(currentPage - 1);
          } else {
            fetchEventsList();
          }
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const changePublishStatus = (itemId: number, status: number) => {
    console.log("change1PublishStatus ~ itemId", itemId, status);
    let publishStatus = Boolean(Number(status));
    const body = {
      status: !publishStatus,
    };
    setLoading(true);
    props
      .changeEventStatus(itemId, body)
      .then((result: any) => {
        console.log("change1PublishStatusresultresult", result);
        setLoading(false);
        if (result && result.isSuccess) {
          const index = data.findIndex(
            (item: EVENT_ITEM) => item.id === itemId
          );
          let addObj: EVENT_ITEM;
          let addsList = _.cloneDeep(data);
          addObj = addsList[index];
          addObj["published"] = status === 0 ? 1 : 0;
          setData(addsList);
          toggleSnackBar(true, result.data.message);
          console.log("data", data, addsList, addObj);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const inputChangeHandler = (text: any) => {
    if (text) {
      setSearchInputText(text);
    } else {
      setSearchInputText(text);
      setSearchKeyword(text);
    }
  };

  const searchWithKeyword = () => {
    if (searchInputText) {
      setCurrentPage(1);
      setSearchKeyword(searchInputText);
    } else {
      toggleSnackBar(true, alert.searchAlert);
    }
  };

  return (
    <React.Fragment>
      <SimpleBreadcrumbs breadcrumbData={breadData} heading={"Events List"} />
      <React.Fragment>
        <Grid
          container
          // md={12}
          // xs={12}
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={10}>
            <TextField
              className={classes.textFieldWithBottomMargin}
              id="outlined-size-small"
              label="Search for a Location"
              variant="outlined"
              size="small"
              value={searchInputText}
              onChange={(event: Event) =>
                inputChangeHandler(event.target.value)
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnStyle}
              size="large"
              onClick={searchWithKeyword}
            >
              <Typography className={classes.buttonTextStyle}>
                {"Search"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
        {data && data.length ? (
          <>
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          align={column.align}
                          style={{ width: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item: EVENT_ITEM) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={item.id}
                        >
                          <TableCell>
                            <Typography className={classes.longWordBreak}>
                              {item.title}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.longWordBreak}>
                              {item.dates
                                ? utils.convertDateInEvents(
                                    item.dates,
                                    item.times
                                  ) +
                                  " - " +
                                  utils.convertDateInEvents(
                                    item.enddates,
                                    item.endtimes
                                  )
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.longWordBreak}>
                              {item && item.catname ? item.catname : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>{item.venue ? item.venue : "-"}</TableCell>
                          <TableCell align={"center"}>
                            <Toggle
                              statusType={item.published === 0 ? 0 : 1}
                              itemId={item.id}
                              changePublishCallback={changePublishStatus}
                            />
                          </TableCell>
                          <TableCell>
                            <div className={classes.alignInOne}>
                              <span
                                className="icons-spacing cursor-pointer"
                                onClick={() => editEvent(item)}
                              >
                                <ManageButton
                                  title={"Edit Event"}
                                  buttonType={"edit"}
                                />
                              </span>
                              <span
                                className="cursor-pointer"
                                onClick={() => deleteEvent(item)}
                              >
                                <ManageButton
                                  title={"Delete Event"}
                                  buttonType={"delete"}
                                />
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Grid container justify="center">
              <PaginationComponent
                currentPage={currentPage}
                totalPage={totalPage}
                setPageCallback={pageChangeHandlerCallback}
              />
            </Grid>
          </>
        ) : !loading ? (
          <EmptyComponent />
        ) : null}
      </React.Fragment>
      <Loading isFetching={loading} />
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    placesSuccessFailure: state.getEvents.placesSuccessFailure,
  };
};

const mapDispatch = {
  getEvents: (pageNo: any, body: any) => getEvents(pageNo, body),
  changeEventStatus: (id: number, body: any) => changeEventStatus(id, body),
  deleteEvent: (id: number) => deleteEvent(id),
};

const connector = connect(mapStateToProps, mapDispatch);
export default connector(EventList);
