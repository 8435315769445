import AxiosInstance from "./api/axios";
import * as utils from "../src/utils/index";

export default {
  setupInterceptors: (store: any, history: any) => {
    AxiosInstance.interceptors.response.use(
      (response) => {
        console.log("axios.interceptors.response", response);
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          utils.logout();
          setTimeout(() => {
            return (window.location.href = "/");
          });
        }
        return Promise.reject(error);
      }
    );

    // Add a request interceptor
    AxiosInstance.interceptors.request.use(
      (config) => {
        console.log("axios.interceptors.request", config);
        // you can add some information before send it.

        utils.setCorsHeader();
        const token = utils.getAccessToken();
        const clientIpAddress = utils.getClientIp();
        if (token) {
          config.headers["x-access-token"] = token;
          config.headers["CLIENT_IP"] = clientIpAddress;
          console.log("config", config);
        }
        return config;
      },
      (error) => {
        console.log("axios.interceptors.request", error);
        // you can add some information before send it.
        return Promise.reject(error);
      }
    );
  },
};
