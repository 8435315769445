import {
  SAVE_APP_CONTENT_SUCCESS_FAILURE,
  InitialState,
  Action,
  ICON_LIST_SUCCESS_FAILURE,
  WEATHER_DETAILS_SUCCESS_FAILURE,
  APP_CONTENT_DETAILS_SUCCESS_FAILURE,
} from "../../types/home";

const initialState: InitialState = {
  appContentSuccessFailure: undefined,
  iconsListSuccessFailure: [],
  weatherDeatilsSuccessFailure: undefined,
  appContentDetailsSuccessFailure: undefined,
};

const saveAppContent = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case SAVE_APP_CONTENT_SUCCESS_FAILURE:
      return {
        ...state,
        appContentSuccessFailure: actions.data,
      };
    case ICON_LIST_SUCCESS_FAILURE:
      return {
        ...state,
        iconsListSuccessFailure: actions.data,
      };
    case WEATHER_DETAILS_SUCCESS_FAILURE:
      return {
        ...state,
        weatherDeatilsSuccessFailure: actions.data,
      };
    case APP_CONTENT_DETAILS_SUCCESS_FAILURE:
      return {
        ...state,
        appContentDetailsSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default saveAppContent;
