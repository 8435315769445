import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "-.5vh",
    // marginLeft: '1rem',
    display: "flex",
    // flexDirection: 'row',
    alignItems: "center",
    marginBottom: "1rem",
  },
  linkStyle: {
    color: "#000",
    opacity: 1,
    font: "normal normal normal 18px Montserrat",
    cursor: "pointer",
    zIndex: 3,
  },
  arrowStyle: {
    color: "#000",
    fontSize: "1rem",
    marginLeft: "-9px",
    marginRight: "-9px",
  },
  titleStyle: {
    color: "#000",
    fontWeight: "bold",
    fontSize: "18px",
    marginLeft: "3px",
    font: "Montserrat",
  },
}));
export default function SimpleBreadcrumbs(props: any) {
  const classes = useStyles();
  const array = props.breadcrumbData;
  const heading = props.heading;
  const handleClick = (path: any) => {
    if (path) {
      props.history.push(path, props.history.location.state);
    }
  };
  const arrayList =
    array && array.length
      ? array.map((item: any) => {
          return (
            <Link
              key={item}
              className={classes.linkStyle}
              //   underline="always"
              href={item.link}
              //   onClick={() => handleClick(item.link)}
            >
              {item.title}
            </Link>
          );
        })
      : null;
  const arrayLength = arrayList && arrayList.length ? arrayList.length : 0;
  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="small" className={classes.arrowStyle} />
        }
        aria-label="breadcrumb"
      >
        {arrayList}
        <Typography className={classes.titleStyle}>{heading}</Typography>
      </Breadcrumbs>
    </div>
  );
}
