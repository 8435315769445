export interface Action {
  type: string;
  data: object | boolean;
}

export const SAVE_APP_CONTENT_SUCCESS_FAILURE: string =
  "SAVE_APP_CONTENT_SUCCESS_FAILURE";
export const ICON_LIST_SUCCESS_FAILURE: string = "ICON_LIST_SUCCESS_FAILURE";
export const WEATHER_DETAILS_SUCCESS_FAILURE: string =
  "WEATHER_DETAILS_SUCCESS_FAILURE";
export const APP_CONTENT_DETAILS_SUCCESS_FAILURE: string =
  "APP_CONTENT_DETAILS_SUCCESS_FAILURE";

export interface InitialState {
  appContentSuccessFailure: any;
  iconsListSuccessFailure: any;
  weatherDeatilsSuccessFailure: any;
  appContentDetailsSuccessFailure: any;
}

export interface APP_CONTENT_PAYLOAD {
  imgFlag?: boolean;
  imageFile?: any;
  beachReport?: any;
  weather?: {
    icon?: string;
    summary?: any;
    currentTemperatureF?: any;
    currentTemperatureC?: any;
    currentWaterTemperature?: any;
    temperatureMin?: any;
    temperatureMax?: any;
    tide?: any;
    tideTime?: any;
    windSpeed?: any;
    windDirection?: any;
    swellMaxHeight?: any;
  };
}
