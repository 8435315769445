export const publishUnpublish = [
  { label: "Publish", value: 1 },
  { label: "Unpublish", value: 0 },
];

export const adminPublishPlaceActions = [
  { label: "Publish Revision", value: 1 },
  { label: "Reject Revision", value: 0 },
];

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
export const tempVendorsData = [
  "The Shawshank Redemption",
  "The Godfather",
  "The Godfather: Part II",
  "The Dark Knight",
  "12 Angry Men",
  "Schindler's List",
  "Pulp Fiction",
  "The Lord of the Rings: The Return of the King",
  "The Good, the Bad and the Ugly",
  "Fight Club",
];
