import React, { useState, useEffect } from "react";
import "../src/App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./App.css";
import { routePathNames } from "../src/utils/constants";
import SideBar from "./modules/sideBar/sideBar";
import SignIn from "../src/modules/signin/signIn";
import * as utils from "../src/utils/index";
import ForgotPassword from "../src/modules/password/forgotPassword";
import ResetPassword from "../src/modules/password/resetPassword";

const getRoleType = (data: any) => {
  if (data && data.length === 2) {
    return "Admin";
  } else {
    if (data[0] === "Vendor") {
      return "Vendor";
    } else {
      return "Admin";
    }
  }
};

function App(props: any) {
  console.log("App props", props, utils.getLoginDetails(), utils.getRole());
  const [role, setRole] = useState(utils.getRole());

  const setRoleCall = (data: any) => {
    console.log("setRoleCall ~ data", data);
    setRole(data);
  };

  useEffect(() => {
    setRole(utils.getRole());
  }, [localStorage]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Route
        exact
        path={routePathNames.SIGNIN}
        render={(routeProps) => (
          <SignIn {...routeProps} setRoleCallback={setRoleCall} />
        )}
      />
      <Route
        exact
        path={routePathNames.FORGOT_PASSWORD}
        render={(routeProps) => <ForgotPassword {...routeProps} />}
      />
      <Route
        path={routePathNames.RESET_PASSWORD}
        render={(routeProps) => <ResetPassword {...routeProps} />}
      />
      <Switch>
        <Route
          path={routePathNames.DASHBOARD}
          render={(routeProps) => (
            <SideBar {...routeProps} currentRole={role} />
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
