import {
  InitialState,
  Action,
  PLACES_REVISIONS_SUCCESS_FAILURE,
  VENDOR_ADD_PLACE_REVIEW_SUCCESS_FAILURE,
  ADMIN_PENDING_REVIEW_SUCCESS_FAILURE,
  DELETE_REVISION_ADMIN_SUCCESS_FAILURE,
} from "../../types/revisions";

const initialState: InitialState = {
  placesRevisionSuccessFailure: undefined,
  vendorAddPlaceForReviewSuccessFailure: undefined,
  adminPendingReviewSuccessFailure: undefined,
  deleteRevisionByAdminSuccessFailure: undefined,
};

const revisions = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case PLACES_REVISIONS_SUCCESS_FAILURE:
      return {
        ...state,
        singlePlaceDataSuccessFailure: actions.data,
      };
    case VENDOR_ADD_PLACE_REVIEW_SUCCESS_FAILURE:
      return {
        ...state,
        vendorAddPlaceForReviewSuccessFailure: actions.data,
      };
    case ADMIN_PENDING_REVIEW_SUCCESS_FAILURE:
      return {
        ...state,
        adminPendingReviewSuccessFailure: actions.data,
      };
    case DELETE_REVISION_ADMIN_SUCCESS_FAILURE:
      return {
        ...state,
        deleteRevisionByAdminSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default revisions;
