import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./store/store";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import "./fonts/Montserrat-Regular.ttf";
import "./fonts/Montserrat-SemiBold.ttf";
import "./fonts/Montserrat-Bold.ttf";

// Required for Redux store setup
import { Provider } from "react-redux";

//Axios interceptors setup
import { createBrowserHistory } from "history";
import NetworkService from "./network-service";
const history = createBrowserHistory();
NetworkService.setupInterceptors(configureStore(), history);

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
    button: {
      textTransform: "none",
    },
  },
  palette: {
    secondary: {
      main: "#123068",
    },
    primary: {
      main: "#1aa4dc",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={configureStore()}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
