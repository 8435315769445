import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  GET_CATEGORIES_SUCCESS_FAILURE,
  ADVERTISEMENT_LIST_SUCCESS_FAILURE,
  CHANGE_ADVERTISEMENT_PUBLISH_STATUS_SUCCESS_FAILURE,
  DELETE_ADVERTISEMENT_SUCCESS_FAILURE,
  CREATE_ADVERTISEMENT_SUCCESS_FAILURE,
  publishStatusChange,
  createNewAdvertisement,
  editAdvertisement,
  EDIT_ADVERTISEMENT_SUCCESS_FAILURE,
} from "../../types/advertisements";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const categoriesSuccessFailure = (data: any) => {
  return {
    type: GET_CATEGORIES_SUCCESS_FAILURE,
    data,
  };
};

const advertisementsSuccessFailure = (data: any) => {
  return {
    type: ADVERTISEMENT_LIST_SUCCESS_FAILURE,
    data,
  };
};

const advertisementPublishStatusSuccessFailure = (data: any) => {
  return {
    type: CHANGE_ADVERTISEMENT_PUBLISH_STATUS_SUCCESS_FAILURE,
    data,
  };
};

const deleteAdvertisementSuccessFailure = (data: any) => {
  return {
    type: DELETE_ADVERTISEMENT_SUCCESS_FAILURE,
    data,
  };
};

const createAdvertisementSuccessFailure = (data: any) => {
  return {
    type: CREATE_ADVERTISEMENT_SUCCESS_FAILURE,
    data,
  };
};

const editAdvertisementSuccessFailure = (data: any) => {
  return {
    type: EDIT_ADVERTISEMENT_SUCCESS_FAILURE,
    data,
  };
};

export const getCategories = () => {
  return (dispatch: Dispatch) => {
    const url = "categories";
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(categoriesSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(categoriesSuccessFailure(result));
        return result;
      });
  };
};

export const getAdvertisements = (pageNo: number) => {
  return (dispatch: Dispatch) => {
    const url = "advertisements/" + pageNo;
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(advertisementsSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(advertisementsSuccessFailure(result));
        return result;
      });
  };
};

export const changeAdvertisementPublishStatus = (
  adsId: number,
  body: publishStatusChange
) => {
  return (dispatch: Dispatch) => {
    const url = "advertisements/published/" + adsId;
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(advertisementPublishStatusSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(advertisementPublishStatusSuccessFailure(result));
        return result;
      });
  };
};

export const deleteAdvertisement = (adsId: number) => {
  return (dispatch: Dispatch) => {
    const url = "advertisements/" + adsId;
    return AxiosInstance.delete(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(deleteAdvertisementSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(deleteAdvertisementSuccessFailure(result));
        return result;
      });
  };
};

export const createAdvertisement = (body: createNewAdvertisement) => {
  return (dispatch: Dispatch) => {
    const url = "advertisements";
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(createAdvertisementSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(createAdvertisementSuccessFailure(result));
        return result;
      });
  };
};

export const editAdvertisementCall = (
  adsId: number,
  body: editAdvertisement
) => {
  console.log("editAdvertisementCall", adsId, body);
  return (dispatch: Dispatch) => {
    const url = "advertisements/" + adsId;
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(editAdvertisementSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(editAdvertisementSuccessFailure(result));
        return result;
      });
  };
};
