import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  SAVE_APP_CONTENT_SUCCESS_FAILURE,
  APP_CONTENT_PAYLOAD,
  ICON_LIST_SUCCESS_FAILURE,
  WEATHER_DETAILS_SUCCESS_FAILURE,
  APP_CONTENT_DETAILS_SUCCESS_FAILURE
} from "../../types/home";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const appContentSuccessFailure = (data: any) => {
  return {
    type: SAVE_APP_CONTENT_SUCCESS_FAILURE,
    data,
  };
};

const iconsListSuccessFailure = (data: any) => {
  return {
    type: ICON_LIST_SUCCESS_FAILURE,
    data,
  };
};

const weatherDeatilsSuccessFailure = (data: any) => {
  return {
    type: WEATHER_DETAILS_SUCCESS_FAILURE,
    data,
  };
};

const appContentDetailsSuccessFailure = (data: any) => {
  return {
    type: APP_CONTENT_DETAILS_SUCCESS_FAILURE,
    data,
  };
};

export const saveAppContent = (body: APP_CONTENT_PAYLOAD) => {
  return (dispatch: Dispatch) => {
    const url = "weatherReport/";
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(appContentSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(appContentSuccessFailure(result));
        return result;
      });
  };
};

export const getIconsList = () => {
  return (dispatch: Dispatch) => {
    const url = "weatherReport/icon";
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(iconsListSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(iconsListSuccessFailure(result));
        return result;
      });
  };
};

export const getWeatherDeatils = () => {
  return (dispatch: Dispatch) => {
    const url = "weatherReport";
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(weatherDeatilsSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(weatherDeatilsSuccessFailure(result));
        return result;
      });
  };
};

export const getAppContentDetails = () => {
  return (dispatch: Dispatch) => {
    const url = "weatherReport/details";
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(appContentDetailsSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(appContentDetailsSuccessFailure(result));
        return result;
      });
  };
};
