export const PLACES_REVISIONS_SUCCESS_FAILURE: string =
  "PLACES_REVISIONS_SUCCESS_FAILURE";
export const VENDOR_ADD_PLACE_REVIEW_SUCCESS_FAILURE: string =
  "VENDOR_ADD_PLACE_REVIEW_SUCCESS_FAILURE";
export const ADMIN_PENDING_REVIEW_SUCCESS_FAILURE: string =
  "ADMIN_PENDING_REVIEW_SUCCESS_FAILURE";
export const DELETE_REVISION_ADMIN_SUCCESS_FAILURE: string =
  "DELETE_REVISION_ADMIN_SUCCESS_FAILURE";

export interface InitialState {
  placesRevisionSuccessFailure: any;
  vendorAddPlaceForReviewSuccessFailure: any;
  adminPendingReviewSuccessFailure: any;
  deleteRevisionByAdminSuccessFailure: any;
}
export interface Action {
  type: string;
  data: object | boolean;
}

export interface PENDING_REVISION_ITEM {
  id: number;
  title: string;
  revisedOn: string;
  revisedBy?: string;
  status: string;
}

export interface PlacesTableColumn {
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

export interface vendorEditPlaceForRevision {
  data: {
    title: string;
    imageFile?: any;
    imgName?: any;
    street: string;
    city: string;
    federal_state: string;
    postcode: any;
    county?: string;
    country?: string;
    phone?: string;
    fax?: string;
    website?: string;
    longitude?: any;
    latitude?: any;
    email?: string;
    contact_person?: string;
    hotline?: string;
    description?: any;
    outside_ocnj?: any;
    categoryIds?: any;
    image?: any;
    imageFlag?: any;
    place_id?: any;
    user_id?: any;
    Vendoremail?: string;
  };
}

export interface adminPendingRevision {
  title: string;
  imageFile?: any;
  imgName?: any;
  street: string;
  city: string;
  federal_state: string;
  postcode: any;
  county?: string;
  country?: string;
  phone?: string;
  fax?: string;
  website?: string;
  longitude?: any;
  latitude?: any;
  email?: string;
  contact_person?: string;
  hotline?: string;
  description?: any;
  outside_ocnj?: any;
  categoryIds?: any;
  image?: any;
  imageFlag?: any;
  place_id?: any;
  user_id?: any;
  Vendoremail?: string;
  adminAction?: boolean;
  id?: any;
  state?: any;
  vendorIds?: any;
}
