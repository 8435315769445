import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > *": {
        marginTop: theme.spacing(4),
      },
    },
  })
);

export default function PaginationComponent(props: any) {
  let { currentPage, totalPage, setPageCallback } = props;
  const classes = useStyles();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageCallback(newPage);
  };

  return (
    <div className={classes.root}>
      <Pagination
        page={currentPage}
        count={totalPage}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        color="primary"
        onChange={handleChangePage}
      />
    </div>
  );
}
