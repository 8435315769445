import {
  USERS_SUCCESS_FAILURE,
  InitialState,
  Action,
  ADD_NEW_USER_SUCCESS_FAILURE,
  EDIT_USER_SUCCESS_FAILURE,
  DELETE_USER_SUCCESS_FAILURE,
} from "../../types/users";

const initialState: InitialState = {
  isFetching: false,
  usersSuccessFailure: [],
  addUserSuccessFailure: [],
  editUserSuccessFailure: [],
  deleteUserSuccessFailure: [],
};
const getUsers = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case USERS_SUCCESS_FAILURE:
      return {
        ...state,
        usersSuccessFailure: actions.data,
      };
    case ADD_NEW_USER_SUCCESS_FAILURE:
      return {
        ...state,
        addUserSuccessFailure: actions.data,
      };
    case EDIT_USER_SUCCESS_FAILURE:
      return {
        ...state,
        editUserSuccessFailure: actions.data,
      };
    case DELETE_USER_SUCCESS_FAILURE:
      return {
        ...state,
        deleteUserSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default getUsers;
