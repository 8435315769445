import {
  InitialState,
  INSTAIMG_LIST_SUCCESS_FAILURE,
  CHANGE_INSTAIMG_PUBLISH_STATUS_SUCCESS_FAILURE,
  Action,
  DELETE_INSTAIMG_SUCCESS_FAILURE,
} from "../../types/insta";

const initialState: InitialState = {
  instaImgsSuccessFailure: [],
  instaImgPublishStatusSuccessFailure: [],
  deleteinstaImgSuccessFailure: [],
};

const instaImgs = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case INSTAIMG_LIST_SUCCESS_FAILURE:
      return {
        ...state,
        instaImgsSuccessFailure: actions.data,
      };
    case CHANGE_INSTAIMG_PUBLISH_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        instaImgPublishStatusSuccessFailure: actions.data,
      };
    case DELETE_INSTAIMG_SUCCESS_FAILURE:
      return {
        ...state,
        deleteinstaImgSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default instaImgs;
