export const GET_CATEGORIES_SUCCESS_FAILURE: string =
  "GET_CATEGORIES_SUCCESS_FAILURE";
export const ADVERTISEMENT_LIST_SUCCESS_FAILURE: string =
  "ADVERTISEMENT_LIST_SUCCESS_FAILURE";
export const CHANGE_ADVERTISEMENT_PUBLISH_STATUS_SUCCESS_FAILURE: string =
  "CHANGE_ADVERTISEMENT_PUBLISH_STATUS_SUCCESS_FAILURE";
export const DELETE_ADVERTISEMENT_SUCCESS_FAILURE: string =
  "DELETE_ADVERTISEMENT_SUCCESS_FAILURE";
export const CREATE_ADVERTISEMENT_SUCCESS_FAILURE: string =
  "CREATE_ADVERTISEMENT_SUCCESS_FAILURE";
export const EDIT_ADVERTISEMENT_SUCCESS_FAILURE: string =
  "EDIT_ADVERTISEMENT_SUCCESS_FAILURE";

export interface InitialState {
  categoriesSuccessFailure: any;
  advertisementsSuccessFailure: any;
  advertisementPublishStatusSuccessFailure: any;
  deleteAdvertisementSuccessFailure: any;
  createAdvertisementSuccessFailure: any;
  editAdvertisementSuccessFailure: any;
}
export interface Action {
  type: string;
  data: object | boolean;
}

export interface ADVERTISMENT_ITEM {
  id: number;
  categoryName: [];
  display_general: number;
  image_url?: string | undefined;
  link: string;
  published: number;
}

export interface AdsTableColumn {
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

export interface AdsTableData {}

export interface publishStatusChange {
  status: number;
}

export interface createNewAdvertisement {
  imageFile: any;
  imgName: string;
  published: number;
  display_general: number;
  link: string;
  categoryIds: any;
}

export interface editAdvertisement {
  imageFlag?: boolean;
  imageFile?: any;
  imgName?: string;
  published: number;
  display_general: number;
  link: string;
  categoryIds: any;
}
