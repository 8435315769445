import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Colors } from "../../utils/colors";
import Loading from "../../commonComponents/loading";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import { rolesArray, routePathNames } from "../../utils/constants";
import PaginationComponent from "../../commonComponents/pagination";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import {
  NotificationTableColumn,
  NotificationTableData,
} from "./types/notifications";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import "./css/notifications.css";
import { getNotifications } from "./redux/actions/notifications";
import { connect, useSelector } from "react-redux";
import ShowSnackBar from "../../commonComponents/snackBar";
import EmptyComponent from "../../commonComponents/emptyComponent";
import Typography from "@material-ui/core/Typography";

// @ts-ignore
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { formatDate } from "../../utils/index";

const columns: NotificationTableColumn[] = [
  { label: "Sent", minWidth: 150 },
  { label: "Expires/Scheduled", minWidth: 150 },
  {
    label: "By",
    minWidth: 100,
  },
  {
    label: "Message",
    minWidth: 100,
  },
  {
    label: "Category",
    minWidth: 100,
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 420,
  },
  longWordBreak: {
    wordWrap: "break-word",
    maxWidth: 240,
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: 400,
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: Colors.brightBlue,
      color: Colors.white,
      fontWeight: "bold",
    },
    body: {
      fontSize: 18,
    },
  })
)(TableCell);

const Notifications = (props: any) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [
    { title: "Notifications", link: routePathNames.NOTIFICATIONS },
  ];

  const fetchNotificationsData = () => {
    setLoading(true);
    props
      .getNotifications(currentPage)
      .then((result: any) => {
        console.log("resultresult", result);
        setData(result.data.notifications);
        setTotalPage(result.data.totalPage);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        setData([]);
      });
  };

  const pageChangeHandlerCallback = (newPage: number) => {
    console.log("pageChangeHandlerCallback ~ newPage", newPage);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchNotificationsData();
  }, [currentPage]);

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  return (
    <React.Fragment>
      <SimpleBreadcrumbs breadcrumbData={breadData} heading={"History"} />
      {data && data.length ? (
        <React.Fragment>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  {columns.map((column) => (
                    <StyledTableCell
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {data.map((item: NotificationTableData) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item.id}
                      >
                        <TableCell>{formatDate(item.created_at)}</TableCell>
                        <TableCell>
                          {item.expires
                            ? formatDate(item.expires)
                            : formatDate(item.schedule)}
                        </TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>
                          <Typography className={classes.longWordBreak}>
                            {item.notification_text}
                          </Typography>
                        </TableCell>
                        <TableCell>{item.category}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Grid container justify="center">
            <PaginationComponent
              currentPage={currentPage}
              totalPage={totalPage}
              setPageCallback={pageChangeHandlerCallback}
            />
          </Grid>
        </React.Fragment>
      ) : !loading ? (
        <EmptyComponent />
      ) : null}
      <Loading isFetching={loading} />
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    notificationsSuccessFailure:
      state.notifications.notificationsSuccessFailure,
  };
};

const mapDispatch = {
  getNotifications: (pageNo: any) => getNotifications(pageNo),
};

const connector = connect(mapStateToProps, mapDispatch);
export default connector(Notifications);
