export interface Roles {
  name: any;
  id: any;
}

export const ADD_NOTIFICATION_SUCCESS_FAILURE: string =
  "ADD_NOTIFICATION_SUCCESS_FAILURE";
export const NOTIFICATIONS_SUCCESS_FAILURE: string =
  "NOTIFICATIONS_SUCCESS_FAILURE";
export const CATEGORIES_SUCCESS_FAILURE: string = "CATEGORIES_SUCCESS_FAILURE";
export const MOBILE_USERS_COUNT_SUCCESS_FAILURE: string =
  "MOBILE_USERS_COUNT_SUCCESS_FAILURE";
export const SEND_NOTIFICATION_SUCCESS_FAILURE: string =
  "SEND_NOTIFICATION_SUCCESS_FAILURE";

export interface InitialState {
  isFetching: boolean;
  notificationsSuccessFailure: any;
  sendNotificationSuccessFailure: any;
}
export interface Action {
  type: string;
  data: object | boolean;
}

export interface Category {
  id: number;
  name: String;
}

export interface CircleData {
  radius: any;
  lat: any;
  long: any;
}

export interface NotificationTableColumn {
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

export interface NotificationTableData {
  id: any;
  created_at: string;
  expires: string;
  schedule: string;
  email: any;
  notification_text: string;
  category: any;
}

export interface latLng {
  lat: number;
  lng: number;
}

export interface AddNotifictionParams {
  type: String;
  notificationText: string;
  fullText: string;
  locations?: latLng[];
  distance?: number;
  category?: number;
  scheduled?: any;
  expires?: any;
}

export interface notification_payload {
  type: string;
  numOfNotification?: any | null;
  notificationDifference?: any | null;
  notification_text: string;
  full_text: string | undefined;
  lat?: any | null;
  lng?: any | null;
  radius?: any | null;
  category?: any | null;
  scheduled?: any;
  expires?: any;
}
