import { combineReducers } from "redux";
import getUsers from "../modules/users/redux/reducers/getUsers";
import userSignIn from "../modules/signin/redux/reducer/signIn";
import advertisement from "../modules/advertisements/redux/reducers/advertisement";
import homescreenimage from "../modules/HomeScreenImages/redux/reducers/homescreenimage";
import saveAppContent from "../modules/appContent/redux/reducers/appContent";
import notifications from "../modules/notifications/redux/reducers/notifications";
import getPlaces from "../modules/places/redux/reducers/getPlaces";
import revisions from "../modules/places/redux/reducers/revisions";
import getEvents from "../modules/events/redux/reducers/getEvents";
import instaImgs from "../modules/instagram/redux/reducers/insta";
import forgotPassword from "../modules/password/redux/reducer/forgotPassword";
import resetPassword from "../modules/password/redux/reducer/resetPassword";
import storiesReports from "../modules/stories/redux/reducers/stories";

const rootReducer = combineReducers({
  getUsers: getUsers,
  userSignIn: userSignIn,
  advertisement: advertisement,
  homescreenimage:homescreenimage,
  saveAppContent: saveAppContent,
  notifications: notifications,
  getPlaces: getPlaces,
  revisions: revisions,
  getEvents: getEvents,
  instaImgs: instaImgs,
  forgotPassword: forgotPassword,
  resetPassword: resetPassword,
  storiesReports: storiesReports,
});
export default rootReducer;
