import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Colors } from "../../utils/colors";
import Loading from "../../commonComponents/loading";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import { rolesArray, routePathNames } from "../../utils/constants";
import PaginationComponent from "../../commonComponents/pagination";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import {
  HomeScreenImagesTableColumn,
  HomeScreenImagesTableData,
} from "./types/homescreenimages";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import {
  getHomeScreenImages,
  changeHomeScreenImagesPublishStatus,
  deleteHomeScreenImages,
} from "./redux/actions/homescreenimage";

import { connect, useSelector } from "react-redux";
import ShowSnackBar from "../../commonComponents/snackBar";
import logoImage from "../../assets/ocnj-logo.png";
import Toggle from "../../commonComponents/publishToggle";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import _, { result } from "lodash";
import { HOMESCREENIMAGES_ITEM } from "./types/homescreenimages";
import EmptyComponent from "../../commonComponents/emptyComponent";
import ManageButton from "../../commonComponents/manageButtons";

// @ts-ignore
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

const columns: HomeScreenImagesTableColumn[] = [
  { label: "Images", minWidth: 140 },
  {
    label: "Added by",
    minWidth: 10,
  },
  { label: "Link To", minWidth: 10 },

  { label: "Status" },
  {
    label: "Manage",
    minWidth: 100,
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 420,
  },
  ellipsisModeStyle: {
    whiteSpace: "nowrap",
    maxWidth: 240,
    overflow: "hidden",
    textOverflow: "ellipsis",
    OTextOverflow: "ellipsis",
    display: "table-cell",
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: 400,
  },
  imageStyle: {
    display: "block",
    width: "150px",
    height: "150px",
    backgroundColor: "#000",
    cursor: "pointer",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: Colors.brightBlue,
      color: Colors.white,
      fontWeight: "bold",
    },
    body: {
      fontSize: 18,
    },
  })
)(TableCell);

const HomeScreenImagesList = (props: any) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [
    { title: "Home Screen Images", link: routePathNames.HOME_SCREEN_IMAGES },
  ];

  const fetchHomeScreenImages = () => {
    setLoading(true);
    props
      .getHomeScreenImages(currentPage)
      .then((result: any) => {
        console.log("getHomeScreenImages", result);
        setData(result.data.data);
        setTotalPage(result.data.totalPage);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((err: any) => {
        setLoading(false);
        setData([]);
      });
  };

  const pageChangeHandlerCallback = (newPage: number) => {
    console.log("pageChangeHandlerCallback ~ newPage", newPage);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchHomeScreenImages();
  }, [currentPage]);

  const deleteUserimage = (item: HOMESCREENIMAGES_ITEM) => {
    let title = "Delete";
    let message = "Do you want to delete this Home Screen Image?";
    alertify.confirm(
      title,
      message,
      function () {
        deleteUserApiCall(item);
      },
      function () {
        // alertify.error('Cancel');
      }
    );
  };

  const editUserImage = (item: HOMESCREENIMAGES_ITEM) => {
    console.log("edituserimage", item);
    props.history.push({
      pathname: routePathNames.EDIT_HOMESCREEN_IMAGES,
      state: {
        data: item,
      },
    });
  };

  const deleteUserApiCall = (item: HOMESCREENIMAGES_ITEM) => {
    setLoading(true);
    props
      .deleteHomeScreenImages(item.id)
      .then((result: any) => {
        console.log("deleteUserimage", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          if (data && data.length === 1) {
            console.log("data", data);
            setCurrentPage(currentPage - 1);
          } else {
            fetchHomeScreenImages();
          }
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const changePublishStatus = (id: number, status: number) => {
    console.log("changePublishStatus ~ itemId", id, status);
    let publishStatus = status == 0 ? 1 : 0;
    const body = {
      published: Number(publishStatus),
    };
    console.log("changePublishStatus ~ itemId 2", id, status, body);
    setLoading(true);
    props
      .changeHomeScreenImagesPublishStatus(id, body)
      .then((result: any) => {
        console.log("changeHomeScreenImagesPublishStatus", result);
        setLoading(false);
        if (result && result.isSuccess) {
          const index = data.findIndex(
            (item: HOMESCREENIMAGES_ITEM) => item.id === id
          );
          let addObj: HOMESCREENIMAGES_ITEM;
          let addsList = _.cloneDeep(data);
          addObj = addsList[index];
          addObj["published"] ^= 1;
          setData(addsList);
          toggleSnackBar(true, result.data.message);
          console.log("data", data, addsList, addObj);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const openImageInNewTab = (url: any) => {
    window.open(url);
  };

  return (
    <React.Fragment>
      <SimpleBreadcrumbs
        breadcrumbData={breadData}
        heading={"Home Screen Images"}
      />
      {data && data.length ? (
        <React.Fragment>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item: HOMESCREENIMAGES_ITEM) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item.id}
                      >
                        <TableCell>
                          <img
                            src={item.imageurl}
                            className={classes.imageStyle}
                            onClick={() => openImageInNewTab(item.imageurl)}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.ellipsisModeStyle}>
                            <a id="link" target="_blank">
                              {item.email}
                            </a>
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography className={classes.ellipsisModeStyle}>
                            {item && item.url ? (
                              <Tooltip
                                arrow={true}
                                placement="top"
                                title={
                                  <h2 style={{ color: "#fff" }}>{item.url}</h2>
                                }
                              >
                                <Typography
                                  className={classes.ellipsisModeStyle}
                                >
                                  <a id="link" href={item.url} target="_blank">
                                    {item.url}
                                  </a>
                                </Typography>
                              </Tooltip>
                            ) : (
                              "-"
                            )}
                          </Typography>
                        </TableCell>

                        <TableCell align={"center"}>
                          <Toggle
                            statusType={item.published}
                            itemId={item.id}
                            changePublishCallback={changePublishStatus}
                          />
                        </TableCell>
                        <TableCell>
                          <span
                            className="icons-spacing cursor-pointer"
                            onClick={() => editUserImage(item)}
                          >
                            <ManageButton
                              title={"Edit Home Screen Image"}
                              buttonType={"edit"}
                            />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => deleteUserimage(item)}
                          >
                            <ManageButton
                              title={"Delete Home Screen Image"}
                              buttonType={"delete"}
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Grid container justify="center">
            <PaginationComponent
              currentPage={currentPage}
              totalPage={totalPage}
              setPageCallback={pageChangeHandlerCallback}
            />
          </Grid>
        </React.Fragment>
      ) : !loading ? (
        <EmptyComponent />
      ) : null}
      <Loading isFetching={loading} />
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => {
  return {
    usersSuccessFailure: state.getUsers.usersSuccessFailure,
  };
};

const mapDispatch = {
  getHomeScreenImages: (pageNo: any) => getHomeScreenImages(pageNo),
  changeHomeScreenImagesPublishStatus: (id: number, body: any) =>
    changeHomeScreenImagesPublishStatus(id, body),
  deleteHomeScreenImages: (id: number) => deleteHomeScreenImages(id),
};

const connector = connect(mapStateToProps, mapDispatch);
export default connector(HomeScreenImagesList);
