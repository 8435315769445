import React, { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Colors } from "../../utils/colors";
import Loading from "../../commonComponents/loading";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import { rolesArray, routePathNames } from "../../utils/constants";
import PaginationComponent from "../../commonComponents/pagination";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import { AdsTableColumn, AdsTableData } from "./types/advertisements";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import {
  getAdvertisements,
  changeAdvertisementPublishStatus,
  deleteAdvertisement,
} from "./redux/actions/advertisement";
import { connect, useSelector } from "react-redux";
import ShowSnackBar from "../../commonComponents/snackBar";
import logoImage from "../../assets/ocnj-logo.png";
import Toggle from "../../commonComponents/publishToggle";
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import { ADVERTISMENT_ITEM } from "./types/advertisements";
import EmptyComponent from "../../commonComponents/emptyComponent";
import ManageButton from "../../commonComponents/manageButtons";

// @ts-ignore
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

const columns: AdsTableColumn[] = [
  { label: "Advertisement", minWidth: 170 },
  { label: "Links To", minWidth: 10 },
  {
    label: "Category(ies)",
  },
  { label: "General" },
  { label: "Status" },
  {
    label: "Manage",
    minWidth: 100,
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 420,
  },
  ellipsisModeStyle: {
    whiteSpace: "nowrap",
    maxWidth: 240,
    overflow: "hidden",
    textOverflow: "ellipsis",
    OTextOverflow: "ellipsis",
    display: "table-cell",
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    fontWeight: 400,
  },
  imageStyle: {
    display: "block",
    width: "150px",
    height: "150px",
    backgroundColor: "#000",
    cursor: "pointer",
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: Colors.brightBlue,
      color: Colors.white,
      fontWeight: "bold",
    },
    body: {
      fontSize: 18,
    },
  })
)(TableCell);

const AdvertisementList = (props: any) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [
    { title: "Advertisements", link: routePathNames.ADVERTISEMENTS },
  ];

  const fetchAdvertisements = () => {
    setLoading(true);
    props
      .getAdvertisements(currentPage)
      .then((result: any) => {
        console.log("resultresult", result);
        setData(result.data.advertisement);
        setTotalPage(result.data.pageCount);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((err: any) => {
        setLoading(false);
        setData([]);
      });
  };

  const pageChangeHandlerCallback = (newPage: number) => {
    console.log("pageChangeHandlerCallback ~ newPage", newPage);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchAdvertisements();
  }, [currentPage]);

  const deleteUser = (item: ADVERTISMENT_ITEM) => {
    let title = "Delete";
    let message = "Do you want to delete this advertisement?";
    alertify.confirm(
      title,
      message,
      function () {
        deleteUserApiCall(item);
      },
      function () {
        // alertify.error('Cancel');
      }
    );
  };

  const editUser = (item: ADVERTISMENT_ITEM) => {
    console.log("itemitemitemitem", item);
    props.history.push({
      pathname: routePathNames.EDIT_ADVERTISEMENT,
      state: {
        data: item,
      },
    });
  };

  const deleteUserApiCall = (item: ADVERTISMENT_ITEM) => {
    setLoading(true);
    props
      .deleteAdvertisement(item.id)
      .then((result: any) => {
        console.log("deleteUserApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          if (data && data.length === 1) {
            console.log("data", data);
            setCurrentPage(currentPage - 1);
          } else {
            fetchAdvertisements();
          }
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const changePublishStatus = (itemId: number, status: number) => {
    console.log("changePublishStatus ~ itemId", itemId, status);
    let publishStatus = Boolean(Number(status));
    const body = {
      status: !publishStatus,
    };
    setLoading(true);
    props
      .changeAdvertisementPublishStatus(itemId, body)
      .then((result: any) => {
        console.log("changePublishStatusresultresult", result);
        setLoading(false);
        if (result && result.isSuccess) {
          const index = data.findIndex(
            (item: ADVERTISMENT_ITEM) => item.id === itemId
          );
          let addObj: ADVERTISMENT_ITEM;
          let addsList = _.cloneDeep(data);
          addObj = addsList[index];
          addObj["published"] ^= 1;
          setData(addsList);
          toggleSnackBar(true, result.data.message);
          console.log("data", data, addsList, addObj);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const openImageInNewTab = (url: any) => {
    window.open(url);
  };

  return (
    <React.Fragment>
      <SimpleBreadcrumbs
        breadcrumbData={breadData}
        heading={"Advertisements List"}
      />
      {data && data.length ? (
        <React.Fragment>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item: ADVERTISMENT_ITEM) => {
                    let generalColor =
                      item.display_general === 1 ? "#3CB371" : "#EF5350";
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item.id}
                      >
                        <TableCell>
                          <img
                            src={item.image_url ? item.image_url : logoImage}
                            className={classes.imageStyle}
                            onClick={() => openImageInNewTab(item.image_url)}
                          />
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            // title={item.link}
                            arrow={true}
                            placement="top"
                            title={
                              <h2 style={{ color: "#fff" }}>{item.link}</h2>
                            }
                          >
                            <Typography className={classes.ellipsisModeStyle}>
                              <a id="link" href={item.link} target="_blank">
                                {item.link}
                              </a>
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{item.categoryName.join(", ")}</TableCell>
                        <TableCell align={"center"}>
                          {item.display_general === 1 ? (
                            <CheckCircleOutlineSharpIcon
                              style={{
                                color: generalColor,
                              }}
                            />
                          ) : (
                            <CancelSharpIcon
                              style={{
                                color: generalColor,
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell align={"center"}>
                          <Toggle
                            statusType={item.published}
                            itemId={item.id}
                            changePublishCallback={changePublishStatus}
                          />
                        </TableCell>
                        <TableCell>
                          <span
                            className="icons-spacing cursor-pointer"
                            onClick={() => editUser(item)}
                          >
                            <ManageButton
                              title={"Edit Advertisement"}
                              buttonType={"edit"}
                            />
                          </span>
                          <span
                            className="cursor-pointer"
                            onClick={() => deleteUser(item)}
                          >
                            <ManageButton
                              title={"Delete Advertisement"}
                              buttonType={"delete"}
                            />
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Grid container justify="center">
            <PaginationComponent
              currentPage={currentPage}
              totalPage={totalPage}
              setPageCallback={pageChangeHandlerCallback}
            />
          </Grid>
        </React.Fragment>
      ) : !loading ? (
        <EmptyComponent />
      ) : null}
      <Loading isFetching={loading} />
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    usersSuccessFailure: state.getUsers.usersSuccessFailure,
  };
};

const mapDispatch = {
  getAdvertisements: (pageNo: any) => getAdvertisements(pageNo),
  changeAdvertisementPublishStatus: (adsId: number, body: any) =>
    changeAdvertisementPublishStatus(adsId, body),
  deleteAdvertisement: (adsId: number) => deleteAdvertisement(adsId),
};

const connector = connect(mapStateToProps, mapDispatch);
export default connector(AdvertisementList);
