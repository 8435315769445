export const GET_EVENTS_SUCCESS_FAILURE: string = "GET_EVENTS_SUCCESS_FAILURE";
export const CHANGE_EVENT_STATUS_SUCCESS_FAILURE: string =
  "CHANGE_EVENT_STATUS_SUCCESS_FAILURE";
export const DELETE_EVENT_STATUS_SUCCESS_FAILURE: string =
  "DELETE_EVENT_STATUS_SUCCESS_FAILURE";
export const ADD_EVENT_SUCCESS_FAILURE: string = "ADD_EVENT_SUCCESS_FAILURE";
export const UPDATE_EVENT_SUCCESS_FAILURE: string =
  "UPDATE_EVENT_SUCCESS_FAILURE";
export const SINGLE_EVENT_DATA_SUCCESS_FAILURE: string =
  "SINGLE_EVENT_DATA_SUCCESS_FAILURE";
export const EVENT_CATEGORY_SUCCESS_FAILURE: string =
  "EVENT_CATEGORY_SUCCESS_FAILURE";
export const EVENT_VENUES_SUCCESS_FAILURE: string =
  "EVENT_VENUES_SUCCESS_FAILURE";

export interface InitialState {
  eventsSuccessFailure: any;
  changeEventStatusSuccessFailure: any;
  deleteEventSuccessFailure: any;
  addEventSuccessFailure: any;
  updateEventSuccessFailure: any;
  singleEventDataSuccessFailure: any;
  getEventCategorySuccessFailure: any;
  getEventVenuesSuccessFailure: any;
}
export interface Action {
  type: string;
  data: object | boolean;
}

export interface EVENT_ITEM {
  id: number;
  dates: string;
  enddates: string;
  times: string;
  endtimes: string;
  title: string;
  venue?: string;
  catname?: string;
  published: number;
}

export interface UPDATE_EVENT {
  title: string;
  startDate: any;
  endDate: any;
  startTime: any;
  endTime: any;
  description?: any;
  venueId?: any;
  categoryId: any;
  published: any;
}

export interface EventTableColumn {
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}
