export const CREATE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE: string =
  "CREATE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE";
export const EDIT_HOME_SCREEN_IMAGES_SUCCESS_FAILURE: string =
  "EDIT_HOME_SCREEN_IMAGES_SUCCESS_FAILURE";
export const HOMESCREENIMAGES_LIST_SUCCESS_FAILURE: string =
  "HOMESCREENIMAGES_LIST_SUCCESS_FAILURE";
export const DELETE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE: string =
  "DELETE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE";
export const CHANGE_HOME_SCREEN_IMAGES_PUBLISH_STATUS_SUCCESS_FAILURE: string =
  "CHANGE_HOME_SCREEN_IMAGES_PUBLISH_STATUS_SUCCESS_FAILURE";


export interface InitialState {
    homeScreenImagesPublishStatusSuccessFailure: any;
    deletehomeScreenImagesSuccessFailure: any;
    createHomeScreenImagesSuccessFailure: any;
    editHomeScreenImagesSuccessFailure: any;
  }

export interface Action {
    type: string;
    data: object | boolean;
  }

  export interface HOMESCREENIMAGES_ITEM {
    id: number;
    url?: string | undefined;
    published: number;
    email:string;
    imageurl?:string | undefined;
    updatedAt:string;
  }
  
  export interface HomeScreenImagesTableColumn {
    label: string;
    minWidth?: number;
    align?: "center";
    format?: (value: number) => string;
  }
  
  export interface HomeScreenImagesTableData {}
  
  export interface publishStatusChange {
    status: number;
  }
  
  export interface createNewHomeScreenImage {
    imageFile: any;
    imgName: string;
    published: number;
    url?: string | undefined;
  }
  
  export interface editHomeScreenImage {
    imageFile?: any;
    imgName?: string;
    published: number;
    url?: string | undefined;
  }
  
  