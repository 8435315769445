import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  STORIES_LIST_SUCCESS_FAILURE,
  DELETE_STORY_SUCCESS_FAILURE,
  REPORT_STORIES_LIST_SUCCESS_FAILURE
} from "../../types/Stories";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";



const storiesSuccessFailure = (data: any) => {
  return {
    type: STORIES_LIST_SUCCESS_FAILURE,
    data,
  };
};


const deleteStorySuccessFailure = (data: any) => {
  return {
    type: DELETE_STORY_SUCCESS_FAILURE,
    data,
  };
};

/**
 * This function will retrive list of stories
 * @param pageNo 
 */
export const getStories = (pageNo: number) => {
  console.log("Page no. =>",pageNo);
  return (dispatch: Dispatch) => {
    const url = "stories/"+pageNo;
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(storiesSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(storiesSuccessFailure(result));
        return result;
      });
  };
};




/**
 * This function is used to delete story
 * @param storyId 
 */
export const deleteStory = (storyId: number) => {
  return (dispatch: Dispatch) => {
    const url = "stories/" + storyId;
    return AxiosInstance.delete(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(deleteStorySuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(deleteStorySuccessFailure(result));
        return result;
      });
  };
};



/**
 * This function will retrive list of stories
 * @param pageNo 
 */
 export const getStoriesReports = (pageNo: number) => {
  console.log("Page no. =>",pageNo);
  return (dispatch: Dispatch) => {
    const url = "/stories/reportedStories/"+pageNo;
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(reportStoriesSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(reportStoriesSuccessFailure(result));
        return result;
      });
  };
};

const reportStoriesSuccessFailure = (data: any) => {
  return {
    type: REPORT_STORIES_LIST_SUCCESS_FAILURE,
    data,
  };
};


/**
 * This function is used to upblish or unpublish an instagram image into mobile app
 * @param adsId 
 */
 export const approveDeclineReport = (imgId: number, status: number) => {
   status = status ? 0 : 1;
   var body = {
    'status' : status
   }
  return (dispatch: Dispatch) => {
    const url = "stories/status/"+imgId+"&status="+status;
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        console.log("status ==>", response);
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(approveDeclineReportSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const error: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(approveDeclineReportSuccessFailure(error));
        return error;
      });
  };
};

const approveDeclineReportSuccessFailure = (data: any) => {
  return {
    type: REPORT_STORIES_LIST_SUCCESS_FAILURE,
    data,
  };
};