export const USER_SIGN_IN_SUCCESS_FAILURE: string =
  "USER_SIGN_IN_SUCCESS_FAILURE";

export interface InitialState {
  signInUserSuccessFailure: any;
}
export interface Action {
  type: string;
  data: object | boolean;
}

export interface Login {
  email: string;
  password: any;
}
