import {
  InitialState,
  STORIES_LIST_SUCCESS_FAILURE,
  DELETE_STORY_SUCCESS_FAILURE,
  REPORT_STORIES_LIST_SUCCESS_FAILURE,
  Action,
} from "../../types/Stories";

const initialState: InitialState = {
  reportStoriesSuccessFailure:[],
  storiesSuccessFailure:[]
  
};

const storiesReports = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case STORIES_LIST_SUCCESS_FAILURE:
      return {
        ...state,
        storiesSuccessFailure: actions.data,
      };
    case REPORT_STORIES_LIST_SUCCESS_FAILURE:
      return {
        ...state,
        reportStoriesSuccessFailure: actions.data,
      };
    case DELETE_STORY_SUCCESS_FAILURE:
      return {
        ...state,
        deleteStorySuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default storiesReports;
