export const GET_PLACES_SUCCESS_FAILURE: string = "GET_PLACES_SUCCESS_FAILURE";
export const CHANGE_PLACE_STATUS_SUCCESS_FAILURE: string =
  "CHANGE_PLACE_STATUS_SUCCESS_FAILURE";
export const DELETE_PLACE_STATUS_SUCCESS_FAILURE: string =
  "DELETE_PLACE_STATUS_SUCCESS_FAILURE";
export const VENDORS_LIST_SUCCESS_FAILURE: string =
  "VENDORS_LIST_SUCCESS_FAILURE";
export const ADD_PLACE_SUCCESS_FAILURE: string = "ADD_PLACE_SUCCESS_FAILURE";
export const UPDATE_PLACE_SUCCESS_FAILURE: string =
  "UPDATE_PLACE_SUCCESS_FAILURE";
export const SINGLE_PLACE_DATA_SUCCESS_FAILURE: string =
  "SINGLE_PLACE_DATA_SUCCESS_FAILURE";
export const PLACES_REVISIONS_SUCCESS_FAILURE: string =
  "PLACES_REVISIONS_SUCCESS_FAILURE";

export interface InitialState {
  placesSuccessFailure: any;
  changePlaceStatusSuccessFailure: any;
  deletePlaceStatusSuccessFailure: any;
  vendorsListSuccessFailure: any;
  addPlaceSuccessFailure: any;
  updatePlaceSuccessFailure: any;
  singlePlaceDataSuccessFailure: any;
}
export interface Action {
  type: string;
  data: object | boolean;
}

export interface PLACES_ITEM {
  id: number;
  title: string;
  location: string;
  phone?: string | number;
  categoryName?: [];
  state: number;
  categoryId?: [];
}

export interface PENDING_REVISION_ITEM {
  id: number;
  title: string;
  created_at: string;
  Vendoremail?: string;
  status: string;
}

export interface PlacesTableColumn {
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

export interface createPlace {
  title: string;
  imageFile?: any;
  imgName?: any;
  street: string;
  city: string;
  federal_state: string;
  postcode: any;
  county?: string;
  country?: string;
  phone?: string;
  fax?: string;
  website?: string;
  longitude?: any;
  latitude?: any;
  email?: string;
  contact_person?: string;
  hotline?: string;
  description?: any;
  outside_ocnj?: any;
  state?: any;
  categoryIds?: any;
  vendorIds?: any;
  userIp?: any;
}

export interface editPlace {
  title: string;
  imageFile?: any;
  imgName?: any;
  street: string;
  city: string;
  federal_state: string;
  postcode: any;
  county?: string;
  country?: string;
  phone?: string;
  fax?: string;
  website?: string;
  longitude?: any;
  latitude?: any;
  email?: string;
  contact_person?: string;
  hotline?: string;
  description?: any;
  outside_ocnj?: any;
  state?: any;
  categoryIds?: any;
  image?: any;
  vendorIds?: any;
  imageFlag?: any;
  userIp?: any;
}
