import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  FORGOT_PASSWORD_SUCCESS_FAILURE,
  Forgot_Password,
} from "../../types/forgotPassword";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const forgotPasswordSuccessFailure = (data: any) => {
  return {
    type: FORGOT_PASSWORD_SUCCESS_FAILURE,
    data,
  };
};

export const forgotPasswordApiCall = (body: Forgot_Password) => {
  return (dispatch: Dispatch) => {
    const url = "forgotPassword";
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(forgotPasswordSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(forgotPasswordSuccessFailure(result));
        return result;
      });
  };
};
