import axios from "axios";

//prod
export const GOOGLE_CLOUD_BASE_URL = "https://us-central1-refined-legend-301013.cloudfunctions.net/new_ocnj_dev/api/v1/";

//dev
//  export const GOOGLE_CLOUD_BASE_URL = "https://us-central1-task-progress-app-308717.cloudfunctions.net/new_ocnj_dev/api/v1/";

//local
// export const GOOGLE_CLOUD_BASE_URL = "http://localhost:5000/refined-legend-301013/us-central1/new_ocnj_dev/api/v1/";


const AxiosInstance = axios.create({
    baseURL: GOOGLE_CLOUD_BASE_URL,
    // timeout: 12 * 1000, // 12 second server timeout
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    }
})

export default AxiosInstance