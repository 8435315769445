export const icons = [
    {
        label: "None",
        value: "",
    },
    {
        label: "Partly Sunny Day",
        value: "Partly Sunny Day",
    },
    {
        label: "Clear Day",
        value: "Clear Day",
    },
    {
        label: "Rain",
        value: "Rain",
    }, {
        label: "Snow",
        value: "Snow",
    },
];


export const windDirectionList = [
    "N",
    "NNE",
    "NE",
    "ENE",
    "E",
    "ESE",
    "SE",
    "SSE",
    "S",
    "SSW",
    "SW",
    "WSW",
    "W",
    "WNW",
    "NW",
    "NNW"
];

export const nextTideList = [
    "High",
    "Low",
];