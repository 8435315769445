import React, { memo, useEffect, useState, useMemo } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { TRUMBOWYG_EVENT, Event } from "../../commonTypes/types";
import { rolesArray, routePathNames, alert } from "../../utils/constants";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import _ from "lodash";
import ShowSnackBar from "../../commonComponents/snackBar";
import Typography from "@material-ui/core/Typography";
import Loading from "../../commonComponents/loading";
import * as utils from "../../utils/index";
import ImageDropZone from "../../commonComponents/imageDropZone";
import TrumbowygTextEditor from "../../commonComponents/trumbowygTextEditor";
import Menu from "../appContent/components/menu";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { publishUnpublish, tempVendorsData } from "./constants/index";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import VenueList from "./components/venueList";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ListItem from "@material-ui/core/ListItem";
import {
  getEventCategory,
  getEventVenues,
  getEventData,
  updateEvent,
} from "./redux/actions/getEvents";
import { UPDATE_EVENT } from "./types/events";
var moment = require("moment");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
    },
    textFieldWithBottomMargin: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
      marginBottom: "16px",
    },
    btnStyle: {
      width: "50%",
      marginTop: "20px",
      fontWeight: "bold",
    },
    buttonTextStyle: {
      color: "#fff",
      fontWeight: "bold",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#fff",
      marginTop: theme.spacing(2),
    },
    imageItem: {
      order: 2,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        order: 1,
      },
    },
    contentItem: {
      order: 1,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        order: 2,
      },
    },
    btnItem: {
      width: "100%",
      order: 3,
    },
    reportText: {
      fontWeight: "bold",
    },
    dailyBeachReport: {
      backgroundColor: "#fff",
      //   margin: theme.spacing(1, "auto"),
      padding: theme.spacing(2),
    },
    weatherReportBlock: {
      backgroundColor: "#fff",
      marginTop: 60,
      padding: theme.spacing(2),
    },
    dailyBeachTitle: {
      position: "absolute",
      backgroundColor: "#5eb9df",
      color: "#fff",
      padding: 10,
      fontWeight: "bold",
      marginTop: -35,
    },
    root: {
      marginTop: 40,
    },
    dailyBeachBlock: {
      marginTop: 20,
    },
    marginFromTop: {
      marginTop: 16,
    },
    marginTop25: {
      marginTop: 25,
    },
    currentWeatherDetails: {
      backgroundColor: "#f2f5f6",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        marginTop: theme.spacing(2),
      },
    },
    textAlignCenter: {
      textAlign: "center",
    },
    verticalPadding_8: {
      paddingTop: 8,
      paddingBottom: 8,
    },
    longWordBreak: {
      wordWrap: "break-word",
    },
    headerStyle: {
      backgroundColor: "#1aa4dc",
      color: "#fff",
      marginTop: "15px",
      fontWeight: "bold",
    },
    listRoot: {
      width: "100%",
      //   maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
      maxHeight: 120,
      borderWidth: 1,
      borderColor: "#A9A9A9",
      borderStyle: "solid",
    },
  })
);

const AddEvents = (props: any) => {
  const { location } = props;
  const classes = useStyles();
  const [eventId, setEventId] = React.useState("");
  const [title, setTitle] = React.useState("");

  const [status, setStatus] = React.useState(1);
  const [description, setDescription] = React.useState("");
  const [apiFetchedDescription, setApiFetchedDescription] = React.useState("");

  const [categoriesData, setCategoriesData] = React.useState([]);
  const [selectedCategories, setSelectedCategoriesData] = React.useState("");

  const [venueData, setVenueData] = React.useState([]);
  const [selectedVenues, setSelectedVenues] = React.useState({} as any);

  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [{ title: "Events", link: routePathNames.EVENTS }];
  const actionType = props.actionType;

  useEffect(() => {
    fetchEventsCategory();
    fetchEventVenue();
    checkUrlProps();
  }, []);

  const checkUrlProps = () => {
    if (location && location.state && location.state.data) {
      let data = location.state.data;
      console.log("checkUrlProps ~ data event", data);
      fetchSingleVenueData(data.id);
    }
  };

  const fetchSingleVenueData = (id: number) => {
    setLoading(true);
    props
      .getEventData(id)
      .then((result: any) => {
        // console.log("getEventDataresultresult", result);
        let data = result.data.data[0];
        setTitle(data.title);
        setEventId(data.id);
        setDescription(data.introtext);
        setApiFetchedDescription(data.introtext);
        setSelectedCategoriesData(data.catname);
        setStatus(data && data.published === 0 ? 0 : 1);
        if (data.venue_id && data.venue) {
          let venueObject: any = {
            id: data.venue_id,
            venue: data.venue,
          };
          setSelectedVenues(venueObject);
        }
        setStartDate(utils.convertDateInYYYYMMDDFormat(data.dates));
        setEndDate(utils.convertDateInYYYYMMDDFormat(data.enddates));
        setStartTime(data.times);
        setEndTime(data.endtimes);
        console.log("getEventDataresultresult", data, result);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const fetchEventsCategory = () => {
    setLoading(true);
    props
      .getEventCategory()
      .then((result: any) => {
        console.log("resultresult", result);
        setCategoriesData(result.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const fetchEventVenue = () => {
    setLoading(true);
    props
      .getEventVenues()
      .then((result: any) => {
        console.log("resultresult", result);
        setVenueData(result.data.data);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const setDescriptionTextTrumbowygText = (data: any) => {
    setDescription(data);
  };

  const syncSelectedVenues = (data: any) => {
    console.log("syncSelectedVenues", data);
    setSelectedVenues(data);
  };

  const handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategoriesData((event.target as HTMLInputElement).value);
    console.log("handleRadio", (event.target as HTMLInputElement).value);
  };

  const listCategory = useMemo(() => {
    return (
      <>
        <ListSubheader disableSticky={false} className={classes.headerStyle}>
          Categories*
        </ListSubheader>
        <List className={classes.listRoot}>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={selectedCategories}
            onChange={handleRadio}
          >
            {categoriesData.map((item: any) => {
              return (
                <ListItem dense button>
                  <FormControlLabel
                    value={item.catname}
                    control={<Radio color="primary" />}
                    label={item.catname}
                  />
                </ListItem>
              );
            })}
          </RadioGroup>
        </List>
      </>
    );
  }, [categoriesData, selectedCategories]);

  const vendorListSection = useMemo(() => {
    return (
      <VenueList
        title={"Venue"}
        data={venueData}
        selectedData={selectedVenues}
        selectedVenueCallback={syncSelectedVenues}
      />
    );
  }, [venueData, selectedVenues]);

  const checkEmpty = () => {
    if (
      title === "" ||
      startDate === "" ||
      endDate === "" ||
      startTime === "" ||
      endTime === "" ||
      selectedCategories === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validate = () => {
    if (checkEmpty()) {
      const categoryIdName: any = categoriesData.filter(
        (obj: any) => obj.catname === selectedCategories
      );
      let body = {
        title: title,
        startDate: startDate,
        endDate: endDate,
        startTime: moment(startTime, ["hh:mm"]).format("hh:mm:ss"),
        endTime: moment(endTime, ["hh:mm"]).format("hh:mm:ss"),
        description: description,
        venueId:
          selectedVenues && selectedVenues.id ? Number(selectedVenues.id) : 0,
        categoryId:
          categoryIdName && categoryIdName.length
            ? Number(categoryIdName[0].id)
            : "",
        published: status === 0 ? 0 : 1,
      };
      console.log("validate ~ body", body);
      editEvent(body);
    } else {
      toggleSnackBar(true, alert.allStarFieldsRequired);
    }
  };

  const editEvent = (body: UPDATE_EVENT) => {
    setLoading(true);
    props
      .updateEvent(eventId, body)
      .then((result: any) => {
        setLoading(false);
        console.log("resultresult", result);
        toggleSnackBar(true, result.data.message);
        setTimeout(() => {
          props.history.goBack();
        }, 1000);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <SimpleBreadcrumbs
        breadcrumbData={breadData}
        heading={actionType === "edit" ? "Update" : "Add New"}
      />
      <form noValidate autoComplete="off">
        <Grid container direction="row" spacing={2}>
          <Grid container item md={8} xs={12}>
            <Grid item xs={12}>
              <TextField
                required
                className={classes.textField}
                id="outlined-size-small"
                label="TITLE"
                variant="outlined"
                value={title}
                onChange={(event: Event) => setTitle(event.target.value)}
              />
            </Grid>
            <Grid
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={2}
              style={{ marginTop: "8px", marginBottom: "8px" }}
            >
              <Grid item md={6} xs={12}>
                <TextField
                  color="primary"
                  onChange={(e: any) => setStartDate(e.target.value)}
                  value={startDate}
                  className={classes.textField}
                  id="start_date"
                  required
                  type="date"
                  label="START DATE"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  color="primary"
                  value={endDate}
                  onChange={(e: any) => setEndDate(e.target.value)}
                  className={classes.textField}
                  id="end_date"
                  required
                  type="date"
                  label="END DATE"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="start_time"
                  label="START TIME"
                  type="time"
                  required
                  value={startTime}
                  onChange={(e: any) => setStartTime(e.target.value)}
                  defaultValue={""}
                  className={classes.textField}
                  variant="outlined"
                  color="primary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="end_time"
                  label="END TIME"
                  value={endTime}
                  required
                  onChange={(e: any) => setEndTime(e.target.value)}
                  type="time"
                  className={classes.textField}
                  variant="outlined"
                  color="primary"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                gutterBottom
                className={classes.reportText}
              >
                DESCRIPTION
              </Typography>
              <TrumbowygTextEditor
                id={"description"}
                textData={apiFetchedDescription}
                setDataCallback={setDescriptionTextTrumbowygText}
              />
            </Grid>
          </Grid>
          <Grid container item md={4} xs={12}>
            <Grid item xs={12}>
              <Menu
                heading={"STATUS"}
                selectedValue={status}
                data={publishUnpublish}
                setValueCallback={(data: any) => setStatus(data)}
              />
              {listCategory}
              {vendorListSection}
            </Grid>
            {/* <Grid item>{listCategory}</Grid> */}
          </Grid>
          <Grid container xs={12} justify="center" className={classes.btnItem}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnStyle}
              size="large"
              onClick={validate}
            >
              <Typography className={classes.buttonTextStyle}>
                {"Save"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
      <Loading isFetching={loading} />
    </React.Fragment>
  );
};

const mapState = (state: any) => {
  return {
    categoriesSuccessFailure: state.advertisement.categoriesSuccessFailure,
  };
};

const mapDispatch = {
  getEventCategory: () => getEventCategory(),
  getEventVenues: () => getEventVenues(),
  getEventData: (id: number) => getEventData(id),
  updateEvent: (id: number, body: UPDATE_EVENT) => updateEvent(id, body),
};

const connector = connect(mapState, mapDispatch);
export default connector(React.memo(AddEvents));
