import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import brandLogo from "../../assets/ocnj-logo@3x.png";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Event } from "../../commonTypes/types";
import { Forgot_Password } from "./types/forgotPassword";
import { connect } from "react-redux";
import { forgotPasswordApiCall } from "./redux/actions/forgotPassword";
import ShowSnackBar from "../../commonComponents/snackBar";
import Loading from "../../commonComponents/loading";
import * as utils from "../../utils/index";
import { alert, routePathNames } from "../../utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 350,
    },
    textField: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
      marginBottom: "20px",
    },
    media: {
      height: 180,
      //   paddingTop: "56.25%", // 16:9
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "10px",
    },
    btnStyle: {
      width: "100%",
      fontWeight: "bold",
    },
    buttonTextStyle: {
      color: "#fff",
      fontWeight: "bold",
    },
    titleText: {
      color: "#000",
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "24px",
      fontSize: "20px",
    },
    goBackText: {
      marginTop: "20px",
      cursor: "pointer",
      "&:hover": {
        color: "#1aa4dc",
        textDecorationLine: "underline",
      },
    },
  })
);

const ForgotPassword = (props: any) => {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");

  const forgotPassword = (body: Forgot_Password) => {
    setLoading(true);
    props
      .forgotPasswordApiCall(body)
      .then((result: any) => {
        console.log("forgotPasswordApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          setEmail("");
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const checkEmpty = () => {
    if (email === "") {
      return false;
    } else {
      return true;
    }
  };

  const ValidateLoginPayload = () => {
    if (checkEmpty()) {
      if (utils.validateEmail(email)) {
        let body = {
          email: email,
        };
        forgotPassword(body);
      } else {
        toggleSnackBar(true, alert.EMAIL_VALIDATION);
      }
    } else {
      toggleSnackBar(true, alert.EMPTY_EMAIL_VALIDATION);
    }
  };

  const goBackToSignIn = () => {
    props.history.goBack();
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      //   alignItems="center"
      style={{
        marginTop: "16vh",
      }}
    >
      <Card className={classes.root}>
        <CardMedia className={classes.media}>
          <img src={brandLogo} height={100} />
        </CardMedia>
        <CardContent>
          <Typography className={classes.titleText}>
            {"Forgot Password"}
          </Typography>
          <TextField
            autoFocus
            className={classes.textField}
            id="outlined-size-small"
            label="E-mail Address"
            variant="outlined"
            size="small"
            value={email}
            onChange={(event: Event) => setEmail(event.target.value)}
          />
          <Grid container justify="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.btnStyle}
              size="large"
              onClick={ValidateLoginPayload}
            >
              <Typography className={classes.buttonTextStyle}>
                {"Send Password Reset Link"}
              </Typography>
            </Button>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.goBackText}
              onClick={goBackToSignIn}
            >
              Go Back To Login
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
      <Loading isFetching={loading} />
    </Grid>
  );
};

const mapState = (state: any) => {
  return {
    signInUserSuccessFailure: state.forgotPassword.signInUserSuccessFailure,
  };
};

const mapDispatch = {
  forgotPasswordApiCall: (body: Forgot_Password) => forgotPasswordApiCall(body),
};

const connector = connect(mapState, mapDispatch);
export default connector(ForgotPassword);
