import Tooltip from "@material-ui/core/Tooltip";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";

const ManageButton = (props: any) => {
  return (
    <Tooltip
      arrow={true}
      placement="top"
      title={<span className="tooltip-style">{props.title}</span>}
    >
      {props.buttonType === "edit" ? (
        <CreateOutlinedIcon />
      ) : (
        <DeleteOutlineRoundedIcon />
      )}
    </Tooltip>
  );
};

export default ManageButton;
