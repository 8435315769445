export const INSTAIMG_LIST_SUCCESS_FAILURE: string =
  "INSTAIMG_LIST_SUCCESS_FAILURE";
export const CHANGE_INSTAIMG_PUBLISH_STATUS_SUCCESS_FAILURE: string =
  "CHANGE_INSTAIMG_PUBLISH_STATUS_SUCCESS_FAILURE";
export const DELETE_INSTAIMG_SUCCESS_FAILURE: string =
  "DELETE_INSTAIMG_SUCCESS_FAILURE";

export interface InitialState {
  instaImgsSuccessFailure: any;
  instaImgPublishStatusSuccessFailure: any;
  deleteinstaImgSuccessFailure: any;
}
export interface Action {
  type: string;
  data: object | boolean;
}

export interface instaItems {
  id: number;
  image_url?: string | undefined;
  is_published: number;
  is_deleted: number;
}

export interface AdsTableColumn {
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

export interface AdsTableData {}

export interface publishStatusChange {
  status: number;
}

export interface createNewAdvertisement {
  imageFile: any;
  imgName: string;
  published: number;
  display_general: number;
  link: string;
  categoryIds: any;
}

export interface editAdvertisement {
  imageFlag?: boolean;
  imageFile?: any;
  imgName?: string;
  published: number;
  display_general: number;
  link: string;
  categoryIds: any;
}
