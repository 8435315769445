import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import brandLogo from "../../assets/ocnj-logo@3x.png";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Event } from "../../commonTypes/types";
import { Login } from "./types/signin";
import { connect } from "react-redux";
import { userSignIn } from "./redux/actions/signin";
import ShowSnackBar from "../../commonComponents/snackBar";
import Loading from "../../commonComponents/loading";
import * as utils from "../../utils/index";
import { alert, routePathNames } from "../../utils/constants";

//password eye
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 350,
    },
    textField: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
      marginBottom: "20px",
    },
    media: {
      height: 180,
      //   paddingTop: "56.25%", // 16:9
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "10px",
    },
    btnStyle: {
      width: "100%",
      fontWeight: "bold",
    },
    buttonTextStyle: {
      color: "#fff",
      fontWeight: "bold",
    },
    forgotText: {
      marginTop: "20px",
      cursor: "pointer",
      "&:hover": {
        color: "#1aa4dc",
        textDecorationLine: "underline",
      },
    },
  })
);

const SignIn = (props: any) => {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const loginCall = (body: Login) => {
    setLoading(true);
    props
      .userSignIn(body)
      .then((result: any) => {
        console.log("loginCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          let token = result.data.token;
          utils.saveToken(token);
          utils.fetchClientIpAddress();
          const roleType = utils.getRole(token);
          props.setRoleCallback(roleType);
          if (roleType === "Administrator") {
            props.history.push({
              pathname: routePathNames.APP_CONTENT,
            });
          } else {
            props.history.push({
              pathname: routePathNames.PLACES,
            });
          }
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const checkEmpty = () => {
    if (email === "" || password === "") {
      return false;
    } else {
      return true;
    }
  };

  const ValidateLoginPayload = () => {
    if (checkEmpty()) {
      if (utils.validateEmail(email)) {
        let body = {
          email: email,
          password: password,
        };
        loginCall(body);
      } else {
        toggleSnackBar(true, alert.EMAIL_VALIDATION);
      }
    } else {
      toggleSnackBar(true, alert.mandatoryFields);
    }
  };

  const goToForgotPassword = () => {
    props.history.push({
      pathname: routePathNames.FORGOT_PASSWORD,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      //   alignItems="center"
      style={{
        marginTop: "16vh",
      }}
    >
      <Card className={classes.root}>
        <CardMedia className={classes.media}>
          <img src={brandLogo} height={100} />
        </CardMedia>
        <CardContent>
          <TextField
            autoFocus
            className={classes.textField}
            id="outlined-size-small"
            label="E-mail Address"
            variant="outlined"
            size="small"
            value={email}
            onChange={(event: Event) => setEmail(event.target.value)}
          />
          <TextField
            className={classes.textField}
            id="outlined-size-small"
            label="Password"
            variant="outlined"
            size="small"
            value={password}
            defaultValue={password}
            onChange={(event: Event) => setPassword(event.target.value)}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Grid container justify="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.btnStyle}
              size="large"
              onClick={ValidateLoginPayload}
            >
              <Typography className={classes.buttonTextStyle}>
                {"Login"}
              </Typography>
            </Button>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.forgotText}
              onClick={goToForgotPassword}
            >
              Forgot Your Password
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
      <Loading isFetching={loading} />
    </Grid>
  );
};

const mapState = (state: any) => {
  return {
    signInUserSuccessFailure: state.userSignIn.signInUserSuccessFailure,
  };
};

const mapDispatch = {
  userSignIn: (body: Login) => userSignIn(body),
};

const connector = connect(mapState, mapDispatch);
export default connector(SignIn);
