import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  USERS_SUCCESS_FAILURE,
  AddNewUser,
  ADD_NEW_USER_SUCCESS_FAILURE,
  EditUser,
  EDIT_USER_SUCCESS_FAILURE,
  DELETE_USER_SUCCESS_FAILURE,
} from "../../types/users";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const usersSuccessFailure = (data: any) => {
  return {
    type: USERS_SUCCESS_FAILURE,
    data,
  };
};

export const getUsers = (pageNo: number) => {
  console.log("pageNo", pageNo);
  return (dispatch: Dispatch) => {
    const url = "users/" + pageNo;
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(usersSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(usersSuccessFailure(result));
        return result;
      });
  };
};

const editUserSuccessFailure = (data: any) => {
  return {
    type: EDIT_USER_SUCCESS_FAILURE,
    data,
  };
};

export const editUser = (body: EditUser, userId: any) => {
  console.log("editUser ~ body", body);
  return (dispatch: Dispatch) => {
    const url = "users/" + userId;
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(editUserSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(editUserSuccessFailure(result));
        return result;
      });
  };
};

const addUserSuccessFailure = (data: any) => {
  return {
    type: ADD_NEW_USER_SUCCESS_FAILURE,
    data,
  };
};

export const addUser = (body: AddNewUser) => {
  console.log("addUser ~ body", body);
  return (dispatch: Dispatch) => {
    const url = "users/";
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(addUserSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(addUserSuccessFailure(result));
        return result;
      });
  };
};

const deleteUserSuccessFailure = (data: any) => {
  return {
    type: DELETE_USER_SUCCESS_FAILURE,
    data,
  };
};

export const deleteUser = (userId: any) => {
  console.log("deleteUser ~ body", userId);
  return (dispatch: Dispatch) => {
    const url = "users/" + userId;
    return AxiosInstance.delete(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(deleteUserSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(deleteUserSuccessFailure(result));
        return result;
      });
  };
};
