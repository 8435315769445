import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  INSTAIMG_LIST_SUCCESS_FAILURE,
  CHANGE_INSTAIMG_PUBLISH_STATUS_SUCCESS_FAILURE,
  DELETE_INSTAIMG_SUCCESS_FAILURE,
  publishStatusChange,
} from "../../types/insta";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const instaImgsSuccessFailure = (data: any) => {
  return {
    type: INSTAIMG_LIST_SUCCESS_FAILURE,
    data,
  };
};

const instaImgPublishStatusSuccessFailure = (data: any) => {
  return {
    type: CHANGE_INSTAIMG_PUBLISH_STATUS_SUCCESS_FAILURE,
    data,
  };
};

const deleteinstaImgSuccessFailure = (data: any) => {
  return {
    type: DELETE_INSTAIMG_SUCCESS_FAILURE,
    data,
  };
};

/**
 * This function will retrive list of instagram images
 * @param pageNo
 */
export const getInstaImgs = (pageNo: number) => {
  console.log("Page no. =>", pageNo);
  return (dispatch: Dispatch) => {
    const url = "insta/fetchInstaImgs/" + pageNo;
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(instaImgsSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(instaImgsSuccessFailure(result));
        return result;
      });
  };
};

/**
 * This function is used to upblish or unpublish an instagram image into mobile app
 * @param adsId
 */
export const changeInstaImgPublishStatus = (imgId: number) => {
  return (dispatch: Dispatch) => {
    const url = "insta/status";
    var bady = {
      currentImgId: imgId,
    };
    return AxiosInstance.put(url, bady)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(instaImgPublishStatusSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const error: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(instaImgPublishStatusSuccessFailure(error));
        return error;
      });
  };
};

/**
 * This function is used to delete instagram image from tbl
 * @param adsId
 */
export const deleteInstaImg = (adsId: number) => {
  return (dispatch: Dispatch) => {
    const url = "insta/deleteImg/" + adsId;
    return AxiosInstance.delete(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(deleteinstaImgSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(deleteinstaImgSuccessFailure(result));
        return result;
      });
  };
};
