import React, { memo, useEffect, useState, useMemo } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Event } from "../../commonTypes/types";
import { rolesArray, routePathNames, alert } from "../../utils/constants";
import SimpleBreadcrumbs from "../../commonComponents/breadCrumbs";
import _ from "lodash";
import ShowSnackBar from "../../commonComponents/snackBar";
import Typography from "@material-ui/core/Typography";
import Loading from "../../commonComponents/loading";
import * as utils from "../../utils/index";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ImageDropZone from "../../commonComponents/imageDropZone";
import CategoryListComponent from "../../commonComponents/categoryList";
import {
  getCategories,
  createAdvertisement,
  editAdvertisementCall,
} from "./redux/actions/advertisement";
import {
  createNewAdvertisement,
  editAdvertisement,
} from "./types/advertisements";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    textField: {
      fontWeight: 500,
      backgroundColor: "#fff",
      width: "100%",
    },
    btnStyle: {
      width: "50%",
      marginTop: "20px",
      fontWeight: "bold",
    },
    buttonTextStyle: {
      color: "#fff",
      fontWeight: "bold",
    },
    formControl: {
      width: "100%",
      backgroundColor: "#fff",
      marginTop: theme.spacing(2),
    },
    imageItem: {
      order: 2,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        order: 1,
      },
    },
    contentItem: {
      order: 1,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        order: 2,
      },
    },
    btnItem: {
      width: "100%",
      order: 3,
    },
  })
);

const CreateAdvertisement = (props: any) => {
  const { location } = props;
  const classes = useStyles();
  const [adsId, setAdsId] = React.useState("");
  const [imageWhileUpdate, setImageWhileUpdate] = React.useState("");
  const [imageData, setImageData] = React.useState([{}]);
  const [linkTo, setLinkTo] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [displayGeneral, setDisplayGeneral] = React.useState("");
  const [categoriesData, setCategoriesData] = React.useState([]);
  const [selectedCategories, setSelectedCategoriesData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [snackBarVisisble, setSnackBarVisisble] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const breadData = [
    { title: "Advertisements", link: routePathNames.ADVERTISEMENTS },
  ];
  const actionType = props.actionType;
  let imageUrlOnUpdate: string;

  useEffect(() => {
    checkUrlProps();
  }, []);

  const checkUrlProps = () => {
    const currentUrl = props.location;
    console.log("checkUrlProps ~ data111", props, currentUrl);
    if (
      location &&
      location.state &&
      location.state.data &&
      actionType === "edit"
    ) {
      let data = location.state.data;
      console.log("checkUrlProps ~ data", data);
      setAdsId(data.id);
      setLinkTo(data.link);
      const displayGenaralValue = data.display_general === 0 ? "No" : "Yes";
      setDisplayGeneral(displayGenaralValue);
      const publishStatus = data.published === 0 ? "Unpublish" : "Publish";
      setStatus(publishStatus);
      setSelectedCategoriesData(data.categoryId);
      imageUrlOnUpdate = data.image_url;
      let imageData = [
        {
          preview: imageUrlOnUpdate,
        },
      ];
      setImageData(imageData);
      setImageWhileUpdate(data.image_url);
    }
  };

  const clearData = () => {
    setImageData([]);
    setLinkTo("");
    setStatus("");
    setDisplayGeneral("");
    setSelectedCategoriesData([]);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    let isAlreadyFetchedCategory = props.categoriesSuccessFailure;
    if (isAlreadyFetchedCategory && isAlreadyFetchedCategory.isSuccess) {
      setCategoriesData(isAlreadyFetchedCategory.data.category);
    } else {
      setLoading(true);
      props
        .getCategories()
        .then((result: any) => {
          console.log("resultresult", result, props.categoriesSuccessFailure);
          setCategoriesData(result.data.category);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }
  };

  const addAdvertisementApiCall = (body: createNewAdvertisement) => {
    setLoading(true);
    props
      .createAdvertisement(body)
      .then((result: any) => {
        console.log("addAdvertisementApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          clearData();
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const updateAdvertisementApiCall = (body: editAdvertisement) => {
    setLoading(true);
    props
      .editAdvertisementCall(adsId, body)
      .then((result: any) => {
        console.log("updateAdvertisementApiCall", result);
        setLoading(false);
        if (result && result.isSuccess) {
          toggleSnackBar(true, result.data.message);
          setTimeout(() => {
            clearData();
            props.history.goBack();
          }, 1000);
        } else {
          toggleSnackBar(true, result.message);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const checkEmpty = () => {
    if (linkTo === "" || status === "" || displayGeneral === "") {
      return false;
    } else {
      return true;
    }
  };

  const validateAndGeneratePayoad = () => {
    console.log("validateAndGeneratePayoad", status, displayGeneral);
    if (checkEmpty()) {
      if (selectedCategories && selectedCategories.length) {
        if (imageData && imageData.length) {
          let data: any = imageData[0];
          let baseData = data && data.base64 ? data.base64.split(",")[1] : "";
          let body: any = {
            imageFile: baseData,
            imgName: data && data.name ? data.name : "",
            published: status === "Publish" ? 1 : 0,
            display_general: displayGeneral === "Yes" ? 1 : 0,
            link: linkTo,
            categoryIds: selectedCategories,
            imageFlag: "",
          };
          if (actionType === "add") {
            delete body.imageFlag;
            console.log("body add", body);
            addAdvertisementApiCall(body);
          } else {
            let imagePreview = data && data.preview ? data.preview : "";
            let isImageChange =
              imageWhileUpdate === imagePreview ? false : true;
            if (!isImageChange) {
              delete body.imageFile;
              delete body.imgName;
            }
            body.imageFlag = isImageChange;
            console.log("body edit", body);
            updateAdvertisementApiCall(body);
          }
        } else {
          toggleSnackBar(true, alert.upload);
        }
      } else {
        toggleSnackBar(true, alert.selectCategory);
      }
    } else {
      toggleSnackBar(true, alert.mandatoryFields);
    }
  };

  const toggleSnackBar = (isVisible = false, message = "") => {
    setSnackBarVisisble(isVisible);
    setSnackBarMessage(message);
  };

  const handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };

  const handleDisplayChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setDisplayGeneral(event.target.value as string);
  };

  const selectedCatgoriesSync = (data: any) => {
    console.log("data1212", data);
    setSelectedCategoriesData(data);
  };

  const syncImageData = (data: any) => {
    console.log("CreateAdvertisement ~ data", data, imageData);
    setImageData(data);
  };

  const listCategory = useMemo(() => {
    return (
      <CategoryListComponent
        data={categoriesData}
        selectedData={selectedCategories}
        selectedCatgoriesCallback={selectedCatgoriesSync}
      />
    );
  }, [categoriesData, selectedCategories]);

  return (
    <React.Fragment>
      <SimpleBreadcrumbs
        breadcrumbData={breadData}
        heading={actionType === "add" ? "Create New" : "Update"}
      />
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item md={3} sm={12} className={classes.imageItem}>
            <ImageDropZone
              placeHolderText={
                "Drag 'n' drop single image file here, or click here to select the file."
              }
              height={"350px"}
              width={"100%"}
              imageData={imageData}
              syncImageDataCallback={syncImageData}
            />
          </Grid>
          <Grid item md={9} sm={12} className={classes.contentItem}>
            <TextField
              className={classes.textField}
              id="outlined-size-small"
              label="Link To"
              variant="outlined"
              value={linkTo}
              onChange={(event: Event) => setLinkTo(event.target.value)}
            />
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Status
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={status}
                onChange={handleStatusChange}
                label="Status"
              >
                <MenuItem value={"Publish"}>Publish</MenuItem>
                <MenuItem value={"Unpublish"}>Unpublish</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Display General
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={displayGeneral}
                onChange={handleDisplayChange}
                label="Display General"
              >
                <MenuItem value={"Yes"}>Yes</MenuItem>
                <MenuItem value={"No"}>No</MenuItem>
              </Select>
            </FormControl>
            <Grid item>{listCategory}</Grid>
          </Grid>
          <Grid container xs={12} justify="center" className={classes.btnItem}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnStyle}
              size="large"
              onClick={validateAndGeneratePayoad}
            >
              <Typography className={classes.buttonTextStyle}>
                {"Save"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
      <ShowSnackBar
        isVisible={snackBarVisisble}
        message={snackBarMessage}
        handleClose={() => toggleSnackBar()}
      />
      <Loading isFetching={loading} />
    </React.Fragment>
  );
};

const mapState = (state: any) => {
  return {
    categoriesSuccessFailure: state.advertisement.categoriesSuccessFailure,
  };
};

const mapDispatch = {
  getCategories: () => getCategories(),
  createAdvertisement: (body: createNewAdvertisement) =>
    createAdvertisement(body),
  editAdvertisementCall: (adsId: number, body: editAdvertisement) =>
    editAdvertisementCall(adsId, body),
};

const connector = connect(mapState, mapDispatch);
export default connector(React.memo(CreateAdvertisement));
