export const FORGOT_PASSWORD_SUCCESS_FAILURE: string =
  "FORGOT_PASSWORD_SUCCESS_FAILURE";

export interface InitialState {
  forgotPasswordSuccessFailure: any;
}
export interface Action {
  type: string;
  data: object | boolean;
}

export interface Forgot_Password {
  email: string;
}
