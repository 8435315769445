import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import logoImg from "../../assets/logo-horizontal@2x.png";
import {
  adminRoutes,
  routePathNames,
  vendorRoute,
} from "../../utils/constants";
import MenuListItem from "./components/menuListItem";
import "./css/sideBar.css";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import CreateUser from "../../modules/users/createUser";
import {
  BrowserRouter as Redirect,
  Router,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import Users from "../../modules/users/users";
import * as utils from "../../utils/index";
import CreateAdvertisement from "../advertisements/createAdd";
import AdvertisementList from "../advertisements/advertisementList";
import CreateNotification from "../notifications/createNotification";
import Notifications from "../notifications/notifications";
import Home from "../appContent/home";
import AddPlaces from "../places/addPlaces";
import PlacesList from "../places/placesList";
import AddEvents from "../events/addEvents";
import PendingRevision from "../places/pendingRevision";
import EventList from "../events/eventsList";
import _ from "lodash";
import InstagramImagesList from "../instagram/instagramImagesList";
import UserStories from "../stories/storiesList";
import ReportedStories from "../stories/reportStoriesList";
import CreateHomeScreenImages from "../HomeScreenImages/addImage";
import HomeScreenImagesList from "../HomeScreenImages/homescreenimagesList";

const drawerWidth = 220;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    userNameStyle: {
      color: "#fff",
      alignSelf: "center",
      right: 0,
      position: "absolute",
      paddingRight: "24px",
      fontWeight: "bold",
      fontSize: "16px",
      visibility: "visible",
      [theme.breakpoints.down("xs")]: {
        visibility: "hidden",
      },
    },
  })
);

const SideBar = (props: any) => {
  console.log("SideBar ~ props", props);
  const userDetails = utils.getLoginDetails();
  const userName =
    userDetails && userDetails.name ? _.upperFirst(userDetails.name) : "";
  console.log("userDetails", userDetails);
  const [currentActivePage, setCurrentActivePage] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  const classes = useStyles();
  const {
    container,
    location: { pathname },
  } = props;

  const onSelectCallback = (name: string) => {
    console.log("name ===>", name);
    setCurrentActivePage(name);
  };

  const toggleCallback = (name: string) => {
    if (name === currentActivePage) {
      setToggle(!toggle);
    } else {
      setToggle(true);
    }
  };

  const logout = () => {
    utils.logout();
    props.history.push(routePathNames.SIGNIN);
  };

  const renderList = () => {
    let list =
      props.currentRole === "Administrator" ? adminRoutes : vendorRoute;
    return (
      <React.Fragment>
        {list.map((item, index) => {
          return (
            <MenuListItem
              key={"MenuListItem" + index}
              data={item}
              pathname={pathname}
              currentActivePage={currentActivePage}
              toggle={toggle}
              toggleCallback={toggleCallback}
              onSelectCallback={onSelectCallback}
            />
          );
        })}
        <div className="logout-btn" onClick={() => logout()}>
          Logout
        </div>
      </React.Fragment>
    );
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  type Anchor = "top" | "left" | "bottom" | "right";

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="primary">
        <Toolbar>
          <React.Fragment>
            <MenuRoundedIcon
              className="show-mobile-drawer"
              style={{ marginTop: "1px", marginRight: "5px", color: "#fff" }}
              onClick={toggleDrawer("left", true)}
            />
            <img style={{ marginTop: "6px" }} src={logoImg} height={30} />
          </React.Fragment>
          <span className={classes.userNameStyle}>
            {"Welcome, " + userName}
          </span>
        </Toolbar>
      </AppBar>
      <Drawer
        className={`${classes.drawer} hide-big-drawer`}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>{renderList()}</List>
        </div>
      </Drawer>
      {/* Mini drawer for mobile */}
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <List>
          <Toolbar />
          {renderList()}
        </List>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {props && props.currentRole === "Administrator" ? (
          <Switch>
            <Route
              path={routePathNames.APP_CONTENT}
              render={(routeProps) => <Home {...routeProps} {...props} />}
            />
            {/* Users Routes */}
            {[
              { path: routePathNames.CREATE_USER, type: "add" },
              { path: routePathNames.EDIT_USER, type: "edit" },
            ].map((data, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={data.path}
                  render={(routeProps) => (
                    <CreateUser actionType={data.type} {...routeProps} />
                  )}
                />
              );
            })}
            <Route
              path={routePathNames.USERS}
              render={(routeProps) => <Users {...routeProps} />}
            />
            {/* Advertisement Routes */}
            {[
              { path: routePathNames.CREATE_ADVERTISEMENT, type: "add" },
              { path: routePathNames.EDIT_ADVERTISEMENT, type: "edit" },
            ].map((data, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={data.path}
                  render={(routeProps) => (
                    <CreateAdvertisement
                      actionType={data.type}
                      {...routeProps}
                    />
                  )}
                />
              );
            })}
            <Route
              path={routePathNames.ADVERTISEMENTS}
              render={(routeProps) => <AdvertisementList {...routeProps} />}
            />
            {/*Home Screen Images */}

            {[
              { path: routePathNames.CREATE_HOMESCREEN_IMAGES, type: "add" },
              { path: routePathNames.EDIT_HOMESCREEN_IMAGES, type: "edit" },
            ].map((data, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={data.path}
                  render={(routeProps) => (
                    <CreateHomeScreenImages
                      actionType={data.type}
                      {...routeProps}
                    />
                  )}
                />
              );
            })}
            <Route
              path={routePathNames.HOME_SCREEN_IMAGES}
              render={(routeProps) => <HomeScreenImagesList{...routeProps} />}
            />



            {/* Notification Routes */}
            <Route
              path={routePathNames.CREATE_NOTIFICATION}
              render={(routeProps) => <CreateNotification {...routeProps} />}
            />
            <Route
              path={routePathNames.NOTIFICATIONS}
              render={(routeProps) => <Notifications {...routeProps} />}
            />
            {/* Places Routes */}
            {[
              { path: routePathNames.ADD_PLACES, type: "add" },
              { path: routePathNames.EDIT_PLACES, type: "edit" },
              { path: routePathNames.ADMIN_EDIT_REVISION, type: "revision" },
            ].map((data, index) => {
              return (
                <Route
                  key={index}
                  exact
                  path={data.path}
                  render={(routeProps) => (
                    <AddPlaces actionType={data.type} {...routeProps} />
                  )}
                />
              );
            })}
            <Route
              exact
              path={routePathNames.PLACES}
              render={(routeProps) => <PlacesList {...routeProps} />}
            />
            <Route
              exact
              path={routePathNames.PENDING_REVISION}
              render={(routeProps) => <PendingRevision {...routeProps} />}
            />
            {/* Events Routes */}
            {[{ path: routePathNames.EDIT_EVENT, type: "edit" }].map(
              (data, index) => {
                return (
                  <Route
                    key={index}
                    exact
                    path={data.path}
                    render={(routeProps) => (
                      <AddEvents actionType={data.type} {...routeProps} />
                    )}
                  />
                );
              }
            )}
            <Route
              path={routePathNames.EVENTS}
              render={(routeProps) => <EventList {...routeProps} />}
            />
            {/* Instagram Routes */}
            <Route
              path={routePathNames.INSTAGRAM}
              render={(routeProps) => <InstagramImagesList {...routeProps} />}
            />
            <Route
              path={routePathNames.USER_STORIES}
              render={(routeProps) => <UserStories {...routeProps} />}
            />

            <Route
              path={routePathNames.REPORTED_STORIES}
              render={(routeProps) => <ReportedStories {...routeProps} />}
            />
          </Switch>
        ) : (
          <Switch>
            <Route
              exact
              path={routePathNames.PLACES}
              render={(routeProps) => <PlacesList {...routeProps} />}
            />
            <Route
              exact
              path={routePathNames.VENDOR_EDIT_PLACES}
              render={(routeProps) => (
                <AddPlaces actionType={"vendorEdit"} {...routeProps} />
              )}
            />
          </Switch>
        )}
      </main>
    </div>
  );
};

export default SideBar;
