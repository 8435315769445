import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  RESET_PASSWORD_SUCCESS_FAILURE,
  Reset_Password,
} from "../../types/resetPassword";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const resetPasswordSuccessFailure = (data: any) => {
  return {
    type: RESET_PASSWORD_SUCCESS_FAILURE,
    data,
  };
};

export const resetPasswordApiCall = (body: Reset_Password) => {
  return (dispatch: Dispatch) => {
    const url = "resetPassword";
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(resetPasswordSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(resetPasswordSuccessFailure(result));
        return result;
      });
  };
};
