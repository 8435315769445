import AxiosInstance from "../../../../api/axios";
import axios from "axios";
import { Dispatch } from "redux";
import {
  GET_PLACES_SUCCESS_FAILURE,
  CHANGE_PLACE_STATUS_SUCCESS_FAILURE,
  DELETE_PLACE_STATUS_SUCCESS_FAILURE,
  VENDORS_LIST_SUCCESS_FAILURE,
  ADD_PLACE_SUCCESS_FAILURE,
  UPDATE_PLACE_SUCCESS_FAILURE,
  SINGLE_PLACE_DATA_SUCCESS_FAILURE,
  createPlace,
} from "../../types/places";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const placesSuccessFailure = (data: any) => {
  return {
    type: GET_PLACES_SUCCESS_FAILURE,
    data,
  };
};

const changePlaceStatusSuccessFailure = (data: any) => {
  return {
    type: CHANGE_PLACE_STATUS_SUCCESS_FAILURE,
    data,
  };
};

const deletePlaceStatusSuccessFailure = (data: any) => {
  return {
    type: DELETE_PLACE_STATUS_SUCCESS_FAILURE,
    data,
  };
};

const vendorsListSuccessFailure = (data: any) => {
  return {
    type: VENDORS_LIST_SUCCESS_FAILURE,
    data,
  };
};

const addPlaceSuccessFailure = (data: any) => {
  return {
    type: ADD_PLACE_SUCCESS_FAILURE,
    data,
  };
};

const updatePlaceSuccessFailure = (data: any) => {
  return {
    type: UPDATE_PLACE_SUCCESS_FAILURE,
    data,
  };
};

const singlePlaceDataSuccessFailure = (data: any) => {
  return {
    type: SINGLE_PLACE_DATA_SUCCESS_FAILURE,
    data,
  };
};

export const getPlaces = (pageNo: number, body: any) => {
  console.log("getPlacesgetPlaces", pageNo, body);
  return (dispatch: Dispatch) => {
    const url = "places/" + pageNo;
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(placesSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(placesSuccessFailure(result));
        return result;
      });
  };
};

export const changePlaceStatus = (id: number, body: any) => {
  console.log("getPlacesgetPlaces", id, body);
  return (dispatch: Dispatch) => {
    const url = "places/state/" + id;
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(changePlaceStatusSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(changePlaceStatusSuccessFailure(result));
        return result;
      });
  };
};

export const deletePlace = (id: number) => {
  console.log("getPlacesgetPlaces", id);
  return (dispatch: Dispatch) => {
    const url = "places/" + id;
    return AxiosInstance.delete(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(deletePlaceStatusSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(deletePlaceStatusSuccessFailure(result));
        return result;
      });
  };
};

export const getVendorsList = () => {
  return (dispatch: Dispatch) => {
    const url = "places/vendors";
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(vendorsListSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(vendorsListSuccessFailure(result));
        return result;
      });
  };
};

export const addPlace = (body: createPlace) => {
  return (dispatch: Dispatch) => {
    const url = "places";
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(addPlaceSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(addPlaceSuccessFailure(result));
        return result;
      });
  };
};

export const updatePlace = (id: number, body: any) => {
  return (dispatch: Dispatch) => {
    const url = "places/" + id;
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(updatePlaceSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(updatePlaceSuccessFailure(result));
        return result;
      });
  };
};

export const getSinglePlace = (id: number) => {
  console.log("getSinglePlace ~ id", id);
  return (dispatch: Dispatch) => {
    const url = "places/singlePlace/" + id;
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(singlePlaceDataSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(singlePlaceDataSuccessFailure(result));
        return result;
      });
  };
};

//fetch the client ip address  from third party library
export const getClientIpAddress = () => {
  return (dispatch: Dispatch) => {
    const url = "https://api.ipify.org?format=json";
    return axios
      .get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        return result;
      });
  };
};
