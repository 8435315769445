import React from "react";
import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const EmptyComponent = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "70vh" }}
    >
      <Typography>{"No Data Found"}</Typography>
    </Grid>
  );
};

export default EmptyComponent;
