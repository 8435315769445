export const RESET_PASSWORD_SUCCESS_FAILURE: string =
  "RESET_PASSWORD_SUCCESS_FAILURE";

export interface InitialState {
  resetPasswordSuccessFailure: any;
}
export interface Action {
  type: string;
  data: object | boolean;
}

export interface Reset_Password {
  password: string;
  token: any;
}
