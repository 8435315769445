import {
  USER_SIGN_IN_SUCCESS_FAILURE,
  InitialState,
  Action,
} from "../../types/signin";

const initialState: InitialState = {
  signInUserSuccessFailure: [],
};
const userSignIn = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case USER_SIGN_IN_SUCCESS_FAILURE:
      return {
        ...state,
        signInUserSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default userSignIn;
