export const Colors = {
    lightBlue: '#86d2ea',
    midBlue: '#6cc7e9',
    darkBlue: '#5eb9df',
    brightBlue: '#1aa4dc',
    navyBlue: '#123068',
    orange: '#fbbd58',
    orangeDark: '#ee9f20',
    grey: '#6d6d6d',
    txt: '#6d6d6d',
    red: '#EF5350',
    white: '#ffffff',
    green: '#3CB371',
    lightGrey: '#D3D3D3',
    black: '#000'
};