// import React, { useMemo } from "react";
// import { useDropzone } from "react-dropzone";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImageDropZoneType } from "../commonTypes/types";
import _ from "lodash";

function ImageDropZone(props: ImageDropZoneType) {
  const [files, setFiles] = useState(props.imageData);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles: any) => {
      if (acceptedFiles && acceptedFiles.length === 1) {
        console.log("acceptedFiles", acceptedFiles);
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = (event: any) => {
          setFiles(
            acceptedFiles.map((file: any) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
                base64: event.target.result,
              })
            )
          );
        };
      }
      // setFiles(
      //   acceptedFiles.map((file: any) =>
      //     Object.assign(file, {
      //       preview: URL.createObjectURL(file),
      //     })
      //   )
      // );
    },
  });

  useEffect(() => {
    setFiles(props.imageData);
  }, [props.imageData]);

  const baseStyle = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px",
    borderWidth: 2,
    borderRadius: 2,
    color: "#606060",
    borderColor: "#A9A9A9",
    borderStyle: "dashed",
    backgroundColor: "#fff",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: props.height,
    width: props.width,
    // marginTop: "15px",
    cursor: "pointer",
  };

  const thumbs = files.map((file: any) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    // files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    props.syncImageDataCallback(files);
    console.log("files", files);
  }, [files]);

  const removeImage = (event: any) => {
    event.stopPropagation();
    setFiles([]);
  };

  return (
    <section className="container">
      <div {...getRootProps({ style: baseStyle })}>
        <input {...getInputProps()} />
        {files && files.length && !_.isEmpty(files[0]) ? (
          <aside style={thumbsContainer}>
            {thumbs}
            <div
              style={{
                position: "absolute",
                paddingTop: "135px",
                color: "#EF5350",
              }}
            >
              <span
                title={"remove image file"}
                onClick={(event) => removeImage(event)}
              >
                Remove
              </span>
            </div>
          </aside>
        ) : (
          <p style={{ textAlign: "center" }}>{props.placeHolderText}</p>
        )}
      </div>
    </section>
  );
}

export default ImageDropZone;

const thumbsContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  width: 120,
  height: 120,
  padding: 4,
  marginTop: "-10px",
  //   boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};
