import {
  InitialState,
  GET_CATEGORIES_SUCCESS_FAILURE,
  ADVERTISEMENT_LIST_SUCCESS_FAILURE,
  Action,
  CHANGE_ADVERTISEMENT_PUBLISH_STATUS_SUCCESS_FAILURE,
  DELETE_ADVERTISEMENT_SUCCESS_FAILURE,
  CREATE_ADVERTISEMENT_SUCCESS_FAILURE,
  EDIT_ADVERTISEMENT_SUCCESS_FAILURE,
} from "../../types/advertisements";

const initialState: InitialState = {
  categoriesSuccessFailure: [],
  advertisementsSuccessFailure: [],
  advertisementPublishStatusSuccessFailure: [],
  deleteAdvertisementSuccessFailure: [],
  createAdvertisementSuccessFailure: [],
  editAdvertisementSuccessFailure: [],
};

const getCategories = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case GET_CATEGORIES_SUCCESS_FAILURE:
      return {
        ...state,
        categoriesSuccessFailure: actions.data,
      };
    case ADVERTISEMENT_LIST_SUCCESS_FAILURE:
      return {
        ...state,
        advertisementsSuccessFailure: actions.data,
      };
    case CHANGE_ADVERTISEMENT_PUBLISH_STATUS_SUCCESS_FAILURE:
      return {
        ...state,
        advertisementPublishStatusSuccessFailure: actions.data,
      };
    case DELETE_ADVERTISEMENT_SUCCESS_FAILURE:
      return {
        ...state,
        deleteAdvertisementSuccessFailure: actions.data,
      };
    case CREATE_ADVERTISEMENT_SUCCESS_FAILURE:
      return {
        ...state,
        createAdvertisementSuccessFailure: actions.data,
      };
    case EDIT_ADVERTISEMENT_SUCCESS_FAILURE:
      return {
        ...state,
        editAdvertisementSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default getCategories;
