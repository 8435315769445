import {
  FORGOT_PASSWORD_SUCCESS_FAILURE,
  InitialState,
  Action,
} from "../../types/forgotPassword";

const initialState: InitialState = {
  forgotPasswordSuccessFailure: [],
};
const forgotPassword = (state = initialState, actions: Action) => {
  switch (actions.type) {
    case FORGOT_PASSWORD_SUCCESS_FAILURE:
      return {
        ...state,
        forgotPasswordSuccessFailure: actions.data,
      };
    default:
      return state;
  }
};
export default forgotPassword;
