import {
    InitialState,
    Action,
    CREATE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE,
    EDIT_HOME_SCREEN_IMAGES_SUCCESS_FAILURE,
    CHANGE_HOME_SCREEN_IMAGES_PUBLISH_STATUS_SUCCESS_FAILURE,
    DELETE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE,


  } from "../../types/homescreenimages";
  
  const initialState: InitialState = {
    createHomeScreenImagesSuccessFailure: [],
    editHomeScreenImagesSuccessFailure: [],
    homeScreenImagesPublishStatusSuccessFailure:[],
    deletehomeScreenImagesSuccessFailure:[],
  };
  
  const getCategories = (state = initialState, actions: Action) => {
    switch (actions.type) {
    
      case CREATE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE:
        return {
          ...state,
          createHomeScreenImagesSuccessFailure: actions.data,
        };
      case EDIT_HOME_SCREEN_IMAGES_SUCCESS_FAILURE:
        return {
          ...state,
          editHomeScreenImagesSuccessFailure: actions.data,
        };
      case CHANGE_HOME_SCREEN_IMAGES_PUBLISH_STATUS_SUCCESS_FAILURE:
        return {
        ...state,
        homeScreenImagesPublishStatusSuccessFailure : actions.data,
      };
      case DELETE_HOME_SCREEN_IMAGES_SUCCESS_FAILURE:
      return {
        ...state,
        deletehomeScreenImagesSuccessFailure: actions.data,
      };


      default:
        return state;
    }
  };
  export default getCategories;
  