import AxiosInstance from "../../../../api/axios";
import { Dispatch } from "redux";
import {
  GET_EVENTS_SUCCESS_FAILURE,
  CHANGE_EVENT_STATUS_SUCCESS_FAILURE,
  DELETE_EVENT_STATUS_SUCCESS_FAILURE,
  ADD_EVENT_SUCCESS_FAILURE,
  UPDATE_EVENT_SUCCESS_FAILURE,
  SINGLE_EVENT_DATA_SUCCESS_FAILURE,
  EVENT_CATEGORY_SUCCESS_FAILURE,
  EVENT_VENUES_SUCCESS_FAILURE,
  UPDATE_EVENT,
} from "../../types/events";
import { AxiosResponse, AxiosError } from "../../../../commonTypes/types";

const eventsSuccessFailure = (data: any) => {
  return {
    type: GET_EVENTS_SUCCESS_FAILURE,
    data,
  };
};

const changeEventStatusSuccessFailure = (data: any) => {
  return {
    type: CHANGE_EVENT_STATUS_SUCCESS_FAILURE,
    data,
  };
};

const deleteEventSuccessFailure = (data: any) => {
  return {
    type: DELETE_EVENT_STATUS_SUCCESS_FAILURE,
    data,
  };
};

const addEventSuccessFailure = (data: any) => {
  return {
    type: ADD_EVENT_SUCCESS_FAILURE,
    data,
  };
};

const updateEventSuccessFailure = (data: any) => {
  return {
    type: UPDATE_EVENT_SUCCESS_FAILURE,
    data,
  };
};

const singleEventDataSuccessFailure = (data: any) => {
  return {
    type: SINGLE_EVENT_DATA_SUCCESS_FAILURE,
    data,
  };
};

const getEventCategorySuccessFailure = (data: any) => {
  return {
    type: EVENT_CATEGORY_SUCCESS_FAILURE,
    data,
  };
};

const getEventVenuesSuccessFailure = (data: any) => {
  return {
    type: EVENT_VENUES_SUCCESS_FAILURE,
    data,
  };
};

export const getEvents = (pageNo: number, body: any) => {
  console.log("getPlacesgetPlaces", pageNo, body);
  return (dispatch: Dispatch) => {
    const url = "event/" + pageNo;
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(eventsSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(eventsSuccessFailure(result));
        return result;
      });
  };
};

export const changeEventStatus = (id: number, body: any) => {
  console.log("changeEventStatus", id, body);
  return (dispatch: Dispatch) => {
    const url = "event/status/" + id;
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(changeEventStatusSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(changeEventStatusSuccessFailure(result));
        return result;
      });
  };
};

export const deleteEvent = (id: number) => {
  console.log("deleteEvent", id);
  return (dispatch: Dispatch) => {
    const url = "event/" + id;
    return AxiosInstance.delete(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(deleteEventSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(deleteEventSuccessFailure(result));
        return result;
      });
  };
};

export const addEvent = (body: any) => {
  return (dispatch: Dispatch) => {
    const url = "places/";
    return AxiosInstance.post(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(addEventSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(addEventSuccessFailure(result));
        return result;
      });
  };
};

export const updateEvent = (id: number, body: UPDATE_EVENT) => {
  console.log("updateEvent ~ body", body);
  return (dispatch: Dispatch) => {
    const url = "event/" + id;
    return AxiosInstance.put(url, body)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(updateEventSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(updateEventSuccessFailure(result));
        return result;
      });
  };
};

export const getEventData = (id: number) => {
  return (dispatch: Dispatch) => {
    const url = "event/singleEvent/" + id;
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(singleEventDataSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(singleEventDataSuccessFailure(result));
        return result;
      });
  };
};

export const getEventCategory = () => {
  return (dispatch: Dispatch) => {
    const url = "event/categories";
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(getEventCategorySuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(getEventCategorySuccessFailure(result));
        return result;
      });
  };
};

export const getEventVenues = () => {
  return (dispatch: Dispatch) => {
    const url = "event/venues";
    return AxiosInstance.get(url)
      .then((response: any) => {
        const result: AxiosResponse = {
          isSuccess: true,
          data: response.data,
        };
        dispatch(getEventVenuesSuccessFailure(result));
        return result;
      })
      .catch((err: any) => {
        const result: AxiosError = {
          isSuccess: false,
          message: err.response.data.message,
        };
        dispatch(getEventVenuesSuccessFailure(result));
        return result;
      });
  };
};
