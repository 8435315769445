import React, { useCallback, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import ListSubheader from "@material-ui/core/ListSubheader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      //   maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      overflow: "auto",
      maxHeight: (props: any) => (props && props.height ? props.height : 210),
      borderWidth: 1,
      borderColor: "#A9A9A9",
      borderStyle: "solid",
    },
    headerStyle: {
      backgroundColor: "#1aa4dc",
      color: "#fff",
      marginTop: "15px",
      fontWeight: "bold",
    },
  })
);

const CategoryListComponent = (props: any) => {
  console.log("CategoryListComponentprops", props);
  const classes = useStyles(props);
  const [checked, setChecked] = React.useState(props.selectedData);

  const handleToggle = (value: any) => () => {
    console.log("handleToggle ~ value", value);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    console.log("newChecked", newChecked);
    setChecked(newChecked);
  };

  useEffect(() => {
    props.selectedCatgoriesCallback(checked);
  }, [checked]);

  useEffect(() => {
    setChecked(props.selectedData);
  }, [props.selectedData]);

  const checkListItem = (item: any) => {
    return (
      <ListItem
        key={item.id}
        role={undefined}
        dense
        button
        onClick={handleToggle(item.id)}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked.indexOf(item.id) !== -1}
            tabIndex={-1}
            disableRipple
            color="primary"
            inputProps={{ "aria-labelledby": item.id }}
          />
        </ListItemIcon>
        <ListItemText id={item.id} primary={item.name} />
      </ListItem>
    );
  };

  return (
    <>
      <ListSubheader disableSticky={false} className={classes.headerStyle}>
        Categories
      </ListSubheader>
      <List className={classes.root}>
        {props.data.map((item: any) => {
          let length = item && item.childs && item.childs.length ? true : false;
          let subItemsList = item.childs;
          if (length) {
            return (
              <>
                {checkListItem(item)}
                <div style={{ paddingLeft: "55px" }}>
                  {subItemsList.map((subItem: any) => {
                    return checkListItem(subItem);
                  })}
                </div>
              </>
            );
          } else {
            return checkListItem(item);
          }
        })}
      </List>
    </>
  );
};

export default React.memo(CategoryListComponent);
