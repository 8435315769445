export interface Roles {
  name: any;
  id: any;
}

export const REQUEST_USERS: string = "REQUEST_USERS";
export const USERS_SUCCESS_FAILURE: string = "USERS_SUCCESS_FAILURE";
export const ADD_NEW_USER_SUCCESS_FAILURE: string =
  "ADD_NEW_USER_SUCCESS_FAILURE";
export const EDIT_USER_SUCCESS_FAILURE: string = "EDIT_USER_SUCCESS_FAILURE";
export const DELETE_USER_SUCCESS_FAILURE: string =
  "DELETE_USER_SUCCESS_FAILURE";

export interface InitialState {
  isFetching: boolean;
  usersSuccessFailure: any;
  addUserSuccessFailure: any;
  editUserSuccessFailure: any;
  deleteUserSuccessFailure: any;
}
export interface Action {
  type: string;
  data: object | boolean;
}

export interface UserTableColumn {
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

export interface UserTableData {
  id: any;
  name: string;
  email: string;
  role: any;
}

export interface AddNewUser {
  name: string;
  email: string;
  password: any;
  role: any;
}

export interface EditUser {
  name: string;
  email: string;
  password?: any;
  role: any;
}
