import Loader from "react-loader-spinner";
import React from "react";
import { Colors } from "../utils/colors";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loading = (props) => {
  return (
    <div style={styles.centerLoading}>
      <Loader
        type="Oval"
        color={Colors.black}
        height={44}
        width={44}
        visible={props.isFetching ? props.isFetching : false}
      />
    </div>
  );
};

export default Loading;

const styles = {
  centerLoading: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overlay: "rgba(0, 0, 0, 0.4)",
    zIndex: 1000,
  },
};
