import React, { Component } from "react";
import {
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  withStyles,
  Divider,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { withRouter } from "react-router-dom";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      background: "#A9A9A9",
    },
    listItemText: {
      fontFamily: "Montserrat !important",
      fontWeight: "lighter",
    },
    listItemBoldText: {
      fontFamily: "Montserrat !important",
      fontWeight: "bold",
    },
    childListItemText: {
      fontFamily: "Montserrat !important",
      fontWeight: "lighter",
      paddingLeft: "12px",
    },
    childListItemTextBold: {
      fontFamily: "Montserrat !important",
      fontWeight: "bold",
      paddingLeft: "12px",
    },
  })
);

const MenuListItem = (props: any) => {
  const classes = useStyles();

  const handleClick = (item: any) => {
    props.onSelectCallback(item.name);
    props.toggleCallback(item.name);
    if (item && item.path) {
      window.scrollTo(0, 0);
      props.history.push({
        pathname: item.path,
      });
    }
  };

  const handleClickLink = (path: any) => {
    window.scrollTo(0, 0);
    props.history.push({
      pathname: path[0],
    });
  };

  const handleHeading = () => {
    let routeName = props.location;
    let pathname = routeName.pathname;
    let temp = pathname.split("/");
    let name = temp[2];
    console.log("handleHeading ~ name", name);
    switch (name) {
      case "users":
        return "Users";
        break;
      case "advertisements":
        return "Advertisements";
        break;
      case "appContent":
        return "App Content";
        break;
      case "notifications":
        return "Notifications";
        break;
      case "places":
        return "Places";
        break;
      case "events":
        return "Events";
        break;
      case "instagram":
        return "Instagram Images";
        break;
      case "stories":
        return "User Stories";
        break;
      case "homeScreenImages":
        return "Home Screen Images";
        break;
      default:
        return "";
    }
  };

  let { data, pathname } = props;
  return (
    <div>
      <ListItem button onClick={() => handleClick(data)}>
        <ListItemText
          disableTypography
          className={
            handleHeading() === data.name
              ? classes.listItemBoldText
              : classes.listItemText
          }
          primary={data.name}
        />
        {props.toggle && props.currentActivePage === data.name ? (
          <ExpandMore className="visibility-hidden" />
        ) : (
          <KeyboardArrowRightIcon className="visibility-hidden" />
        )}
      </ListItem>
      <Divider className={classes.divider} />
      {props.toggle && props.currentActivePage === data.name ? (
        <div>
          {data.childs.map((item: any, i: any) => {
            return (
              <div>
                <ListItem
                  key={"MenuSubListItem" + i}
                  button
                  // component={Link}
                  // to={item.path}
                  selected={item.path.includes(pathname)}
                  onClick={() => handleClickLink(item.path)}
                >
                  <ListItemText
                    disableTypography
                    className={
                      item.path.includes(pathname)
                        ? classes.childListItemTextBold
                        : classes.childListItemText
                    }
                    primary={item.name}
                  />
                </ListItem>
                <Divider className={classes.divider} />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(MenuListItem);
